import React from 'react';
import { navigate } from 'gatsby';
import ImgNextGen from './ImgNextGen';
import config from './config';
import { v1 as uuidv1 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDolly, faGopuram, faCheckCircle, faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import {
  gameValues
} from './game-engine-admin/render-game';
import {
  loadSprites
} from './game-engine-admin/load-images';

const LoadGameModal = ({
  changeHandler, getState, createGameObject, handleMouseDownEvent, canvasDiv, handleDragStart, toggleMenuOn, getPosition
}) => {

  const PlaceholderImageArray = () => {
    let placeholderSpriteArray = []
    for (let i = 0; i < getState().currentPlatformFrameCount; i++) {
      if (getState().newPlatformAnimationArray[i]) {
        placeholderSpriteArray.push(<img src={`${getState().newPlatformAnimationArray[i]}`} width="80" height="80" style={{margin: "10px", border: 'dashed 5px #c1c8d4'}} onClick={() => {
            getState().newPlatformAnimationArray.splice(i, 1)
            changeHandler({
              currentPlatformFrameCount: getState().currentPlatformFrameCount
            })
        }}/>)
      } else {
        placeholderSpriteArray.push(<FontAwesomeIcon icon={faDolly} style={{ color: '#c1c8d4', width: '60px', height: '60px', border: 'dashed 5px #c1c8d4', margin: '10px', padding: '10px' }} />)
      }
    }
    return placeholderSpriteArray
  }
  const PlaceholderMovingPlatformImageArray = () => {
    let placeholderSpriteArray = []
    for (let i = 0; i < getState().currentMovingPlatformFrameCount; i++) {
      if (getState().newMovingPlatformAnimationArray[i]) {
        placeholderSpriteArray.push(<img src={`${getState().newMovingPlatformAnimationArray[i]}`} width="80" height="80" style={{margin: "10px", border: 'dashed 5px #c1c8d4'}} onClick={() => {
            getState().newMovingPlatformAnimationArray.splice(i, 1)
            changeHandler({
              currentMovingPlatformFrameCount: getState().currentMovingPlatformFrameCount
            })
        }}/>)
      } else {
        placeholderSpriteArray.push(<FontAwesomeIcon icon={faDolly} style={{ color: '#c1c8d4', width: '60px', height: '60px', border: 'dashed 5px #c1c8d4', margin: '10px', padding: '10px' }} />)
      }
    }
    return placeholderSpriteArray
  }
  return (
    <div>
      <button style={{marginRight: "20px"}} className="main-nav-link" onClick={() => {
        changeHandler({
          platformTypeBeingEdited: "normal",
        })
      }}>{getState().pageCopy.platform}</button>
      <button className="main-nav-link" onClick={async () => {
        changeHandler({
          platformTypeBeingEdited: "moving-create",
        })
        if(getState().movingPlatformSpriteArray.length === 0) {
          let resData = await fetch(`${config.backend}/admin/get-sprite-list`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
              gameObjectType: 'movingPlatforms',
            }),
          })
          let resDataConverted = await resData.json()
          changeHandler({
            movingPlatformSpriteArray: resDataConverted.message
          })
        }
      }}>{getState().pageCopy.movingPlatforms}</button>
      {getState().platformTypeBeingEdited === "normal" ?
        <>
          <h3>{getState().pageCopy.createPlatform}</h3>
          <label htmlFor="platform-length">{getState().pageCopy.platformLength}</label><br/>
          <input name="platform-length" type='number' min='1' value={getState().currentPlatformFrameCount} onChange={(e) => {changeHandler({
              currentPlatformFrameCount: e.target.value
            })
            if (getState().newPlatformAnimationArray.length > e.target.value) {
              getState().newPlatformAnimationArray.splice(e.target.value, 1)
            }
          }}/><br/>
          <label htmlFor="animation-frame-count">{getState().pageCopy.platformHeight}</label><br/>
          <input name="animation-frame-count" type="number" min="1" max="400" value={getState().currentPlatformHeight} onChange={(e) => {changeHandler({
              currentPlatformHeight: e.target.value
            })
          }}/>
            <br/>
            <PlaceholderImageArray/>
            <br/>
            { getState().platformEditing ? (
                  <button style={{marginRight: "20px"}} className="main-nav-danger-link" onClick={() => {
                    let deleteElementId = getState().editedPlatformId
                    let itemToDeleteIndex
                    gameValues.tileSet.forEach((item, index) => {
                      if (item.uniqueID === deleteElementId) {
                        itemToDeleteIndex = index
                      }
                    })
                    gameValues.tileSet.splice(itemToDeleteIndex, 1)
                    gameValues.gameObjects ? localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects)) : null
                    localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet))
                    document.getElementById(deleteElementId).remove()
                  }}>{getState().pageCopy.deletePlatform}</button>
              ) : null }
            <button style={{marginRight: "20px"}} className="main-nav-link" disabled={getState().newPlatformAnimationArray.length !== parseInt(getState().currentPlatformFrameCount)} onClick={async () => {
              let div = document.createElement('div');
              let uniqueID = `tilesetTile${uuidv1()}`
              div.setAttribute('id', uniqueID)
              div.setAttribute('draggable', false);
              let style = `position: absolute; left: ${getState().menuPositionX}; top: ${getState().menuPositionY}; z-index: 10000; width: ${getState().currentPlatformFrameCount * getState().currentPlatformHeight}px; height: ${getState().currentPlatformHeight}px; cursor: move;`
              for (let i = 0; i < getState().currentPlatformFrameCount; i++) {
                var el = document.createElement('img');
                let elStyle = `position: absolute; left: ${i === 0 ? i : i * getState().currentPlatformHeight + 'px'}; top: 0; z-index: 10000; width: ${getState().currentPlatformHeight}px; cursor: move;`
                el.setAttribute('style', elStyle)
                el.setAttribute('draggable', false)
                el.setAttribute('src', `${getState().newPlatformAnimationArray[i]}`)
                div.appendChild(el);
              }
              let platform = {
                count: getState().currentPlatformFrameCount,
                left: parseInt(getState().menuPositionX.split("px")[0]),
                top: parseInt(getState().menuPositionY.split("px")[0]),
                height: getState().currentPlatformHeight,
                uniqueID: uniqueID,
                imageArray: getState().newPlatformAnimationArray
              }
              if (!getState().platformEditing) {
                gameValues.tileSet.push(platform)
              } else {
                let deleteElementId = getState().editedPlatformId
                let itemToDeleteIndex
                gameValues.tileSet.forEach((item, index) => {
                  if (item.uniqueID === deleteElementId) {
                    itemToDeleteIndex = index
                  }
                })
                gameValues.tileSet[itemToDeleteIndex] = platform
                document.getElementById(deleteElementId).remove()
              }
              div.setAttribute('style', style)
              div.addEventListener('mousedown', (event) => {
                handleMouseDownEvent(event, () => {
                  let itemLeft
                  let itemTop
                  let itemIndex
                  gameValues.tileSet.forEach((item, index) => {
                    if (item.uniqueID === div.id) {
                      itemIndex = index
                      let itemElement = document.getElementById(item.uniqueID)
                      itemLeft = parseInt(itemElement.style.left.split('px')[0]);
                      itemTop = parseInt(itemElement.style.top.split('px')[0]);
                    }
                  })
                  gameValues.tileSet[itemIndex].left = itemLeft
                  gameValues.tileSet[itemIndex].top = itemTop
                  gameValues.gameObjects ? localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects)) : null;
                  localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet));
                  gameValues.tilesetCtx.clearRect(0, 0, gameValues.tilesetCanvas.width, gameValues.tilesetCanvas.height);
                  gameValues.tileSet.forEach((platform, index) => {
                    for (let i = 0; i < platform.count; i += 1) {
                      gameValues.tilesetCtx.drawImage(
                        gameValues.gameSprites[gameValues.tileSet[index].imageArray[i].split("/tileSets/")[1]],
                        platform.left + (i * 80),
                        platform.top,
                        80,
                        80,
                      );
                    }
                  });
                })
              })
              div.addEventListener('dragstart', (event) => {
                return false;
              })
              div.addEventListener('contextmenu', (e) => {
                let itemToEditIndex
                gameValues.tileSet.forEach((item, index) => {
                  if (item.uniqueID === e.target.parentElement.id) {
                    itemToEditIndex = index
                  }
                })
                e.preventDefault();
                let menu = document.getElementById('context-menu');
                let menuPosition;
                let menuPositionX;
                let menuPositionY;
                menuPosition = getPosition(e);
                menuPositionX = menuPosition.x + "px";
                menuPositionY = menuPosition.y + "px";
                menu.style.left = menuPositionX;
                menu.style.top = menuPositionY;
                var rect = canvasDiv.getBoundingClientRect();
                var stateX = e.clientX - rect.left;
                var stateY = e.clientY - rect.top;
                changeHandler({
                  platformEditing: true,
                  menuPositionX: `${stateX}px`,
                  menuPositionY: `${stateY}px`,
                  currentPlatformFrameCount: gameValues.tileSet[itemToEditIndex].count,
                  newPlatformAnimationArray: gameValues.tileSet[itemToEditIndex].imageArray,
                  currentPlatformHeight: gameValues.tileSet[itemToEditIndex].height,
                  editedPlatformId: e.target.parentElement.id,
                })
                toggleMenuOn();
              })
              canvasDiv.appendChild(div);
              changeHandler({
                newPlatformAnimationArray: []
              })
              if (!gameValues.tilesetCtx) {
                gameValues.tilesetCanvas = document.getElementById('pixopixa-custom-tileset');
                gameValues.tilesetCtx = gameValues.tilesetCanvas.getContext('2d');
              }
              gameValues.tilesetCtx.clearRect(0, 0, gameValues.tilesetCanvas.width, gameValues.tilesetCanvas.height);
              let sprites = {}
              let newSprite = false
              for (let j = 0; j < platform.imageArray.length; j++) {
                let platformName = platform.imageArray[j]
                  let imageName = platformName.split("/tileSets/")[1]
                  if (!sprites.imageName && !gameValues.gameSprites[imageName]) {
                    sprites[imageName] = platformName;
                    newSprite = true
                  }
              }
              if (newSprite) {
                let imageLoadCount = 0;
                let spritesLoaded = await loadSprites(sprites)
                for (const [key, value] of Object.entries(spritesLoaded)) {
                  spritesLoaded[key].onload = () => {
                    imageLoadCount++;
                    if (imageLoadCount === Object.keys(spritesLoaded).length) {
                      gameValues.gameSprites = {...gameValues.gameSprites, ...spritesLoaded};
                      gameValues.tileSet.forEach((platform, index) => {
                        for (let i = 0; i < platform.count; i += 1) {
                          gameValues.tilesetCtx.drawImage(
                            gameValues.gameSprites[gameValues.tileSet[index].imageArray[i].split("/tileSets/")[1]],
                            platform.left + (i * 80),
                            platform.top,
                            80,
                            80,
                          );
                        }
                      });
                    }
                  };
                }
              }
              gameValues.gameObjects ? localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects)) : null
              gameValues.tileSet ? localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet)) : null
            }}>{getState().pageCopy.createPlatform}</button>
            <button className="main-nav-link" onClick={() => {
              changeHandler({
                newPlatformAnimationArray: []
              })
            }}>{getState().pageCopy.clear}</button>
          <ul>
          {
            getState().platformSpriteArray.map((object) => {
              return (
                <div className="tooltip">
                  <img src={`${object}`} width="80" height="80" onClick={(e) => {
                    if (getState().newPlatformAnimationArray.length < getState().currentPlatformFrameCount) {
                      getState().newPlatformAnimationArray.push(object)
                      changeHandler({
                        currentPlatformFrameCount: getState().currentPlatformFrameCount
                      })
                    } else if (getState().newPlatformAnimationArray.length >= getState().currentPlatformFrameCount) {
                      changeHandler({
                        currentPlatformFrameCount: parseInt(getState().currentPlatformFrameCount) + 1
                      }, () => {
                        getState().newPlatformAnimationArray.push(object)
                      })
                    }
                  }}/>
                  <span className="tooltiptext" >Image Name: {object.split('/')[5]}</span>
                </div>
              )
            })
          }
          </ul>
        <br/>
        <br/>
       </> : <>{getState().platformTypeBeingEdited === "moving-create" ?
       <>
       <div>
       {
         getState().currentMovingPlatformGroupPlatforms.map((platform, index) => {
           return (
             <div style={{width: '15%', textAlign: "center", float: 'left'}}>
               <p>{getState().pageCopy.platform} {`${index + 1}`}</p>
               <FontAwesomeIcon icon={getState().currentMovingPlatformGroupPlatforms[index].category ? faCheckCircle : faTimesCircle} style={{ color: `${getState().currentMovingPlatformGroupPlatforms[index].category ? '#376e37' : '#c1c8d4'}`, fontSize: '3rem'}} />
             </div>
           )
         })
       }
       </div>
       <div style={{clear: "both"}}></div>
       <h3>{getState().pageCopy.createMovingPlatformGroup}</h3>
       <div style={{width: "50%", float: "left"}}>
       <div>{getState().pageCopy.platform}</div>
       <form>
       {
        getState().currentMovingPlatformGroupPlatforms.map((platform, index) => {
          let disabled = true
          if (index === 0) {
            disabled = false
          } else if (getState().editedMovingPlatformId) {
            disabled = false
          }
          return (
            <div style={{border: '2px dashed #b1b1b1', margin: '5px', backgroundColor: `${getState().currentMovingPlatformGroupPlatforms[index].category ? '#4BCA81' : getState().editedMovingPlatformIndex === `${index}` ? '#0a3f93' : '#d3d3d3'}`, color: `${getState().editedMovingPlatformIndex === `${index}` && !getState().currentMovingPlatformGroupPlatforms[index].category ? '#ffffff' : '#000000'}`}}>
             <input type="radio" name={`radio-input-${index}`} value={index} name="objectType" disabled={disabled} checked={getState().editedMovingPlatformIndex === `${index}`} onClick={(e) => {
               changeHandler({
                 editedMovingPlatformIndex: e.target.value,
                 editedMovingPlatformImageArray: getState().currentMovingPlatformGroupPlatforms.platforms && getState().currentMovingPlatformGroupPlatforms.platforms.imageArray ? getState().currentMovingPlatformGroupPlatforms.platforms.imageArray : [],
                 currentMovingPlatformFrameCount: getState().currentMovingPlatformGroupPlatforms.platforms && getState().currentMovingPlatformGroupPlatforms.platforms.count ? getState().currentMovingPlatformGroupPlatforms.platforms.count : 1,
               })
             }}/><label htmlFor={`radio-input-${index}`} disabled={disabled}> {getState().pageCopy.platform} {`${index + 1}`}</label><br/>
             <div className="tooltip"> {getState().pageCopy.platformStartingRight}
               <span className="tooltiptext" style={{paddingLeft: "45px"}}>{getState().pageCopy.platformStartingRightTooltip}</span>
             </div>
             <form>
               <input type="radio" value="false" name={`radio-input-${index}-mr`} checked={getState().editedMovingPlatformMovingRight === false} onClick={(e) => {changeHandler({
                   editedMovingPlatformMovingRight: false
                 })
               }}/> {getState().pageCopy.no} <br/>
               <input type="radio" value="true" name={`radio-input-${index}-mr`} checked={getState().editedMovingPlatformMovingRight === true} onClick={(e) => {changeHandler({
                   editedMovingPlatformMovingRight: true
                 })
               }}/> {getState().pageCopy.yes} <br/>
             </form>
            </div>
           )
         })
       }
      </form>
      </div>
       <div style={{width: "50%", float: "left"}}>
       <label htmlFor="platform-group-size">{getState().pageCopy.numberOfPlatformsInGroup}</label><br/>
       <input name="platform-group-size" type='number' min='1' value={getState().currentMovingPlatformGroupSize} onChange={(e) => {
         let platforms = getState().currentMovingPlatformGroupPlatforms
         for (let i = getState().currentMovingPlatformGroupPlatforms.length; i < e.target.value; i++) {
           platforms.push({})
         }
         if (getState().currentMovingPlatformGroupPlatforms.length > e.target.value) {
           platforms.splice(e.target.value - 1, getState().currentMovingPlatformGroupPlatforms.length - e.target.value)
         }
         changeHandler({
           currentMovingPlatformGroupSize: e.target.value,
           currentMovingPlatformGroupPlatforms: platforms
         })
       }}/><br/>
       <label htmlFor="platform-length">{getState().pageCopy.movingPlatformLength}</label><br/>
       <input name="platform-length" type='number' min='1' value={getState().currentMovingPlatformFrameCount} onChange={(e) => {changeHandler({
           currentMovingPlatformFrameCount: e.target.value
         })
         if (getState().newMovingPlatformAnimationArray.length > e.target.value) {
           getState().newMovingPlatformAnimationArray.splice(e.target.value, 1)
         }
       }}/><br/>
       <label htmlFor="animation-frame-count">{getState().pageCopy.platformHeight}</label><br/>
       <input name="animation-frame-count" type="number" min="1" max="400" value={getState().currentMovingPlatformHeight} onChange={(e) => {changeHandler({
           currentMovingPlatformHeight: e.target.value
         })
       }}/>
       </div>
       <div style={{clear: "both"}}></div>
         <br/>
         <PlaceholderMovingPlatformImageArray/>
         <br/>
         { getState().movingPlatformEditing ? (
               <button style={{marginRight: "20px"}} className="main-nav-link" onClick={() => {
                 let deleteElementId = getState().editedMovingPlatformId
                 let itemToDeleteIndex
                 gameValues.gameObjects.forEach((item, index) => {
                   if (item.uniqueID === deleteElementId) {
                     itemToDeleteIndex = index
                   }
                 })
                 gameValues.gameObjects.splice(itemToDeleteIndex, 1)
                 gameValues.gameObjects ? localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects)) : null
                 localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet))
                 document.getElementById(deleteElementId).remove()
               }}>{getState().pageCopy.deletePlatform}</button>
           ) : null }
        <button style={{marginRight: "20px"}} className="main-nav-link" disabled={getState().newMovingPlatformAnimationArray.length !== parseInt(getState().currentMovingPlatformFrameCount)} onClick={() => {
          if (getState().currentMovingPlatformGroupPlatforms && getState().currentMovingPlatformGroupPlatforms[getState().editedMovingPlatformIndex]) {
            let x, y, uniqueID
            if (!getState().editedMovingPlatformId) {
              uniqueID = `gameObjectsObject${uuidv1()}`
              x = parseInt(getState().menuPositionX.split("px")[0])
              y = parseInt(getState().menuPositionY.split("px")[0])
              changeHandler({
                editedMovingPlatformId: uniqueID
              })
            } else {
              uniqueID = `${getState().editedMovingPlatformId}.${getState().editedMovingPlatformIndex}`
              x = parseInt(getState().menuPositionX.split("px")[0]) + parseInt(getState().editedMovingPlatformIndex * 1000)
              y = parseInt(getState().menuPositionY.split("px")[0]) + parseInt(getState().editedMovingPlatformIndex % 2 * 250)
            }
            getState().currentMovingPlatformGroupPlatforms[getState().editedMovingPlatformIndex] = {
              category: "moving-platform",
              count: getState().currentMovingPlatformFrameCount,
              x: x,
              y: y,
              height: getState().currentMovingPlatformHeight,
              uniqueID: uniqueID,
              imageArray: getState().newMovingPlatformAnimationArray,
              patrolLeft: x - 200,
              patrolRight: x + 400,
              isMovingRight: getState().editedMovingPlatformMovingRight,
            }
          }
          let finished = true;
          for (let i = 0; i < getState().currentMovingPlatformGroupPlatforms.length; i++) {
            if (!getState().currentMovingPlatformGroupPlatforms[i].category) {
              finished = false
            }
          }
          changeHandler({
            currentMovingPlatformFrameCount: 1,
            currentMovingPlatformHeight: 80,
            newMovingPlatformAnimationArray: [],
            currentMovingPlatformCollectionComplete: finished,
            editedMovingPlatformMovingRight: false
          })
        }}>{getState().pageCopy.createPlatform}</button>
        <button className="main-nav-link" onClick={() => {
          changeHandler({
            newMovingPlatformAnimationArray: []
          })
        }}>{getState().pageCopy.clear}</button><br/><br/>
         <button style={{marginRight: "20px"}} className="main-nav-link" disabled={!getState().currentMovingPlatformCollectionComplete} onClick={async () => {
           let parentDiv = document.createElement('div');
           let uniqueID
           getState().currentMovingPlatformGroupPlatforms.forEach((platform, index) => {
             let div = document.createElement('div');
             div.setAttribute('id', platform.uniqueID)
             div.setAttribute('draggable', false);
             let style = `position: absolute; left: ${platform.x}px; top: ${platform.y}px; z-index: 9999; width: ${platform.count * platform.height}px; height: ${platform.height}px; cursor: move;`
             div.setAttribute('style', style)
             let divLeft = document.createElement('div');
             divLeft.setAttribute('draggable', false);
             divLeft.setAttribute('id', `${platform.uniqueID}.left`);
             divLeft.setAttribute('style', `position: absolute; left: -200px; top: ${platform.height/2}px; z-index: 10000; width: 20px; height: 20px; cursor: pointer; background-color: #a976fc; border: 2px solid grey; border-radius: 12px`)
             let divRight = document.createElement('div');
             divRight.setAttribute('draggable', false);
             divRight.setAttribute('id', `${platform.uniqueID}.right`);
             divRight.setAttribute('style', `position: absolute; left: 400px; top: ${platform.height/2}px; z-index: 10000; width: 20px; height: 20px; cursor: pointer; background-color: #7b19c1; border: 2px solid grey; border-radius: 12px`)
             if (index === 0) {
               uniqueID = platform.uniqueID
               parentDiv.setAttribute('id', `${uniqueID}.parent`)
               parentDiv.setAttribute('draggable', false);
               parentDiv.setAttribute('style', `border: 7px dashed #ffffff; border-radius: 10px; width: ${(getState().currentMovingPlatformGroupPlatforms.length - 1) * 1000 + 630}px; position: absolute; left: -210px; top: -15px; height: ${getState().currentMovingPlatformGroupPlatforms.length > 1 ? platform.height + 270 : platform.height + 20}px`)
               div.appendChild(parentDiv);
               parentDiv.addEventListener('mousedown', (event) => {
                 event.preventDefault()
                 event.stopPropagation()
                 handleMouseDownEvent(event, () => {
                   let itemLeft
                   let itemTop
                   let itemIndex
                   let itemId
                   gameValues.gameObjects.forEach((item, index) => {
                     if (item.uniqueID === `${div.id}.parent`) {
                       itemIndex = index
                       let itemElement = document.getElementById(item.uniqueID)
                       itemId = item.uniqueID
                       itemLeft = parseInt(itemElement.parentElement.style.left.split('px')[0]);
                       itemTop = parseInt(itemElement.parentElement.style.top.split('px')[0]); //- (window.pageYOffset + canvasDiv.getBoundingClientRect().top)
                     }
                   })
                   let offsetX = itemLeft - gameValues.gameObjects[itemIndex].x
                   let offsetY = itemTop - gameValues.gameObjects[itemIndex].y
                   gameValues.gameObjects[itemIndex].platforms.forEach((platform, index) => {
                     platform.x += (itemLeft - gameValues.gameObjects[itemIndex].x)
                     platform.y += (itemTop - gameValues.gameObjects[itemIndex].y)
                     platform.patrolLeft += (itemLeft - gameValues.gameObjects[itemIndex].x)
                     platform.patrolRight += (itemLeft - gameValues.gameObjects[itemIndex].x)
                   })
                   gameValues.gameObjects[itemIndex].x = itemLeft
                   gameValues.gameObjects[itemIndex].y = itemTop
                   gameValues.gameObjects ? localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects)) : null;
                   localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet));
                 }, "moving-platform-cluster")
               })
               parentDiv.addEventListener('dragstart', (event) => {
                 return false;
               })
             }
             for (let i = 0; i < platform.count; i++) {
               var el = document.createElement('img');
               let elStyle = `position: absolute; left: ${i === 0 ? i : i * platform.height + 'px'}; top: 0; z-index: 9999; width: ${platform.height}px; cursor: move;`
               el.setAttribute('style', elStyle)
               el.setAttribute('draggable', false)
               el.setAttribute('src', `${platform.imageArray[i]}`)
               div.appendChild(el);
             }
             div.addEventListener('mousedown', (event) => {
               event.preventDefault()
               event.stopPropagation()
               handleMouseDownEvent(event, () => {
                 let itemIndex
                 gameValues.gameObjects.forEach((item, index) => {
                   if (item.uniqueID === `${div.id.split('.')[0]}.parent`) {
                     itemIndex = index
                   }
                 })
                 let itemElement = document.getElementById(`${div.id}`)
                 let itemLeft = parseInt(itemElement.style.left.split('px')[0]);
                 let itemTop = parseInt(itemElement.style.top.split('px')[0]); //- (window.
                 let offsetX = itemLeft - gameValues.gameObjects[itemIndex].x
                 let offsetY = itemTop - gameValues.gameObjects[itemIndex].y
                 gameValues.gameObjects[itemIndex].platforms.forEach((platform, index) => {
                   if (platform.uniqueID === div.id) {
                     platform.patrolLeft += (itemLeft - gameValues.gameObjects[itemIndex].platforms[index].x)
                     platform.patrolRight += (itemLeft - gameValues.gameObjects[itemIndex].platforms[index].x)
                     platform.x += (itemLeft - gameValues.gameObjects[itemIndex].platforms[index].x)
                     platform.y += (itemTop - gameValues.gameObjects[itemIndex].platforms[index].y)
                   }
                 })
                 gameValues.gameObjects ? localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects)) : null;
                 localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet));
               }, `single-moving-platform.${index}`)
             })
             div.addEventListener('dragstart', (event) => {
               return false;
             })
             div.appendChild(divLeft)
             div.appendChild(divRight)
             let setListeners = (endpoint, typeOfEndpoint) => {
               endpoint.addEventListener('mousedown', (event) => {
                 let parentId = endpoint.parentElement.id.split(".")[0]
                 event.preventDefault()
                 event.stopPropagation()
                 let _typeOfEndpoint = typeOfEndpoint
                 let parentPlatform
                 handleMouseDownEvent(event, (event) => {
                   let itemIndex
                   gameValues.gameObjects.forEach((item, index) => {
                     if (item.uniqueID === `${parentId}.parent`) {
                       itemIndex = index
                     }
                   })
                   gameValues.gameObjects[itemIndex].platforms.forEach((platform, index) => {
                     if (platform.uniqueID === endpoint.id.split(`.${_typeOfEndpoint}`)[0]) {
                       if (_typeOfEndpoint === "left") {
                         gameValues.gameObjects[itemIndex].platforms[index].patrolLeft = gameValues.gameObjects[itemIndex].platforms[index].x + parseInt(endpoint.style.left.split('px')[0])
                       }
                       if (_typeOfEndpoint === "right") {
                         gameValues.gameObjects[itemIndex].platforms[index].patrolRight = gameValues.gameObjects[itemIndex].platforms[index].x + parseInt(endpoint.style.left.split('px')[0])
                       }
                     }
                   })
                   localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects));
                   gameValues.tileSet ? localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet)) : null;
                 }, `${_typeOfEndpoint}.mp`)
               })
               endpoint.addEventListener('dragstart', (event) => {
                 event.preventDefault()
                 event.stopPropagation()
                 return false;
               })
               endpoint.addEventListener('drop', (e) => {
                 e.stopPropagation();
                 e.preventDefault();
                 return false;
               })
             }
             setListeners(divLeft, "left")
             setListeners(divRight, "right")
             canvasDiv.appendChild(div);
           })

           let platformGroup = {
             uniqueID: `${uniqueID}.parent`,
             category: "moving-platforms",
             x: parseInt(getState().menuPositionX.split("px")[0]) - 200,
             y: parseInt(getState().menuPositionY.split("px")[0]),
             height: getState().currentMovingPlatformHeight,
             platforms: getState().currentMovingPlatformGroupPlatforms,
           }
           gameValues.gameObjects.push(platformGroup)
           let sprites = {}
           let newSprite = false
           platformGroup.platforms && platformGroup.platforms.forEach((platform, index) => {
             platform.imageArray && platform.imageArray.forEach((image, subIndex) => {
               let imageName = image.split("/")[5] ? image.split("/")[5] : image.split("/")[4];
               if (!sprites.imageName) {
                 sprites[imageName] = image;
                 newSprite = true
               }
             })
           })
           if (newSprite) {
             let imageLoadCount = 0;
             let spritesLoaded = await loadSprites(sprites)
             for (const [key, value] of Object.entries(spritesLoaded)) {
               spritesLoaded[key].onload = () => {
                 imageLoadCount++;
                 if (imageLoadCount === Object.keys(spritesLoaded).length) {
                   gameValues.gameSprites = {...gameValues.gameSprites, ...spritesLoaded};
                 }
               };
             }
           }
           gameValues.gameObjects ? localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects)) : null
           gameValues.tileSet ? localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet)) : null
           changeHandler({
             currentMovingPlatformFrameCount: 1,
             currentMovingPlatformHeight: 80,
             newMovingPlatformAnimationArray: [],
             currentMovingPlatformCollectionComplete: false,
             currentMovingPlatformGroupPlatforms: [{}],
             currentMovingPlatformGroupSize: 1,
             editedMovingPlatformIndex: 0,
             movingPlatformSpriteArray: [],
             editedMovingPlatformId: null,
           })
         }}>{getState().pageCopy.createMovingPlatformGroup}</button>
       <ul>
       {
         getState().movingPlatformSpriteArray.map((object) => {
           return (
             <div className="tooltip">
               <img src={`${object}`} width="80" height="80" onClick={(e) => {
                 if (getState().newMovingPlatformAnimationArray.length < getState().currentMovingPlatformFrameCount) {
                   getState().newMovingPlatformAnimationArray.push(object)
                   changeHandler({
                     currentMovingPlatformFrameCount: getState().currentMovingPlatformFrameCount
                   })
                 } else if (getState().newMovingPlatformAnimationArray.length >= getState().currentMovingPlatformFrameCount) {
                   changeHandler({
                     currentMovingPlatformFrameCount: parseInt(getState().currentMovingPlatformFrameCount) + 1
                   }, () => {
                     getState().newMovingPlatformAnimationArray.push(object)
                   })
                 }
               }}/>
               <span className="tooltiptext" >Image Name: {object.split('/')[5]}</span>
             </div>
           )
         })
       }
       </ul>
     <br/>
     <br/>
       </> : <>
       </>}</>
     }
    </div>
  );
};

export default LoadGameModal;
