import { gameValues } from './render-game';

export default (setValues, getValues) => {
  document.getElementById('canvas-container').addEventListener('keydown', (e) => {
    if (e.key === 'ArrowRight') {
      gameValues.isWalking = true;
      gameValues.walkingRight = true;
    }
    if (e.key === 'ArrowLeft') {
      gameValues.isWalking = true;
      gameValues.walkingRight = false;
    }
    if (e.code === 'Space') {
      e.preventDefault();
      e.stopPropagation();
      if (gameValues.canJump) {
        gameValues.isJumping = true;
      }
    }
    if (e.code === 'p') {
      e.preventDefault();
      setValues({
        paused: !this.state.paused,
      });
    }
  });
  document.getElementById('canvas-container').addEventListener('keyup', (e) => {
    if (e.key === 'ArrowRight' || e.key === 'ArrowLeft') {
      gameValues.isWalking = false;
    }
  });
  document.addEventListener('fullscreenchange', () => { setValues({ fullScreen: !getValues().fullScreen }); });
  document.addEventListener('webkitfullscreenchange', () => { setValues({ fullScreen: !getValues().fullScreen }); });
  document.addEventListener('mozfullscreenchange', () => { setValues({ fullScreen: !getValues().fullScreen }); });
  document.addEventListener('MSFullscreenChange', () => { setValues({ fullScreen: !getValues().fullScreen }); });
};
