import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faVolumeUp, faVolumeMute, faVolumeDown, faPause, faPlay, faLock, faHome, faExpand, faCompress, faAngleRight, faAngleLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  FacebookShareButton,
  FacebookMessengerShareButton,
  PinterestShareButton,
  TumblrShareButton,
  TwitterShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  PinterestIcon,
  TumblrIcon,
  TwitterIcon,
} from 'react-share';
import { v1 as uuidv1 } from 'uuid';
import LoadGameModal from '../components/load-game-modal';
import LoadPlayerModal from '../components/load-player-modal';
import CreatePlaformEditor from '../components/create-platform-editor';
import CreateMovingGameObjectEditor from '../components/create-moving-game-object-editor';
import Layout from '../components/layout-minimal';
import Audio1 from '../../public/audio/1.mp3';
import CoinSound from '../../public/audio/pickup_coin.wav';
import JumpSound from '../../public/audio/jump.wav';
import { getUser } from '../services/auth';
import {
  english, turkish, musanese
} from '../components/editor-lang';
import {
  getSpriteArray, loadSprites
} from '../components/game-engine-admin/load-images';
import {
  gameValues, initializeGame, animateGame, initializeMenu, getTileSet
} from '../components/game-engine-admin/render-game';
import config from "../components/config"

class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: null,
      email: '',
      id: this.props.id,
      level1Text: '',
      level2Text: '',
      level3Text: '',
      enemy: '',
      isloading: true,
      gameVolume: 0.5,
      paused: false,
      gameOver: false,
      menuOpen: true,
      mainMenuOpen: true,
      adjustingVolume: false,
      muted: false,
      canvasWidth: 0,
      canvasHeight: 0,
      currentLevel: 1,
      level2Locked: true,
      level3Locked: true,
      fullScreen: false,
      draggedItem: null,
      draggedOffsetLeft: 0,
      spriteType: "/gameObjects/",
      // Game Object Editor
      sidebarOpen: false,
      gameObjectType: "",
      gameObjectFrameCount: 1,
      gameObjectIsEditing: false,
      gameObjectSpriteArray: [],
      gameObjectObjectName: '',
      newgameObjectType: "",
      newgameObjectAnimationArray: [],
      newgameObjectAnimationArrayLeft: [],
      newgameObjectAnimationArrayRight: [],
      newgameObjectWidth: 80,
      newgameObjectHeight: 80,
      newgameObjectActiveAnimationArray: 'left',
      gameObjectLeftRightAnimation: false,
      newgameObjectReward: null,
      newgameObjectRewardValue: 0,
      newgameObjectRewardRegionPaddingLeft: 0,
      newgameObjectRewardRegionPaddingTop: 0,
      newgameObjectRewardRegionLeft: 0,
      newgameObjectRewardRegionTop: 0,
      newgameObjectDestroySelfOnReward: true,
      newgameObjectHeroBounceOnReward: false,
      newgameObjectHasReward2: false,
      newgameObjectReward2: null,
      newgameObjectReward2Value: 0,
      newgameObjectReward2RegionPaddingLeft: 0,
      newgameObjectReward2RegionPaddingTop: 0,
      newgameObjectReward2RegionLeft: 0,
      newgameObjectReward2RegionTop: 0,
      newgameObjectSpeed: 5,
      playerFrameCount: 1,
      // playerEditorStep: 1,
      playerObjectName: "",
      newPlayerAnimationArray: [],
      newPlayerActiveAnimationArray: '',
      newPlayerAnimationArrayWalkLeft: [],
      newPlayerAnimationArrayWalkRight: [],
      newPlayerAnimationArrayIdleLeft: [],
      newPlayerAnimationArrayIdleRight: [],
      newPlayerAnimationArrayJumpLeft: [],
      newPlayerAnimationArrayJumpRight: [],
      newPlayerAnimationArrayHurtLeft: [],
      newPlayerAnimationArrayHurtRight: [],
      newPlayerAnimationArrayDeathLeft: [],
      newPlayerAnimationArrayDeathRight: [],
      playerRunningSpeed: 5,
      playerJumpSpeed: 20,
      playerIsEditing: false,
      // For Admin CRUD Operations
      testValue: '',
      characterToggle: true,
      loadedCharacters: [],
      loadedGameObjects: [],
      editedGameObjectID: "",
      editedCharacterID: "",
      gameObjectCreationFailed: false,
      gameObjectCreationSucceeded: false,
      characterCreationFailed: false,
      characterCreationSucceeded: false,
      spriteCreationFailed: false,
      spriteCreationSucceeded: false,
      //Context menu
      contextMenuOpen: false,
      menuPosition: 0,
      menuPositionX: 0,
      menuPositionY: 0,
      //Platform editor
      currentPlatformFrameCount: 1,
      newPlatformAnimationArray: [],
      platformSpriteArray: [],
      currentPlatformHeight: 80,
      platformEditing: false,
      editedPlatformId: null,
      platformTypeBeingEdited: "normal",
      //Game Object Editor
      gameObjectContextMenuOpen: false,
      editedMovingGameObjectId: null,
      newMovingGameObjectType: null,
      newMovingGameObjectIsMovingRight: false,
      newMovingGameObjectSpeed: 3,
      newGameObjectPreviewImage: null,
      // Moving Platforms
      currentMovingPlatformGroupSize: 1,
      newMovingPlatformAnimationArray: [],
      currentMovingPlatformFrameCount: 1,
      movingPlatformSpriteArray: [],
      currentMovingPlatformHeight: 80,
      movingPlatformEditing: false,
      editedMovingPlatformMovingRight: false,
      editedMovingPlatformId: null,
      editedMovingPlatformIndex: 0,
      editedMovingPlatformImageArray: [],
      currentMovingPlatformGroupSize: 1,
      currentMovingPlatformGroupPlatforms: [{}],
      currentMovingPlatformCollectionComplete: false,
      // test
      canvasDiv: null,
      // game level settings
      currentGameId: null,
      gameName: "",
      gameThumbnail: "https://pixopixa.cyou/images/logo-transparent.webp",
      backgroundDivColor: '#87ceeb',
      gamePlotText: '',
      gameDescriptionText: '',
      loadedGameDrafts: null,
      aciveLanguage: "turkish",
      pageCopy: turkish,
      gameState: {},
    };
    this.startGame = this.startGame.bind(this);
    this.setGameValues = this.setGameValues.bind(this);
    this.getGameValues = this.getGameValues.bind(this);
    this.modalCloseHandler = this.modalCloseHandler.bind(this);
    this.toggleMenuOn = this.toggleMenuOn.bind(this);
    this.createGameObject = this.createGameObject.bind(this);
    this.createPlayer = this.createPlayer.bind(this);
    this.loadGameDrafts = this.loadGameDrafts.bind(this);
    this.deleteGameDraft = this.deleteGameDraft.bind(this);
    this.checkDisabled = this.checkDisabled.bind(this);
    this.levelMusic = React.createRef();
    this.coinSound = React.createRef();
    this.jumpSound = React.createRef();
  }

  setGameValues(obj, cb) {
    this.setState(obj, cb ? cb() : null);
  }

  getGameValues() {
    return this.state;
  }

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const searchParams = Object.fromEntries(urlSearchParams.entries());
    switch(searchParams.lang) {
      case "english":
        this.setState({
          aciveLanguage: "english",
          pageCopy: english,
        })
        break;
      case "turkish":
        this.setState({
          aciveLanguage: "turkish",
          pageCopy: turkish,
        })
        break;
      case "musanese":
        this.setState({
          aciveLanguage: "musanese",
          pageCopy: musanese,
        })
        break;
      default:
        break
    }
    this.setState({
      canvasDiv: document.getElementById("canvas-container-tileset"),
      currentGameId: localStorage.getItem("currentGameId") ? localStorage.getItem("currentGameId") : null,
      gameName: localStorage.getItem('gameName') ? localStorage.getItem('gameName') : "",
      gameThumbnail: localStorage.getItem('gameThumbnail') ? localStorage.getItem('gameThumbnail') : "https://pixopixa.cyou/images/logo-transparent.webp",
      backgroundDivColor: localStorage.getItem('backgroundDivColor') ? localStorage.getItem('backgroundDivColor') : '#87ceeb',
      gamePlotText: localStorage.getItem('gamePlotText') ? localStorage.getItem('gamePlotText') : '',
      gameDescriptionText: localStorage.getItem('gameDescriptionText') ? localStorage.getItem('gameDescriptionText') : '',
    })
    gameValues.tilesetCanvas = document.getElementById('pixopixa-custom-tileset');
    gameValues.tilesetCtx = gameValues.tilesetCanvas.getContext('2d');
    let imageLoadCount = 0;

    getSpriteArray(this.setGameValues, this.getGameValues, (sprites) => {
      gameValues.gameSprites = sprites;
      if (Object.keys(gameValues.gameSprites).length === 0) {
        this.generateTileSetCanvas()
      }
      for (const [key, value] of Object.entries(gameValues.gameSprites)) {
        gameValues.gameSprites[key].onload = () => {
          imageLoadCount++;
          if (imageLoadCount === Object.keys(gameValues.gameSprites).length) {
            initializeMenu(this.setGameValues, this.getGameValues, 'db3f2010-5b3e-11ec-9e1c-f7d3a26cb877', '7');
            this.generateTileSetCanvas()
          }
        };
      }
    })
  }

  startGame() {
    console.log("We are animating")
    gameValues.coinSound = this.coinSound;
    gameValues.jumpSound = this.jumpSound;
    gameValues.levelMusic = this.levelMusic;
    gameValues.previewGameObjects = gameValues.gameObjects;
    initializeGame(this.getGameValues, this.setGameValues);
    const animate = () => {
      console.log("We are animating")
      if (!this.state.paused) {
        animateGame(this.setGameValues, this.getGameValues);
      }
      if (this.state.menuOpen) return;
      requestAnimationFrame(animate);
    };
    animate();
  }

  async toggleMenuOn(menuType) {
    if (menuType === "gameObject") {
      let menu = document.getElementById('context-menu-game-object')
      if ( !this.state.gameObjectContextMenuOpen ) {
        this.setState({
          gameObjectContextMenuOpen: true,
          contextMenuOpen: false,
        })
        menu.classList.add('context-menu--active');
        document.getElementById('context-menu').classList.remove('context-menu--active');
      }
      return
    }
    if(this.state.platformSpriteArray.length === 0) {
      let resData = await fetch(`${config.backend}/admin/get-sprite-list`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
          gameObjectType: 'tileSets',
        }),
      })
      let resDataConverted = await resData.json()
      this.setState({
        platformSpriteArray: resDataConverted.message
      })
    }
      let menu = document.getElementById('context-menu')
      if ( !this.state.contextMenuOpen ) {
        this.setState({
          contextMenuOpen: true,
          gameObjectContextMenuOpen: false,
        })
        menu.classList.add('context-menu--active');
        document.getElementById('context-menu-game-object').classList.remove('context-menu--active');
      }
  }

  getPosition(e) {
    var posx = 0;
    var posy = 0;
    if (!e) var e = window.event;
    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx = e.clientX + document.body.scrollLeft +
      document.documentElement.scrollLeft;
      posy = e.clientY + document.body.scrollTop +
      document.documentElement.scrollTop;
    }
    return {
      x: posx,
      y: posy
    }
  }

  handleMouseDownEvent(event, cb, mus) {
    let item = event.target.parentElement;
    let siblings = []
    let parent
    let background
    let originalLeft
    let originalTop
    let platformGroupId
    if (mus === "moving-platform-cluster") {
      for (let i = 1; i < 1000; i++) {
        if (document.getElementById(`${event.target.id.split('.parent')[0]}.${i}`)) {
          siblings.push(document.getElementById(`${event.target.id.split('.parent')[0]}.${i}`))
          document.body.append(document.getElementById(`${event.target.id.split('.parent')[0]}.${i}`));
        } else {
          i = 1000;
        }
      }
    } else if (mus && mus.split('.')[0] === 'single-moving-platform') {
      platformGroupId = event.target.parentElement.id
      originalLeft = event.target.parentElement.style.left
      originalTop = event.target.parentElement.style.top
      if (mus.split('.')[1] !== "0") {
        platformGroupId = platformGroupId.split('.')[0]
        siblings.push(document.getElementById(`${platformGroupId}`))
      }
      for (let i = 1; i < 1000; i++) {
        if (document.getElementById(`${platformGroupId}.${i}`) && `${platformGroupId}.${i}` !== event.target.parentElement.id) {
          siblings.push(document.getElementById(`${platformGroupId}.${i}`))
        } else if (parseInt(mus.split('.')[1]) !== i) {
          i = 1000;
        }
      }
      background = document.getElementById(platformGroupId + '.parent')
    } else if (mus === 'left') {
      parent = event.target.parentElement
      item = document.getElementById(event.target.parentElement.firstElementChild.id+'.left')
    } else if ( mus === 'right') {
      parent = event.target.parentElement
      item = document.getElementById(event.target.parentElement.firstElementChild.id+'.right')
    }else if (mus === 'left.mp') {
      parent = event.target.parentElement
      item = document.getElementById(event.target.parentElement.id+'.left')
      originalLeft = item.style.left
    } else if ( mus === 'right.mp') {
      parent = event.target.parentElement
      item = document.getElementById(event.target.parentElement.id+'.right')
      originalLeft = item.style.left
    } else if (mus !== undefined) {
      item = document.getElementById(event.target.id)
    }
    if (!item) return
    let shiftX = event.clientX - item.getBoundingClientRect().left;
    let shiftY = event.clientY - item.getBoundingClientRect().top;
    document.body.append(item);
    function moveAt(pageX, pageY, screenX, screenY) {
      if (mus === "moving-platform-cluster") {
        siblings.forEach((sibling, index) => {
          sibling.style.left = parseInt(sibling.style.left.split('px')[0]) + (pageX - shiftX - parseInt(item.style.left.split('px')[0])) + 'px'
          sibling.style.top = parseInt(sibling.style.top.split('px')[0]) - parseInt(item.style.top.split('px')[0]) + (pageY - shiftY) + 'px'
        })
      }
      item.style.left = pageX - shiftX + 'px';
      item.style.top = pageY - shiftY + 'px';
      if (screenX > window.innerWidth - 50) {
        window.scrollBy(7, 0)
        return false
      } else if (screenX < 50) {
          window.scrollBy(-7, 0)
          return false
      }
      if (screenY > window.innerHeight - 50) {
        window.scrollBy(0, 7)
        return false
      } else if (screenY < 50) {
        window.scrollBy(0, -7)
        return false
      }
    }
    moveAt(event.pageX, event.pageY, event.screenX, event.screenY);

    function onMouseMove(event) {
      event.stopPropagation()
      moveAt(event.pageX, event.pageY, event.screenX, event.screenY);
    }
    function onMouseUp(event) {
      event.stopPropagation()
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
      let itemTop = parseInt(item.style.top.split('px')[0]) - (window.pageYOffset + document.getElementById('pixopixa-custom-tileset-editor').getBoundingClientRect().top)
      item.style.top = itemTop + 'px'
      document.body.removeChild(item)
      if (mus === "left" || mus === "right") {
        item.style.left = parseInt(item.style.left.split('px')[0] - parent.style.left.split('px')[0]) + 'px'
        item.style.top = parseInt(parent.style.height.split('px')[0]/2) + 'px'
        parent.appendChild(item)
      } else if (mus === "left.mp" || mus === "right.mp") {
        if ( mus === "left.mp" && !item.id.split('.')[2]) {
          let box = document.getElementById(`${parent.id}.parent`)
          let right = document.getElementById(`${parent.id}.right`)
          item.style.left = parseInt(item.style.left.split('px')[0] - parent.style.left.split('px')[0]) + 'px'
          item.style.top = parseInt(parent.style.height.split('px')[0]/2) + 'px'
          parent.appendChild(item)
          box.style.left = parseInt(item.style.left.split('px')[0] - 10) + 'px'
          box.style.width = parseInt(box.style.width.split('px')[0]) + parseInt(originalLeft.split("px")[0]) - parseInt(item.style.left.split('px')[0]) + 'px'
          parent.appendChild(box)
        } else if ( mus === "right.mp" && !document.getElementById(`${parent.id.split('.')[0]}${parseInt(parent.id.split('.')[1]) + 1}`)) {
          let box = document.getElementById(`${parent.id.split(".")[0]}.parent`)
          item.style.left = parseInt(item.style.left.split('px')[0] - parent.style.left.split('px')[0]) + 'px'
          item.style.top = parseInt(parent.style.height.split('px')[0]/2) + 'px'
          parent.appendChild(item)
          box.style.width = parseInt(box.style.width.split('px')[0]) + parseInt(item.style.left.split('px')[0]) - parseInt(originalLeft.split("px")[0]) + 'px'
          document.getElementById(item.parentElement.id.split(".")[0]).appendChild(box)
        } else {
          item.style.left = parseInt(item.style.left.split('px')[0] - parent.style.left.split('px')[0]) + 'px'
          item.style.top = parseInt(parent.style.height.split('px')[0]/2) + 'px'
          parent.appendChild(item)
        }
      } else if (mus === "moving-platform-cluster") {
        document.getElementById("canvas-container-tileset").appendChild(item)
        siblings.forEach((sibling, index) => {
          let siblingTop = parseInt(sibling.style.top.split('px')[0]) - (window.pageYOffset + document.getElementById('pixopixa-custom-tileset-editor').getBoundingClientRect().top)
          sibling.style.top = siblingTop + 'px'
          document.getElementById("canvas-container-tileset").appendChild(sibling)
        })
      } else if (mus && mus.split(".")[0] === 'single-moving-platform') {
        let leftMost = background.style.left.split("px")[0]
        let rightMost = background.style.left.split("px")[0] + background.style.width.split("px")[0]
        let topMost = background.style.top.split("px")[0]
        let bottomMost = background.style.top.split("px")[0] + background.style.height.split("px")[0]
        for (let i = 0; i < siblings.length; i++) {
          document.getElementById("canvas-container-tileset").appendChild(siblings[i])
        }
        document.getElementById("canvas-container-tileset").appendChild(item)
        if (parseInt(item.id.split(".")[1]) === siblings.length) {
          background.style.width = parseInt(background.style.width.split("px")[0]) - (parseInt(originalLeft.split("px")[0]) - parseInt(item.style.left.split("px")[0])) + 'px'
        } else if (!item.id.split(".")[1]) {
          background.style.width = parseInt(background.style.width.split("px")[0]) + (parseInt(originalLeft.split("px")[0]) - parseInt(item.style.left.split("px")[0])) + 'px'
        }
        let schmiblings = [item, ...siblings]
        let topSchmibling = item
        let bottomSchmibling = item
        for (let i = 0; i < schmiblings.length; i++) {
          if (parseInt(schmiblings[i].style.top.split('px')[0]) < parseInt(topSchmibling.style.top.split("px")[0])) {
            topSchmibling = schmiblings[i]
          } else if (parseInt(schmiblings[i].style.top.split('px')[0]) > parseInt(bottomSchmibling.style.top.split("px")[0])) {
            bottomSchmibling = schmiblings[i]
          }
        }
        background.style.height = parseInt(bottomSchmibling.style.top.split('px')[0]) - parseInt(topSchmibling.style.top.split('px')[0]) + 110 + "px"
        background.style.top = parseInt(topSchmibling.style.top.split('px')[0]) - parseInt(document.getElementById(`${platformGroupId}`).style.top.split('px')[0]) - 10 + 'px'
      } else {
        document.getElementById("canvas-container-tileset").appendChild(item)
      }
      cb(mus)
    }
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp)
  }

  async generateTileSetCanvas() {
    let menu = document.getElementById('context-menu');
    let movingGameObjectMenu = document.getElementById('context-menu-game-object');
    let menuPosition;
    let menuPositionX;
    let menuPositionY;
    const tileSetCanvas = document.getElementById('pixopixa-custom-tileset-editor');
    tileSetCanvas.addEventListener( "contextmenu", (e) => {
      e.preventDefault();
      menuPosition = this.getPosition(e);
      menuPositionX = menuPosition.x + "px";
      menuPositionY = menuPosition.y + "px";
      menu.style.left = menuPositionX;
      menu.style.top = menuPositionY;
      var rect = e.target.getBoundingClientRect();
      var stateX = e.clientX - rect.left; //x position within the element.
      var stateY = e.clientY - rect.top;  //y position within the element.
      this.setState({
        menuPositionX: `${stateX}px`,
        menuPositionY: `${stateY}px`,
        currentPlatformFrameCount: 1,
        newPlatformAnimationArray: [],
        platformSpriteArray: [],
        currentPlatformHeight: 80,
        platformEditing: false,
        editedPlatformId: null,
      })
      this.toggleMenuOn();
    })
    tileSetCanvas.addEventListener("click", (e) => {
      event.preventDefault()
      event.stopPropagation()
      if ( this.state.contextMenuOpen ) {
        this.setState({
          contextMenuOpen: false,
          currentPlatformFrameCount: 1,
          newPlatformAnimationArray: [],
          platformSpriteArray: [],
          currentPlatformHeight: 80,
          platformEditing: false,
          editedPlatformId: null,
          currentMovingPlatformFrameCount: 1,
          currentMovingPlatformHeight: 80,
          newMovingPlatformAnimationArray: [],
          currentMovingPlatformCollectionComplete: false,
          currentMovingPlatformGroupPlatforms: [{}],
          currentMovingPlatformGroupSize: 1,
          editedMovingPlatformIndex: 0,
          movingPlatformSpriteArray: [],
          editedMovingPlatformId: null,
        })
        menu.classList.remove('context-menu--active');
      } else if ( this.state.gameObjectContextMenuOpen ) {
        this.setState({
          gameObjectContextMenuOpen: false,
          editedMovingGameObjectId: null,
          newMovingGameObjectType: null,
          newMovingGameObjectIsMovingRight: false,
          newMovingGameObjectSpeed: 3,
        })
        movingGameObjectMenu.classList.remove('context-menu--active');
      }
    })
    const tileSetCtx = tileSetCanvas.getContext('2d');
    tileSetCanvas.width = 30000;
    tileSetCanvas.height = 1500;
    tileSetCtx.fillStyle = '#00BCD4';
    tileSetCtx.fillRect(0, 0, tileSetCanvas.width, tileSetCanvas.height);
    let canvasDiv = document.getElementById("canvas-container-tileset");
      let a;
      let b;
      gameValues.tileSet.forEach((platform, index) => {
        let div = document.createElement('div');
        div.setAttribute('id', platform.uniqueID)
        div.setAttribute('draggable', false);
        let style = `position: absolute; left: ${platform.left}px; top: ${platform.top}px; z-index: 10000; width: ${platform.count * platform.height}px; height: ${platform.height}px; cursor: move;`
        for (let i = 0; i < platform.count; i++) {
          var el = document.createElement('img');
          let elStyle = `position: absolute; left: ${i === 0 ? i : i * platform.height + 'px'}; top: 0; z-index: 10000; width: ${platform.height}px; cursor: move;`
          el.setAttribute('style', elStyle)
          el.setAttribute('draggable', false)
          el.setAttribute('src', `${platform.imageArray[i]}`)
          div.appendChild(el);
        }
        div.setAttribute('style', style)
        div.addEventListener('mousedown', (event) => {
          event.preventDefault()
          event.stopPropagation()
          this.handleMouseDownEvent(event, () => {
            let itemLeft
            let itemTop
            let itemIndex
            console.log(div.id)
            gameValues.tileSet.forEach((item, index) => {
              console.log(item, div.id, index)
              if (item.uniqueID === div.id) {
                itemIndex = index
                let itemElement = document.getElementById(item.uniqueID)
                itemLeft = parseInt(itemElement.style.left.split('px')[0]);
                itemTop = parseInt(itemElement.style.top.split('px')[0]);// - (window.pageYOffset + tileSetCanvas.getBoundingClientRect().top)
              }
            })
            gameValues.tileSet[itemIndex].left = itemLeft
            gameValues.tileSet[itemIndex].top = itemTop
            gameValues.gameObjects ? localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects)) : null;
            localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet));
            gameValues.tilesetCtx.clearRect(0, 0, gameValues.tilesetCanvas.width, gameValues.tilesetCanvas.height);
            gameValues.tileSet.forEach((platform, index) => {
              for (let i = 0; i < platform.count; i += 1) {
                gameValues.tilesetCtx.drawImage(
                  gameValues.gameSprites[gameValues.tileSet[index].imageArray[i].split("/tileSets/")[1]],
                  platform.left + (i * 80),
                  platform.top,
                  80,
                  80,
                );
              }
            });
          })
        })
        div.addEventListener('dragstart', (event) => {
          event.preventDefault()
          event.stopPropagation()
          return false;
        })
        div.addEventListener('contextmenu', (e) => {
          let itemToEditIndex
          gameValues.tileSet.forEach((item, index) => {
            if (item.uniqueID === e.target.parentElement.id) {
              itemToEditIndex = index
            }
          })
          e.preventDefault();
          menuPosition = this.getPosition(e);
          menuPositionX = menuPosition.x + "px";
          menuPositionY = menuPosition.y + "px";
          menu.style.left = menuPositionX;
          menu.style.top = menuPositionY;
          var rect = canvasDiv.getBoundingClientRect();
          var stateX = e.clientX - rect.left; //x position within the element.
          var stateY = e.clientY - rect.top;  //y position within the element.
          this.setState({
            platformEditing: true,
            menuPositionX: `${stateX}px`,
            menuPositionY: `${stateY}px`,
            currentPlatformFrameCount: gameValues.tileSet[itemToEditIndex].count,
            newPlatformAnimationArray: gameValues.tileSet[itemToEditIndex].imageArray,
            currentPlatformHeight: gameValues.tileSet[itemToEditIndex].height,
            editedPlatformId: e.target.parentElement.id,
          })
          this.toggleMenuOn();
        })
        canvasDiv.appendChild(div);
      });

      gameValues.gameObjects.forEach((iterableGameObject, index) => {
        if (iterableGameObject.category !== 'moving-platforms' && iterableGameObject.category !== 'boxCoin' && iterableGameObject.category !== 'boxPineapple') {
          let div = document.createElement('div');
          div.setAttribute('style', `position: absolute; left: ${iterableGameObject.x}px; top: ${iterableGameObject.y}px; z-index: 10000; width: ${iterableGameObject.width}px; height: ${iterableGameObject.width + 50}px; cursor: move;`)
          var el = document.createElement('img');
          var copyIcon = document.createElement('div');
          copyIcon.innerHTML = this.state.pageCopy.copy
          copyIcon.setAttribute('style', 'position: absolute; left: 0; top: 0; z-index: 10000; width: 45px; height: 25px; cursor: pointer; background-color: green; color: white; border: 2px solid grey')
          var deleteIcon = document.createElement('div');
          deleteIcon.innerHTML = this.state.pageCopy.delete
          deleteIcon.setAttribute('style', 'position: absolute; left: 53px; top: 0; z-index: 10000; width: 60px; height: 25px; cursor: pointer; background-color: red; color: white; border: 2px solid grey')
          let style = `position: absolute; left: 0; top: 45px; z-index: 10000; width: ${iterableGameObject.width}px; cursor: move;`
          // let style = `position: absolute; left: ${iterableGameObject.x}px; top: ${iterableGameObject.y}px; z-index: 10000; width: ${iterableGameObject.width}px; height: ${iterableGameObject.height}px; cursor: move;`
          iterableGameObject.spriteArrayLeft[0] ? el.setAttribute('src', `${iterableGameObject.spriteArrayLeft[0]}`) : el.setAttribute('src', 'https://pixopixa.cyou/images/logo-transparent.webp')//gameValues.gameSprites[iterableGameObject.image] ? el.setAttribute('src', `${gameValues.gameSprites[iterableGameObject.image].currentSrc}`) : el.setAttribute('src', 'https://pixopixa.cyou/images/logo-transparent.webp')
          div.setAttribute('draggable', false);
          el.setAttribute('draggable', false);
          el.setAttribute('id', iterableGameObject.uniqueID)
          el.setAttribute('style', style)
          div.appendChild(el)
          div.appendChild(copyIcon)
          div.appendChild(deleteIcon)
          if (iterableGameObject.movementType === "patrol") {
            let divLeft = document.createElement('div');
            divLeft.setAttribute('draggable', false);
            divLeft.setAttribute('id', `${iterableGameObject.uniqueID}.left`);
            divLeft.setAttribute('style', `position: absolute; left: ${iterableGameObject.patrolLeft - iterableGameObject.x}px; top: ${parseInt(div.style.height.split('px')[0])/2}px; z-index: 10000; width: 20px; height: 20px; cursor: pointer; background-color: #a976fc; border: 2px solid grey; border-radius: 12px`)
            div.appendChild(divLeft)
            let divRight = document.createElement('div');
            divRight.setAttribute('draggable', false);
            divRight.setAttribute('id', `${iterableGameObject.uniqueID}.right`);
            divRight.setAttribute('style', `position: absolute; left: ${iterableGameObject.patrolRight - iterableGameObject.x}px; top: ${parseInt(div.style.height.split('px')[0])/2}px; z-index: 10000; width: 20px; height: 20px; cursor: pointer; background-color: #7b19c1; border: 2px solid grey; border-radius: 12px`)
            div.appendChild(divRight)
            let setListeners = (endpoint, typeOfEndpoint) => {
              endpoint.addEventListener('mousedown', (event) => {
                event.preventDefault()
                event.stopPropagation()
                let _typeOfEndpoint = typeOfEndpoint
                this.handleMouseDownEvent(event, (event) => {
                  let itemIndex
                  let parentId = endpoint.parentElement.firstElementChild.id
                  gameValues.gameObjects.forEach((item, index) => {
                    if (item.uniqueID === parentId) {
                      itemIndex = index
                      console.log(itemIndex)
                    }
                  })
                  if (_typeOfEndpoint === "left") {
                    gameValues.gameObjects[itemIndex].patrolLeft = gameValues.gameObjects[itemIndex].x + parseInt(endpoint.style.left.split('px')[0])
                  }
                  if (_typeOfEndpoint === "right") {
                    gameValues.gameObjects[itemIndex].patrolRight = gameValues.gameObjects[itemIndex].x + parseInt(endpoint.style.left.split('px')[0])
                  }
                  localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects));
                  gameValues.tileSet ? localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet)) : null;
                }, _typeOfEndpoint)
              })
              endpoint.addEventListener('dragstart', (event) => {
                event.preventDefault()
                event.stopPropagation()
                return false;
              })
            }
            setListeners(divLeft, "left")
            setListeners(divRight, "right")
          }
          div.addEventListener('mousedown', (event) => {
            event.preventDefault()
            event.stopPropagation()
            this.handleMouseDownEvent(event, (event) => {
              let itemLeft
              let itemTop
              let itemIndex
              let itemElement
              gameValues.gameObjects.forEach((item, index) => {
                if (item.uniqueID === el.id) {
                  itemIndex = index
                  itemElement = document.getElementById(item.uniqueID).parentElement
                  itemLeft = parseInt(itemElement.style.left.split('px')[0]);
                  itemTop = parseInt(itemElement.style.top.split('px')[0]); //- (window.pageYOffset + tileSetCanvas.getBoundingClientRect().top)
                }
              })
              if (iterableGameObject.movementType === "patrol") {
                gameValues.gameObjects[itemIndex].patrolLeft = gameValues.gameObjects[itemIndex].patrolLeft + (itemLeft - gameValues.gameObjects[itemIndex].x)
                gameValues.gameObjects[itemIndex].patrolRight = gameValues.gameObjects[itemIndex].patrolRight + (itemLeft - gameValues.gameObjects[itemIndex].x)
              }
              gameValues.gameObjects[itemIndex].x = itemLeft
              gameValues.gameObjects[itemIndex].y = itemTop
              localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects));
              gameValues.tileSet ? localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet)) : null;
            })
          })
          div.addEventListener('dragstart', (event) => {
            event.preventDefault()
            event.stopPropagation()
            return false;
          })
          div.addEventListener('contextmenu', (e) => {
            let itemToEditIndex
            let itemToEditId = e.currentTarget.firstElementChild.id
            gameValues.gameObjects.forEach((item, index) => {
              if (item.uniqueID === itemToEditId) {
                itemToEditIndex = index
              }
            })
            e.preventDefault();
            menuPosition = this.getPosition(e);
            menuPositionX = menuPosition.x + "px";
            menuPositionY = menuPosition.y + "px";
            movingGameObjectMenu.style.left = menuPositionX;
            movingGameObjectMenu.style.top = menuPositionY;
            var rect = canvasDiv.getBoundingClientRect();
            var stateX = e.clientX - rect.left; //x position within the element.
            var stateY = e.clientY - rect.top;  //y position within the element.
            this.setState({
              menuPositionX: `${stateX}px`,
              menuPositionY: `${stateY}px`,
              editedMovingGameObjectId: itemToEditId,
            })
            this.toggleMenuOn('gameObject');
          })
          let deleteHandler = (e) => {
            let deleteElementId = e.currentTarget.parentElement.firstElementChild.id
            let itemToDeleteIndex
            gameValues.gameObjects.forEach((item, index) => {
              if (item.uniqueID === deleteElementId) {
                itemToDeleteIndex = index
              }
            })
            gameValues.gameObjects.splice(itemToDeleteIndex, 1)
            localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects))
            gameValues.tileSet ? localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet)) : null
            let parent = e.currentTarget.parentElement.remove()
          }
          let copyHandler = (e) => {
            let canvasDiv = document.getElementById("canvas-container-tileset");
            let copy = e.currentTarget.parentElement.cloneNode(true)
            let copyImage = copy.firstElementChild
            let copiedCopyIcon = copy.children[1]
            let copiedDeleteIcon = copy.children[2]
            let uniquecopyID = `gameObjectsObject${uuidv1()}`;
            copyImage.id = uniquecopyID
            copy.style["left"] = `${parseInt(copy.style.left) + 100}px`
            gameValues.gameObjects.push({...iterableGameObject, x: parseInt(copy.style.left), y: parseInt(copy.style.top), height: (copyImage.naturalHeight/copyImage.naturalWidth)*iterableGameObject.width, uniqueID: uniquecopyID, currentFrame: 0})
            copy.addEventListener('mousedown', (event) => {
              event.preventDefault()
              event.stopPropagation()
              this.handleMouseDownEvent(event, (event) => {
                let itemLeft
                let itemTop
                let itemIndex
                gameValues.gameObjects.forEach((item, index) => {
                  if (item.uniqueID === copyImage.id) {
                    itemIndex = index
                    let itemElement = document.getElementById(item.uniqueID).parentElement
                    itemLeft = parseInt(itemElement.style.left.split('px')[0]);
                    itemTop = parseInt(itemElement.style.top.split('px')[0]); //- (window.pageYOffset + tileSetCanvas.getBoundingClientRect().top)
                  }
                })
                gameValues.gameObjects[itemIndex].x = itemLeft
                gameValues.gameObjects[itemIndex].y = itemTop
                localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects));
                gameValues.tileSet ? localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet)) : null;
              })
            })
            copy.addEventListener('dragstart', (event) => {
              event.preventDefault()
              event.stopPropagation()
              return false;
            })
            copiedCopyIcon.addEventListener("mousedown", (e) => {
              e.preventDefault()
              e.stopPropagation()
              copyHandler(e)
            })
            copiedDeleteIcon.addEventListener("mousedown", (e) => {
              e.preventDefault()
              e.stopPropagation()
              deleteHandler(e)
            })
            canvasDiv.appendChild(copy)
            localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects))
            gameValues.tileSet ? localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet)) : null
          }
          copyIcon.addEventListener("mousedown", (e) => {
            e.preventDefault()
            e.stopPropagation()
            copyHandler(e)
          })
          deleteIcon.addEventListener("mousedown", (e) => {
            e.preventDefault()
            e.stopPropagation()
            deleteHandler(e)
          })
          canvasDiv.appendChild(div)
        } else if (iterableGameObject.category === 'moving-platforms') {
          let parentDiv = document.createElement('div');
          let uniqueID
          iterableGameObject.platforms.forEach((platform, index) => {
            let div = document.createElement('div');
            div.setAttribute('id', platform.uniqueID)
            div.setAttribute('draggable', false);
            let style = `position: absolute; left: ${platform.x}px; top: ${platform.y}px; z-index: 9999; width: ${platform.count * platform.height}px; height: ${platform.height}px; cursor: move;`
            div.setAttribute('style', style)
            let divLeft = document.createElement('div');
            divLeft.setAttribute('draggable', false);
            divLeft.setAttribute('id', `${platform.uniqueID}.left`);
            divLeft.setAttribute('style', `position: absolute; left: ${platform.patrolLeft - platform.x}px; top: ${platform.height/2}px; z-index: 10000; width: 20px; height: 20px; cursor: pointer; background-color: #a976fc; border: 2px solid grey; border-radius: 12px`)
            let divRight = document.createElement('div');
            divRight.setAttribute('draggable', false);
            divRight.setAttribute('id', `${platform.uniqueID}.right`);
            divRight.setAttribute('style', `position: absolute; left: ${platform.patrolRight - platform.x}px; top: ${platform.height/2}px; z-index: 10000; width: 20px; height: 20px; cursor: pointer; background-color: #7b19c1; border: 2px solid grey; border-radius: 12px`)
            if (index === 0) {
              uniqueID = platform.uniqueID
              let width = iterableGameObject.platforms[iterableGameObject.platforms.length - 1].patrolRight - platform.patrolLeft + 40
              let heightMax = platform.y
              let heightMin = platform.y
              iterableGameObject.platforms.forEach((platform2, index2) => {
                if (platform2.y < heightMax) {
                  heightMax = platform2.y
                } else if (platform2.y > heightMin) {
                  heightMin = platform2.y
                }
              })
              // let height =
              parentDiv.setAttribute('id', `${uniqueID}.parent`)
              parentDiv.setAttribute('draggable', false);
              parentDiv.setAttribute('style', `border: 7px dashed #ffffff; border-radius: 10px; width: ${width}px; position: absolute; left: ${platform.patrolLeft - platform.x - 10}px; top: ${heightMax - platform.y - 15}px; height: ${heightMin - heightMax + platform.height + 20}px`)
              div.appendChild(parentDiv);
              parentDiv.addEventListener('mousedown', (event) => {
                event.preventDefault()
                event.stopPropagation()
                this.handleMouseDownEvent(event, () => {
                  let itemLeft
                  let itemTop
                  let itemIndex
                  let itemId
                  gameValues.gameObjects.forEach((item, index) => {
                    if (item.uniqueID === `${div.id}.parent`) {
                      itemIndex = index
                      let itemElement = document.getElementById(item.uniqueID)
                      itemId = item.uniqueID
                      itemLeft = parseInt(itemElement.parentElement.style.left.split('px')[0]);
                      itemTop = parseInt(itemElement.parentElement.style.top.split('px')[0]); //- (window.pageYOffset + canvasDiv.getBoundingClientRect().top)
                    }
                  })
                  let offsetX = itemLeft - gameValues.gameObjects[itemIndex].x
                  let offsetY = itemTop - gameValues.gameObjects[itemIndex].y
                  gameValues.gameObjects[itemIndex].platforms.forEach((platform, index) => {
                    platform.x += (itemLeft - gameValues.gameObjects[itemIndex].x)
                    platform.y += (itemTop - gameValues.gameObjects[itemIndex].y)
                    platform.patrolLeft += (itemLeft - gameValues.gameObjects[itemIndex].x)
                    platform.patrolRight += (itemLeft - gameValues.gameObjects[itemIndex].x)
                  })
                  gameValues.gameObjects[itemIndex].x = itemLeft
                  gameValues.gameObjects[itemIndex].y = itemTop
                  localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects));
                  gameValues.tileSet ? localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet)) : null;
                }, "moving-platform-cluster")
              })
              parentDiv.addEventListener('dragstart', (event) => {
                return false;
              })
            }
            for (let i = 0; i < platform.count; i++) {
              var el = document.createElement('img');
              let elStyle = `position: absolute; left: ${i === 0 ? i : i * platform.height + 'px'}; top: 0; z-index: 9999; width: ${platform.height}px; cursor: move;`
              el.setAttribute('style', elStyle)
              el.setAttribute('draggable', false)
              el.setAttribute('src', `${platform.imageArray[i]}`)
              div.appendChild(el);
            }
            div.addEventListener('mousedown', (event) => {
              event.preventDefault()
              event.stopPropagation()
              this.handleMouseDownEvent(event, () => {
                let itemIndex
                gameValues.gameObjects.forEach((item, index) => {
                  if (item.uniqueID === `${div.id.split('.')[0]}.parent`) {
                    itemIndex = index
                  }
                })
                let itemElement = document.getElementById(`${div.id}`)
                let itemLeft = parseInt(itemElement.style.left.split('px')[0]);
                let itemTop = parseInt(itemElement.style.top.split('px')[0]); //- (window.
                let offsetX = itemLeft - gameValues.gameObjects[itemIndex].x
                let offsetY = itemTop - gameValues.gameObjects[itemIndex].y
                gameValues.gameObjects[itemIndex].platforms.forEach((platform, index) => {
                  if (platform.uniqueID === div.id) {
                    platform.patrolLeft += (itemLeft - gameValues.gameObjects[itemIndex].platforms[index].x)
                    platform.patrolRight += (itemLeft - gameValues.gameObjects[itemIndex].platforms[index].x)
                    platform.x += (itemLeft - gameValues.gameObjects[itemIndex].platforms[index].x)
                    platform.y += (itemTop - gameValues.gameObjects[itemIndex].platforms[index].y)
                  }
                })
                gameValues.gameObjects ? localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects)) : null;
                localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet));
              }, `single-moving-platform.${index}`)
            })
            div.addEventListener('dragstart', (event) => {
              return false;
            })
            div.appendChild(divLeft)
            div.appendChild(divRight)
            let setListeners = (endpoint, typeOfEndpoint) => {
              endpoint.addEventListener('mousedown', (event) => {
                let parentId = endpoint.parentElement.id.split(".")[0]
                event.preventDefault()
                event.stopPropagation()
                let _typeOfEndpoint = typeOfEndpoint
                let parentPlatform
                this.handleMouseDownEvent(event, (event) => {
                  let itemIndex
                  gameValues.gameObjects.forEach((item, index) => {
                    if (item.uniqueID === `${parentId}.parent`) {
                      itemIndex = index
                    }
                  })
                  gameValues.gameObjects[itemIndex].platforms.forEach((platform, index) => {
                    if (platform.uniqueID === endpoint.id.split(`.${_typeOfEndpoint}`)[0]) {
                      if (_typeOfEndpoint === "left") {
                        gameValues.gameObjects[itemIndex].platforms[index].patrolLeft = gameValues.gameObjects[itemIndex].platforms[index].x + parseInt(endpoint.style.left.split('px')[0])
                      }
                      if (_typeOfEndpoint === "right") {
                        gameValues.gameObjects[itemIndex].platforms[index].patrolRight = gameValues.gameObjects[itemIndex].platforms[index].x + parseInt(endpoint.style.left.split('px')[0])
                      }
                    }
                  })
                  localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects));
                  gameValues.tileSet ? localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet)) : null;
                }, `${_typeOfEndpoint}.mp`)
              })
              endpoint.addEventListener('dragstart', (event) => {
                event.preventDefault()
                event.stopPropagation()
                return false;
              })
              endpoint.addEventListener('drop', (e) => {
                e.stopPropagation();
                e.preventDefault();
                return false;
              })
            }
            setListeners(divLeft, "left")
            setListeners(divRight, "right")
            canvasDiv.appendChild(div);
          })
        }
      })
      if (localStorage.getItem('character')) {
        let characterRaw = localStorage.getItem('character')
        let character = await JSON.parse(characterRaw)
        var el = document.createElement('img');
        let style = `position: absolute; left: ${character.x}px; top: ${character.y}px; z-index: 10000; width: 100px; cursor: move;`
        let uniqueID = character.uniqueID
        character.spriteArrayIdleRight[0] ? el.setAttribute('src', `${character.spriteArrayIdleRight[0]}`) : el.setAttribute('src', 'https://pixopixa.cyou/images/logo-transparent.webp')
        el.setAttribute('draggable', false);
        el.setAttribute('id', uniqueID)
        el.setAttribute('style', style)
        el.addEventListener('mousedown', (event) => {
          let _character = character
          event.stopPropagation()
          this.handleMouseDownEvent(event, () => {
            let itemLeft = parseInt(document.getElementById(_character.uniqueID).style.left.split('px')[0]);
            let itemTop = parseInt(document.getElementById(_character.uniqueID).style.top.split('px')[0]); //- (window.pageYOffset + tileSetCanvas.getBoundingClientRect().top)
            character.x = itemLeft;
            character.y = itemTop;
            localStorage.setItem('character', JSON.stringify(_character))
            gameValues.gameObjects ? localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects)) : null;
            localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet));
            gameValues.heroStartX = _character.x
            gameValues.heroStartY = _character.y
          }, 'character')
        })
        el.addEventListener('dragstart', (event) => {
          event.stopPropagation()
          return false;
        })
        canvasDiv.appendChild(el)
      }
  }

  fullScreenGame() {
    const el = document.getElementById('canvas-container');
    if (!this.state.fullScreen) {
      if (el.webkitRequestFullScreen) {
        el.webkitRequestFullScreen();
      } else {
        el.mozRequestFullScreen();
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }

  copyLink() {
    const copyText = document.getElementById('copyLink');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    const tooltip = document.getElementById('myTooltip');
    tooltip.innerHTML = `Copied: ${copyText.value}`;
  }

  copyMouseOut() {
    const tooltip = document.getElementById('myTooltip');
    tooltip.innerHTML = 'Copy to clipboard';
  }

  modalCloseHandler(e, modal) {
    if (modal === e.target) {
      this.setState({ show: false });
    }
  }

  async handleImageSelect(id) {
    const myInput = document.getElementById(id);
    let formData = new FormData();
    formData.append('admin', true)
    formData.append('gameId', `${this.state.spriteType}`);
    if (myInput.files) {
      for(let i = 0; i < myInput.files.length; i++) {
        formData.append('images', myInput.files[i], myInput.files[i].name.split('.')[0]);
      }
    }
    let res = await fetch(`${config.backend}/client-image`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    })
    let fileResData = await res.json()
    this.setState({
      spriteCreationFailed: res.status !== 201,
      spriteCreationSucceeded:  res.status === 201,
    })
    myInput.value = null;
    if (this.state.spriteType === "/thumbnails/") {
      this.setState({
        spriteType: "/gameObjects/",
        gameThumbnail: fileResData.filePaths[0],
      })
      localStorage.setItem('gameThumbnail', fileResData.filePaths[0])
    } else {
      setTimeout(() => {
        this.setState({
          spriteCreationFailed: false,
          spriteCreationSucceeded: false,
        })
      }, 5000)
    }
  }

  async createGameDraft() {
    let characterRaw = localStorage.getItem('character')
    let character = await JSON.parse(characterRaw)
    let gameDraft = await fetch(`${config.backend}/admin/create-game-draft`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        plotText: this.state.gamePlotText,
        gameDescription: this.state.gameDescriptionText,
        gameObjects: gameValues.gameObjects,
        backgroundImageUrl: this.state.backgroundDivColor,
        tileSet: gameValues.tileSet,
        characterId: character._id,
        thumbnailUrl: this.state.gameThumbnail,
        gameName: this.state.gameName,
        currentGameId: this.state.currentGameId,
      }),
    })
    let parsedGameDraft = await gameDraft.json()
    if (parsedGameDraft.message._id) {
      localStorage.setItem("currentGameId", parsedGameDraft.message._id)
      this.setState({currentGameId: parsedGameDraft.message._id})
    }
  }

  async createPlayer() {
    if (this.state.playerIsEditing) {
      let edit = await fetch(`${config.backend}/admin/edit-character`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          playerId: this.state.editedCharacterID,
          playerObjectName: this.state.playerObjectName,
          spriteArrayWalkLeft: this.state.newPlayerAnimationArrayWalkLeft,
          spriteArrayWalkRight: this.state.newPlayerAnimationArrayWalkRight,
          spriteArrayIdleLeft: this.state.newPlayerAnimationArrayIdleLeft,
          spriteArrayIdleRight: this.state.newPlayerAnimationArrayIdleRight,
          spriteArrayJumpLeft: this.state.newPlayerAnimationArrayJumpLeft,
          spriteArrayJumpRight: this.state.newPlayerAnimationArrayJumpRight,
          spriteArrayHurtLeft: this.state.newPlayerAnimationArrayHurtLeft,
          spriteArrayHurtRight: this.state.newPlayerAnimationArrayHurtRight,
          spriteArrayDeathLeft: this.state.newPlayerAnimationArrayDeathLeft,
          spriteArrayDeathRight: this.state.newPlayerAnimationArrayDeathRight,
          speed: this.state.playerRunningSpeed,
          jumpSpeed: this.state.playerJumpSpeed,
        })
      })
      let parsedEdit = await edit.json()
      this.setState({
        characterCreationFailed: edit.status !== 200,
        characterCreationSucceeded:  edit.status === 200,
      })
      setTimeout(() => {
        this.setState({
          characterCreationFailed: false,
          characterCreationSucceeded: false,
          show: null,
        })
      }, 5000)
    } else {
    let create = await fetch(`${config.backend}/admin/create-character`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        playerObjectName: this.state.playerObjectName,
        spriteArrayWalkLeft: this.state.newPlayerAnimationArrayWalkLeft,
        spriteArrayWalkRight: this.state.newPlayerAnimationArrayWalkRight,
        spriteArrayIdleLeft: this.state.newPlayerAnimationArrayIdleLeft,
        spriteArrayIdleRight: this.state.newPlayerAnimationArrayIdleRight,
        spriteArrayJumpLeft: this.state.newPlayerAnimationArrayJumpLeft,
        spriteArrayJumpRight: this.state.newPlayerAnimationArrayJumpRight,
        spriteArrayHurtLeft: this.state.newPlayerAnimationArrayHurtLeft,
        spriteArrayHurtRight: this.state.newPlayerAnimationArrayHurtRight,
        spriteArrayDeathLeft: this.state.newPlayerAnimationArrayDeathLeft,
        spriteArrayDeathRight: this.state.newPlayerAnimationArrayDeathRight,
        speed: this.state.playerRunningSpeed,
        jumpSpeed: this.state.playerJumpSpeed,
      })
    })
      let parsedCreate = await create.json()
      this.setState({
        characterCreationFailed: create.status !== 200,
        characterCreationSucceeded:  create.status === 200,
      })
      setTimeout(() => {
        this.setState({
          characterCreationFailed: false,
          characterCreationSucceeded: false,
          show: null,
        })
      }, 5000)
    }
  }

  async createGameObject() {
    if (this.state.gameObjectIsEditing) {
      let edit = await fetch(`${config.backend}/admin/edit-game-object`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          gameObjectID: this.state.editedGameObjectID,
          gameObjectObjectName: this.state.gameObjectObjectName,
          category: this.state.newgameObjectType,
          spriteArrayLeft: this.state.newgameObjectAnimationArrayLeft,
          spriteArrayRight: this.state.newgameObjectAnimationArrayRight,
          width: this.state.newgameObjectWidth,
          reward: this.state.newgameObjectReward,
          rewardValue: this.state.newgameObjectRewardValue,
          rewardRegion: {
            paddLeft: this.state.newgameObjectRewardRegionPaddingLeft,
            width: this.state.newgameObjectRewardRegionLeft,
            paddTop: this.state.newgameObjectRewardRegionPaddingTop,
            height: this.state.newgameObjectRewardRegionTop,
          },
          rewardDestroySelf: this.state.newgameObjectDestroySelfOnReward,
          rewardHeroBounce: this.state.newgameObjectHeroBounceOnReward,
          reward2: this.state.newgameObjectReward2,
          reward2Value: this.state.newgameObjectReward2Value,
          reward2Region: {
            paddLeft: this.state.newgameObjectReward2RegionPaddingLeft,
            width: this.state.newgameObjectReward2RegionLeft,
            paddTop: this.state.newgameObjectReward2RegionPaddingTop,
            height: this.state.newgameObjectReward2RegionTop,
          },
          speed: this.state.newgameObjectSpeed,
        })
      })
      let parsedEdit = await edit.json()
      this.setState({
        gameObjectCreationFailed: edit.status !== 200,
        gameObjectCreationSucceeded:  edit.status === 200,
      })
      setTimeout(() => {
        this.setState({
          gameObjectCreationFailed: false,
          gameObjectCreationSucceeded: false,
          show: null,
        })
      }, 5000)
    } else {
      let create = await fetch(`${config.backend}/admin/create-game-object`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          gameObjectObjectName: this.state.gameObjectObjectName,
          category: this.state.newgameObjectType,
          spriteArrayLeft: this.state.newgameObjectAnimationArrayLeft,
          spriteArrayRight: this.state.newgameObjectAnimationArrayRight,
          width: this.state.newgameObjectWidth,
          reward: this.state.newgameObjectReward,
          rewardValue: this.state.newgameObjectRewardValue,
          rewardRegion: {
            paddLeft: this.state.newgameObjectRewardRegionPaddingLeft,
            width: this.state.newgameObjectRewardRegionLeft,
            paddTop: this.state.newgameObjectRewardRegionPaddingTop,
            height: this.state.newgameObjectRewardRegionTop,
          },
          rewardDestroySelf: this.state.newgameObjectDestroySelfOnReward,
          reward2: this.state.newgameObjectReward2,
          reward2Value: this.state.newgameObjectReward2Value,
          reward2Region: {
            paddLeft: this.state.newgameObjectReward2RegionPaddingLeft,
            width: this.state.newgameObjectReward2RegionLeft,
            paddTop: this.state.newgameObjectReward2RegionPaddingTop,
            height: this.state.newgameObjectReward2RegionTop,
          },
          speed: this.state.newgameObjectSpeed,
          rewardHeroBounce: this.state.newgameObjectHeroBounceOnReward,
        })
      })
      let parsedCreate = await create.json()
      this.setState({
        gameObjectCreationFailed: create.status !== 200,
        gameObjectCreationSucceeded:  create.status === 200,
      })
      setTimeout(() => {
        this.setState({
          gameObjectCreationFailed: false,
          gameObjectCreationSucceeded: false,
          show: null,
        })
      }, 5000)
    }
  }

  async loadCharacters() {
    let characters = await fetch(`${config.backend}/admin/get-characters`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: null
    })
      let parsedCharacters = await characters.json()
      this.setState({
        loadedCharacters: parsedCharacters.message
      })
  }

  async loadGameObjects() {
    let gameObjectsFromServer = await fetch(`${config.backend}/admin/get-game-objects`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        category: null
      })
    })
      let parsedGameObjects = await gameObjectsFromServer.json()
      this.setState({
        loadedGameObjects: parsedGameObjects.message
      })
  }

  async loadGameDrafts() {
    let gameDraftsFromServer = await fetch(`${config.backend}/admin/get-game-drafts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        category: null
      })
    })
    let parsedGameDraftsFromServer = await gameDraftsFromServer.json()
    this.setState({loadedGameDrafts: parsedGameDraftsFromServer.message})
  }

  async deleteGameDraft() {
    let load = confirm(this.state.pageCopy.deleteConfirm);
    if (load) {
      let currentGameId = localStorage.getItem('currentGameId')
      let deletedGameDraft = await fetch(`${config.backend}/admin/delete-game-draft`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          _id: currentGameId
        })
      })
      let parsedDeleteGameDraft = await deletedGameDraft.json()
      this.setState({
        backgroundDivColor: '#87ceeb',
        gameDescriptionText: '',
        gameName: '',
        gamePlotText: '',
        gameThumbnail: "https://pixopixa.cyou/images/logo-transparent.webp",
      })
      localStorage.removeItem('tileset')
      localStorage.removeItem('backgroundDivColor')
      localStorage.removeItem('currentGameId')
      localStorage.removeItem('gamePlotText')
      localStorage.removeItem('gameDescriptionText')
      localStorage.removeItem('gameThumbnail')
      localStorage.removeItem('gameName')
      localStorage.removeItem('gameObjects')
      localStorage.removeItem('character')
      location.reload();
    }
 }

 async newGameDraft() {
   let load = confirm(this.state.pageCopy.loadConfirm);
   if (load) {
     this.setState({
       backgroundDivColor: '#87ceeb',
       gameDescriptionText: '',
       gameName: '',
       gamePlotText: '',
       gameThumbnail: "https://pixopixa.cyou/images/logo-transparent.webp",
     })
     localStorage.removeItem('tileset')
     localStorage.removeItem('backgroundDivColor')
     localStorage.removeItem('currentGameId')
     localStorage.removeItem('gamePlotText')
     localStorage.removeItem('gameDescriptionText')
     localStorage.removeItem('gameThumbnail')
     localStorage.removeItem('gameName')
     localStorage.removeItem('gameObjects')
     localStorage.removeItem('character')
     location.reload();
   }
}

  checkDisabled(item) {
    let disabled = false
    if (item === "gameObject") {
      if (!this.state.newgameObjectType || this.state.gameObjectFrameCount < 1 || !this.state.gameObjectObjectName || this.state.newgameObjectAnimationArrayLeft.length === 0 || !this.state.newgameObjectWidth || (this.state.gameObjectLeftRightAnimation && this.state.newgameObjectAnimationArrayRight.length === 0) || (this.state.newgameObjectHasReward2 && !this.state.newgameObjectReward2)) {
        disabled = true
      }
    } else if (item === "player") {
      if (
        !this.state.playerObjectName ||
        this.state.newPlayerAnimationArrayWalkLeft.length < 1 ||
        this.state.newPlayerAnimationArrayWalkRight.length < 1 ||
        this.state.newPlayerAnimationArrayIdleLeft.length < 1 ||
        this.state.newPlayerAnimationArrayIdleRight.length < 1 ||
        this.state.newPlayerAnimationArrayJumpLeft.length < 1 ||
        this.state.newPlayerAnimationArrayJumpRight.length < 1 ||
        this.state.newPlayerAnimationArrayHurtLeft.length < 1 ||
        this.state.newPlayerAnimationArrayHurtRight.length < 1 ||
        this.state.newPlayerAnimationArrayDeathLeft.length < 1 ||
        this.state.newPlayerAnimationArrayDeathRight.length < 1
      ) {
        disabled = true
      }
    }
    return disabled
  }

  render() {
    return (
      <Layout>
      <div style={{textAlign: "center", float: "left", backgroundColor: "#E7E7E7", position: "sticky", left: "0", top: "0", width: `${this.state.sidebarOpen ? "400px" : "60px"}`, height: "100vh", minHeight: "100vh", zIndex: "7000000000", borderRight: "2px solid #E0E0E0", overflowY: "scroll"}}>
        <FontAwesomeIcon icon={this.state.sidebarOpen ? faAngleLeft : faAngleRight} style={{margin: "auto", fontSize: "3rem"}} onClick={() => {
          this.setState({
            sidebarOpen: !this.state.sidebarOpen
          })
        }}/>
      {
        this.state.sidebarOpen ? (
          <div>
            <button style={{marginRight: "20px"}} className="main-nav-link" onClick={() => {
              this.setState({
                characterToggle: true,
              })
            }}>{this.state.pageCopy.character}</button>
            <button className="main-nav-link" onClick={() => {
              this.setState({
                characterToggle: false,
              })}}>{this.state.pageCopy.gameObjects}</button>
            {this.state.characterToggle ? <div style={{backgroundColor: "#03cea4", padding: "7px", margin: "7px", border: "5px solid #fff"}}>
            <h2>{this.state.pageCopy.character}</h2>
            <button style={{marginRight: "20px"}} className="main-nav-link" onClick={() => {
              this.setState({
                show: 'player',
                sidebarOpen: false,
                playerIsEditing: false,
                playerFrameCount: 1,
                playerObjectName: "",
                newPlayerAnimationArray: [],
                newPlayerActiveAnimationArray: '',
                newPlayerAnimationArrayWalkLeft: [],
                newPlayerAnimationArrayWalkRight: [],
                newPlayerAnimationArrayIdleLeft: [],
                newPlayerAnimationArrayIdleRight: [],
                newPlayerAnimationArrayJumpLeft: [],
                newPlayerAnimationArrayJumpRight: [],
                newPlayerAnimationArrayHurtLeft: [],
                newPlayerAnimationArrayHurtRight: [],
                newPlayerAnimationArrayDeathLeft: [],
                newPlayerAnimationArrayDeathRight: [],
                playerRunningSpeed: 5,
                playerJumpSpeed: 20,
              })
            }}>{this.state.pageCopy.create}</button>
            <button className="main-nav-link" onClick={() => {
              this.loadCharacters()
            }}>{this.state.pageCopy.load}</button>
            </div> : <div style={{backgroundColor: "#eab700", padding: "7px", margin: "7px", border: "5px solid #fff"}}>
            <h2>{this.state.pageCopy.gameObjects}</h2>
            <button style={{marginRight: "20px"}} className="main-nav-link" onClick={() => {
              this.setState({
                show: 'gameObject',
                sidebarOpen: false,
                gameObjectIsEditing: false,
                gameObjectObjectName: '',
                newgameObjectType: "",
                newgameObjectAnimationArray: [],
                newgameObjectAnimationArrayLeft: [],
                newgameObjectAnimationArrayRight: [],
                newgameObjectWidth: 80,
                newgameObjectActiveAnimationArray: 'left',
                gameObjectLeftRightAnimation: false,
                newgameObjectReward: null,
                newgameObjectRewardValue: 0,
                newgameObjectRewardRegionPaddingLeft: 0,
                newgameObjectRewardRegionPaddingTop: 0,
                newgameObjectRewardRegionLeft: 0,
                newgameObjectRewardRegionTop: 0,
                newgameObjectDestroySelfOnReward: true,
                newgameObjectHasReward2: false,
                newgameObjectReward2: null,
                newgameObjectReward2Value: 0,
                newgameObjectReward2RegionPaddingLeft: 0,
                newgameObjectReward2RegionPaddingTop: 0,
                newgameObjectReward2RegionLeft: 0,
                newgameObjectReward2RegionTop: 0,
                newgameObjectIsMoving: false,
                newgameObjectSpeed: 5,
                newGameObjectPreviewImage: null,
              })
            }}>{this.state.pageCopy.create}</button>
            <button className="main-nav-link" onClick={() => {
              this.loadGameObjects()
            }}>{this.state.pageCopy.load}</button>
            </div>}
            <ul style={{backgroundColor: "#fff", margin: "15px", listStyle: "none"}}>
            <br/>
            {this.state.characterToggle ? this.state.loadedCharacters.map(character => {
              return (
                <div>
                  <hr/>
                  <img style={{margin: "10px", padding: "10px", width: '100px', border: "5px solid #03cea4", borderRadius: "5px"}} src={character.spriteArrayIdleRight[0]}/>
                  <br/>{character.name}<br/><br/>
                  <button className="main-nav-link" style={{marginRight: "15px"}} onClick={async () => {
                    let canvasDiv = document.getElementById("canvas-container-tileset");
                    if (localStorage.getItem('character')) {
                      let oldCharacter = JSON.parse(localStorage.getItem('character'))
                      let oldCharacterSprite = document.getElementById(oldCharacter.uniqueID)
                      if (oldCharacterSprite) {
                        canvasDiv.removeChild(oldCharacterSprite)
                      }
                    }
                    var el = document.createElement('img');
                    let style = `position: absolute; left: 500px; top: 300px; z-index: 10000; width: 100px; cursor: move;`
                    let uniqueID = `character${uuidv1()}`
                    character.spriteArrayIdleRight[0] ? el.setAttribute('src', `${character.spriteArrayIdleRight[0]}`) : el.setAttribute('src', 'https://pixopixa.cyou/images/logo-transparent.webp')
                    el.setAttribute('draggable', false);
                    el.setAttribute('id', uniqueID)
                    let _character = {...character, x: 500, y: 300, height: 100, uniqueID: uniqueID}
                    el.setAttribute('style', style)
                    localStorage.setItem('character', JSON.stringify(_character))
                    el.addEventListener('mousedown', (event) => {
                      let _character2 = _character
                      event.stopPropagation()
                      this.handleMouseDownEvent(event, () => {
                        let itemLeft = parseInt(document.getElementById(_character2.uniqueID).style.left.split('px')[0]);
                        let itemTop = parseInt(document.getElementById(_character2.uniqueID).style.top.split('px')[0]); //- (window.pageYOffset + canvasDiv.getBoundingClientRect().top)
                        character.x = itemLeft;
                        character.y = itemTop;
                        localStorage.setItem('character', JSON.stringify(character))
                        gameValues.gameObjects ? localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects)) : null;
                        localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet));
                        gameValues.heroStartX = character.x
                        gameValues.heroStartY = character.y
                      }, 'character')
                    })
                    el.addEventListener('dragstart', (event) => {
                      return false;
                    })
                    canvasDiv.appendChild(el)
                    let sprites = {}
                    let newSprite = false
                    if (character && character.spriteArrayDeathLeft) {
                      let characterArray = []
                      character.spriteArrayDeathLeft.forEach(image => {
                        let imageName = image.split("/")[5]
                        characterArray.push(imageName)
                        if (!sprites.imageName) {
                          sprites[imageName] = image;
                          newSprite = true
                        }
                      })
                      gameValues.heroDeathLeftValues = characterArray
                    }
                    if (character && character.spriteArrayDeathRight) {
                      let characterArray = []
                      character.spriteArrayDeathRight.forEach(image => {
                        let imageName = image.split("/")[5]
                        characterArray.push(imageName)
                        if (!sprites.imageName) {
                          sprites[imageName] = image;
                          newSprite = true
                        }
                      })
                      gameValues.heroDeathRightValues = characterArray
                    }
                    if (character && character.spriteArrayHurtLeft) {
                      let characterArray = []
                      character.spriteArrayHurtLeft.forEach(image => {
                        let imageName = image.split("/")[5]
                        characterArray.push(imageName)
                        if (!sprites.imageName) {
                          sprites[imageName] = image;
                          newSprite = true
                        }
                      })
                      gameValues.heroHurtLeftValues = characterArray
                    }
                    if (character && character.spriteArrayHurtRight) {
                      let characterArray = []
                      character.spriteArrayHurtRight.forEach(image => {
                        let imageName = image.split("/")[5]
                        characterArray.push(imageName)
                        if (!sprites.imageName) {
                          sprites[imageName] = image;
                          newSprite = true
                        }
                      })
                      gameValues.heroHurtRightValues = characterArray
                    }
                    if (character && character.spriteArrayIdleLeft) {
                      let characterArray = []
                      character.spriteArrayIdleLeft.forEach(image => {
                        let imageName = image.split("/")[5]
                        characterArray.push(imageName)
                        if (!sprites.imageName) {
                          sprites[imageName] = image;
                          newSprite = true
                        }
                      })
                      gameValues.heroIdleLeftValues = characterArray
                    }
                    if (character && character.spriteArrayIdleRight) {
                      let characterArray = []
                      character.spriteArrayIdleRight.forEach(image => {
                        let imageName = image.split("/")[5]
                        characterArray.push(imageName)
                        if (!sprites.imageName) {
                          sprites[imageName] = image;
                          newSprite = true
                        }
                      })
                      gameValues.heroIdleRightValues = characterArray
                    }
                    if (character && character.spriteArrayJumpLeft) {
                      let characterArray = []
                      character.spriteArrayJumpLeft.forEach(image => {
                        let imageName = image.split("/")[5]
                        characterArray.push(imageName)
                        if (!sprites.imageName) {
                          sprites[imageName] = image;
                          newSprite = true
                        }
                      })
                      gameValues.heroJumpLeftValues = characterArray
                    }
                    if (character && character.spriteArrayJumpRight) {
                      let characterArray = []
                      character.spriteArrayJumpRight.forEach(image => {
                        let imageName = image.split("/")[5]
                        characterArray.push(imageName)
                        if (!sprites.imageName) {
                          sprites[imageName] = image;
                          newSprite = true
                        }
                      })
                      gameValues.heroJumpRightValues = characterArray
                    }
                    if (character && character.spriteArrayWalkLeft) {
                      let characterArray = []
                      character.spriteArrayWalkLeft.forEach(image => {
                        let imageName = image.split("/")[5]
                        characterArray.push(imageName)
                        if (!sprites.imageName) {
                          sprites[imageName] = image;
                          newSprite = true
                        }
                      })
                      gameValues.heroWalkLeftValues = characterArray
                    }
                    if (character && character.spriteArrayWalkRight) {
                      let characterArray = []
                      character.spriteArrayWalkRight.forEach(image => {
                        let imageName = image.split("/")[5]
                        characterArray.push(imageName)
                        if (!sprites.imageName) {
                          sprites[imageName] = image;
                          newSprite = true
                        }
                      })
                      gameValues.heroWalkRightValues = characterArray
                    }
                    if (newSprite) {
                      let imageLoadCount = 0;
                      let spritesLoaded = await loadSprites(sprites)
                      for (const [key, value] of Object.entries(spritesLoaded)) {
                        spritesLoaded[key].onload = () => {
                          imageLoadCount++;
                          if (imageLoadCount === Object.keys(spritesLoaded).length) {
                            gameValues.gameSprites = {...gameValues.gameSprites, ...spritesLoaded};
                          }
                        };
                      }
                    }
                  }}>{this.state.pageCopy.use}</button>
                  <button className="main-nav-link" style={{marginRight: "15px"}} onClick={() => {
                    this.setState({
                      show: 'player',
                      sidebarOpen: false,
                      playerIsEditing: true,
                      newPlayerAnimationArray: character.spriteArrayWalkLeft,
                      newPlayerActiveAnimationArray: 'walkLeft',
                      playerObjectName: character.name,
                      newPlayerAnimationArrayWalkLeft: character.spriteArrayWalkLeft,
                      newPlayerAnimationArrayWalkRight: character.spriteArrayWalkRight,
                      newPlayerAnimationArrayIdleLeft: character.spriteArrayIdleLeft,
                      newPlayerAnimationArrayIdleRight: character.spriteArrayIdleRight,
                      newPlayerAnimationArrayJumpLeft: character.spriteArrayJumpLeft,
                      newPlayerAnimationArrayJumpRight: character.spriteArrayJumpRight,
                      newPlayerAnimationArrayHurtLeft: character.spriteArrayHurtLeft,
                      newPlayerAnimationArrayHurtRight: character.spriteArrayHurtRight,
                      newPlayerAnimationArrayDeathLeft: character.spriteArrayDeathLeft,
                      newPlayerAnimationArrayDeathRight: character.spriteArrayDeathRight,
                      playerRunningSpeed: character.speed,
                      playerJumpSpeed: character.jumpSpeed,
                      editedCharacterID: character._id,
                    })
                  }}>{this.state.pageCopy.edit}</button>
                  <button className="main-nav-danger-link" style={{textTransform: "capitalize"}} onClick={async () => {
                    let load = confirm(this.state.pageCopy.deleteCharacterConfirm);
                    if (load) {
                      let characterRaw = localStorage.getItem("character")
                      let characterParsed = await JSON.parse(characterRaw)
                      if (characterParsed && characterParsed._id === character._id) {
                        localStorage.removeItem('character')
                      }
                      let deletedCharacter = await fetch(`${config.backend}/admin/delete-character`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                        body: JSON.stringify({
                          _id: character._id
                        })
                      })
                      let parsedDeletedCharacter = await deletedCharacter.json()
                      this.loadCharacters()
                    }
                  }}>{this.state.pageCopy.delete}</button>
                </div>
              )
            }): this.state.loadedGameObjects.map(loadedGameObject => {
              return (
                <div>
                  <img style={{margin: "10px", padding: "10px", width: '100px', border: "5px solid #eab700", borderRadius: "5px"}} src={loadedGameObject.spriteArrayLeft[0]}/>
                  <br/>{loadedGameObject.name}<br/><br/>
                  <button className="main-nav-link" style={{marginRight: "15px"}} onClick={async () => {
                    let canvasDiv = document.getElementById("canvas-container-tileset");
                    let div = document.createElement('div');
                    div.setAttribute('style', `position: absolute; left: 500px; top: 455px; z-index: 10000; width: ${loadedGameObject.width}px; height: ${loadedGameObject.width + 50}px; cursor: move;`)
                    var el = document.createElement('img');
                    let uniqueID = `gameObjectsObject${uuidv1()}`;
                    var copyIcon = document.createElement('div');
                    copyIcon.innerHTML = this.state.pageCopy.copy
                    copyIcon.setAttribute('style', 'position: absolute; left: 0; top: 0; z-index: 10000; width: 45px; height: 25px; cursor: pointer; background-color: green; color: white; border: 2px solid grey')
                    var deleteIcon = document.createElement('div');
                    deleteIcon.innerHTML = this.state.pageCopy.delete
                    deleteIcon.setAttribute('style', 'position: absolute; left: 53px; top: 0; z-index: 10000; width: 60px; height: 25px; cursor: pointer; background-color: red; color: white; border: 2px solid grey')
                    let style = `position: absolute; left: 0; top: 45px; z-index: 10000; width: ${loadedGameObject.width}px; cursor: move;`
                    loadedGameObject.spriteArrayLeft[0] ? el.setAttribute('src', `${loadedGameObject.spriteArrayLeft[0]}`) : el.setAttribute('src', 'https://pixopixa.cyou/images/logo-transparent.webp')
                    div.setAttribute('draggable', false);
                    el.setAttribute('draggable', false);
                    el.setAttribute('id', uniqueID)
                    el.setAttribute('style', style)
                    gameValues.gameObjects.push({...loadedGameObject, x: 500, y: 500, height: (el.naturalHeight/el.naturalWidth)*loadedGameObject.width, uniqueID: uniqueID, currentFrame: 0})
                    div.addEventListener('mousedown', (event) => {
                      event.stopPropagation()
                      this.handleMouseDownEvent(event, (event) => {
                        let itemLeft
                        let itemTop
                        let itemIndex
                        gameValues.gameObjects.forEach((item, index) => {
                          if (item.uniqueID === el.id) {
                            itemIndex = index
                            let itemElement = document.getElementById(item.uniqueID).parentElement
                            itemLeft = parseInt(itemElement.style.left.split('px')[0]);
                            itemTop = parseInt(itemElement.style.top.split('px')[0]); //- (window.pageYOffset + canvasDiv.getBoundingClientRect().top)
                          }
                        })
                        if (gameValues.gameObjects[itemIndex].movementType === "patrol") {
                          gameValues.gameObjects[itemIndex].patrolLeft = gameValues.gameObjects[itemIndex].patrolLeft + (itemLeft - gameValues.gameObjects[itemIndex].x)
                          gameValues.gameObjects[itemIndex].patrolRight = gameValues.gameObjects[itemIndex].patrolRight + (itemLeft - gameValues.gameObjects[itemIndex].x)
                        }
                        gameValues.gameObjects[itemIndex].x = itemLeft
                        gameValues.gameObjects[itemIndex].y = itemTop
                        localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects));
                        gameValues.tileSet ? localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet)) : null;
                      })
                    })
                    div.addEventListener('dragstart', (event) => {
                      event.stopPropagation()
                      return false;
                    })
                    div.addEventListener('contextmenu', (e) => {
                      let itemToEditIndex
                      let movingGameObjectMenu = document.getElementById('context-menu-game-object');
                      let menuPosition;
                      let menuPositionX;
                      let menuPositionY;
                      let itemToEditId = e.currentTarget.firstElementChild.id
                      gameValues.gameObjects.forEach((item, index) => {
                        if (item.uniqueID === itemToEditId) {
                          itemToEditIndex = index
                        }
                      })
                      e.preventDefault();
                      menuPosition = this.getPosition(e);
                      menuPositionX = menuPosition.x + "px";
                      menuPositionY = menuPosition.y + "px";
                      movingGameObjectMenu.style.left = menuPositionX;
                      movingGameObjectMenu.style.top = menuPositionY;
                      var rect = canvasDiv.getBoundingClientRect();
                      var stateX = e.clientX - rect.left;
                      var stateY = e.clientY - rect.top;
                      this.setState({
                        menuPositionX: `${stateX}px`,
                        menuPositionY: `${stateY}px`,
                        editedMovingGameObjectId: itemToEditId,
                      })
                      this.toggleMenuOn('gameObject');
                    })
                    let deleteHandler = (e) => {
                      let deleteElementId = e.currentTarget.parentElement.firstElementChild.id
                      let itemToDeleteIndex
                      gameValues.gameObjects.forEach((item, index) => {
                        if (item.uniqueID === deleteElementId) {
                          itemToDeleteIndex = index
                        }
                      })
                      gameValues.gameObjects.splice(itemToDeleteIndex, 1)
                      localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects))
                      gameValues.tileset ? localStorage.setItem('tileset', JSON.stringify(gameValues.tileset)) : null
                      let parent = e.currentTarget.parentElement.remove()
                    }
                    let copyHandler = (e) => {
                      let canvasDiv = document.getElementById("canvas-container-tileset");
                      let copy = e.currentTarget.parentElement.cloneNode(true)
                      let copyImage = copy.firstElementChild
                      let copiedCopyIcon = copy.children[1]
                      let copiedDeleteIcon = copy.children[2]
                      let uniquecopyID = `gameObjectsObject${uuidv1()}`;
                      copyImage.id = uniquecopyID
                      copy.style["left"] = `${parseInt(copy.style.left) + 100}px`
                      gameValues.gameObjects.push({...loadedGameObject, x: parseInt(copy.style.left), y: parseInt(copy.style.top), height: (copyImage.naturalHeight/copyImage.naturalWidth)*loadedGameObject.width, uniqueID: uniquecopyID, currentFrame: 0})
                      copy.addEventListener('mousedown', (event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        this.handleMouseDownEvent(event, (event) => {
                          let itemLeft
                          let itemTop
                          let itemIndex
                          gameValues.gameObjects.forEach((item, index) => {
                            if (item.uniqueID === copyImage.id) {
                              itemIndex = index
                              let itemElement = document.getElementById(item.uniqueID).parentElement
                              itemLeft = parseInt(itemElement.style.left.split('px')[0]);
                              itemTop = parseInt(itemElement.style.top.split('px')[0]); //- (window.pageYOffset + tileSetCanvas.getBoundingClientRect().top)
                            }
                          })
                          gameValues.gameObjects[itemIndex].x = itemLeft
                          gameValues.gameObjects[itemIndex].y = itemTop
                          localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects));
                          gameValues.tileSet ? localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet)) : null;
                        })
                      })
                      copy.addEventListener('dragstart', (event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        return false;
                      })
                      copiedCopyIcon.addEventListener("mousedown", (e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        copyHandler(e)
                      })
                      copiedDeleteIcon.addEventListener("mousedown", (e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        deleteHandler(e)
                      })
                      canvasDiv.appendChild(copy)
                      localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects))
                      gameValues.tileSet ? localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet)) : null
                    }
                    copyIcon.addEventListener("mousedown", (e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      copyHandler(e)}
                    )
                    deleteIcon.addEventListener("mousedown", (e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      deleteHandler(e)
                    })
                    div.appendChild(el)
                    div.appendChild(copyIcon)
                    div.appendChild(deleteIcon)
                    canvasDiv.appendChild(div)
                    let sprites = {}
                    let newSprite = false
                    if (loadedGameObject.spriteArrayLeft) {
                      loadedGameObject.spriteArrayLeft.forEach(image => {
                        let imageName = image.split("/")[5]
                        if (!sprites.imageName) {
                          sprites[imageName] = image;
                          newSprite = true
                        }
                      })
                    }
                    if (loadedGameObject.spriteArrayRight) {
                      loadedGameObject.spriteArrayRight.forEach(image => {
                        let imageName = image.split("/")[5] ? image.split("/")[5] : image.split("/")[4];
                        if (!sprites.imageName) {
                          sprites[imageName] = image;
                          let newSprite = true
                        }
                      })
                    }
                    if (newSprite) {
                      let imageLoadCount = 0;
                      let spritesLoaded = await loadSprites(sprites)
                      for (const [key, value] of Object.entries(spritesLoaded)) {
                        spritesLoaded[key].onload = () => {
                          imageLoadCount++;
                          if (imageLoadCount === Object.keys(spritesLoaded).length) {
                            gameValues.gameSprites = {...gameValues.gameSprites, ...spritesLoaded};
                          }
                        };
                      }
                    }
                    localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects))
                    gameValues.tileset ? localStorage.setItem('tileset', JSON.stringify(gameValues.tileset)) : null
                  }}>{this.state.pageCopy.add}</button>
                  <button className="main-nav-link" style={{marginRight: "15px"}} onClick={() => {
                    this.setState({
                      show: 'gameObject',
                      sidebarOpen: false,
                      gameObjectIsEditing: true,
                      gameObjectObjectName: loadedGameObject.name,
                      newgameObjectType: loadedGameObject.category,
                      newgameObjectAnimationArrayLeft: loadedGameObject.spriteArrayLeft,
                      newgameObjectAnimationArrayRight: loadedGameObject.spriteArrayRight,
                      newgameObjectWidth: loadedGameObject.width,
                      newgameObjectReward: loadedGameObject.reward,
                      newgameObjectRewardValue: loadedGameObject.rewardValue,
                      newgameObjectRewardRegionPaddingLeft: loadedGameObject.rewardRegion.paddLeft,
                      newgameObjectRewardRegionLeft: loadedGameObject.rewardRegion.width,
                      newgameObjectRewardRegionPaddingTop: loadedGameObject.rewardRegion.paddTop,
                      newgameObjectRewardRegionTop: loadedGameObject.rewardRegion.height,
                      newgameObjectDestroySelfOnReward: loadedGameObject.rewardDestroySelf,
                      newgameObjectReward2: loadedGameObject.reward2,
                      newgameObjectReward2Value: loadedGameObject.reward2Value,
                      newgameObjectReward2RegionPaddingLeft: loadedGameObject.reward2Region.paddLeft,
                      newgameObjectReward2RegionLeft: loadedGameObject.reward2Region.width,
                      newgameObjectReward2RegionPaddingTop: loadedGameObject.reward2Region.paddTop,
                      newgameObjectReward2RegionTop: loadedGameObject.reward2Region.height,
                      newgameObjectIsMoving: loadedGameObject.isMoving,
                      newgameObjectSpeed: loadedGameObject.speed,
                      newgameObjectAnimationArray: loadedGameObject.spriteArrayLeft,
                      newgameObjectActiveAnimationArray: 'left',
                      editedGameObjectID: loadedGameObject._id,
                      newGameObjectPreviewImage: loadedGameObject.spriteArrayLeft[0],
                    })
                  }}>{this.state.pageCopy.edit}</button>
                  <button className="main-nav-danger-link" style={{textTransform: "capitalize"}} onClick={async () => {
                    let load = confirm(this.state.pageCopy.deleteGameObjectConfirm);
                    if (load) {
                      let deletedGameObject = await fetch(`${config.backend}/admin/delete-game-object`, {
                        method: 'POST',
                        headers: {
                          'Content-Type': 'application/json',
                          Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                        body: JSON.stringify({
                          _id: loadedGameObject._id
                        })
                      })
                      let parsedDeletedGameObject = await deletedGameObject.json()
                      this.loadGameObjects()
                    }
                  }}>{this.state.pageCopy.delete}</button>
                </div>
              )
            })}
            </ul>
          </div>
        ) : (
          <div>
          </div>
        )
      }
      </div>
        <LoadGameModal show={this.state.show === "gameObject"} handleClose={() => this.setState({ show: false })} modalCloseHandler={this.modalCloseHandler} changeHandler={this.setGameValues} getState={this.getGameValues} checkDisabled={this.checkDisabled} createGameObject={this.createGameObject}/>
        <LoadPlayerModal show={this.state.show === "player"} handleClose={() => this.setState({ show: false })} modalCloseHandler={this.modalCloseHandler} changeHandler={this.setGameValues} getState={this.getGameValues} checkDisabled={this.checkDisabled} createPlayer={this.createPlayer}/>
        <div style={{ textAlign: 'center', backgroundColor: '#F0F0F0', paddingBottom: '2.5rem' }}>
          <div style={{border: "5px solid blue", backgroundColor: "#E1E1E1", margin: "40px", paddingLeft: "70px", textAlign: 'left'}}>
          <br/><br/>
          <h2 style={{ textAlign: 'center' }}>{this.state.pageCopy.addSprite}</h2>
          <input className="main-nav-link" type="file" name="sprites" id="sprites-picker" multiple="multiple"/>
          <br/>
          <br/>
          <div onChange={(e) => {this.setState({
            spriteType: e.target.value
          })}}>
            <input type="radio" value="/players/" name="gender" checked={this.state.spriteType === "/players/"}/> {this.state.pageCopy.optionPlayer} <br/>
            <input type="radio" value="/enemies/" name="gender" checked={this.state.spriteType === "/enemies/"}/> {this.state.pageCopy.optionEnemy} <br/>
            <input type="radio" value="/hazards/" name="gender" checked={this.state.spriteType === "/hazards/"} /> {this.state.pageCopy.optionHazard} <br/>
            <input type="radio" value="/gameObjects/" name="gender" checked={this.state.spriteType === "/gameObjects/"}/> {this.state.pageCopy.optionGameObject} <br/>
            <input type="radio" value="/tileSets/" name="gender" checked={this.state.spriteType === "/tileSets/"} /> {this.state.pageCopy.optionTiles} <br/>
            <input type="radio" value="/movingPlatforms/" name="gender" checked={this.state.spriteType === "/movingPlatforms/"}/> {this.state.pageCopy.optionMovingPlatform} <br/>
          </div><br/>
          <br/>
          <button className="main-nav-link" onClick={() => {this.handleImageSelect('sprites-picker')}}>{this.state.pageCopy.create}!</button><br/><br/>
          </div>
          <br />
          {this.state.spriteCreationFailed ? <div style={{backgroundColor: "#FF4200", color: "#ffffff", padding: "0.5rem", fontSize: "1rem", border: "1px solid red", width: "100%", fontWeight: "600", marginBottom: "1rem" }}>{this.state.pageCopy.spriteFailMessage}</div> : null}
          {this.state.spriteCreationSucceeded ? <div className="success-div" style={{ margin: '0 1rem' }}>{this.state.pageCopy.spriteFailMessage}</div> : null}
          <br />
          <div style={{border: "5px solid blue", backgroundColor: "#E1E1E1", margin: "40px", paddingLeft: "70px", textAlign: 'left'}}>
            <br/><br/>
            <h2 style={{ textAlign: 'center' }}>{this.state.pageCopy.gamelevelSettings}</h2>
              <div style={{display: 'flex'}}>
                <button className="main-nav-link-green" style={{width: "25%", margin: "15px"}} onClick={() => {this.createGameDraft()}}>{this.state.pageCopy.saveGame}</button>
                <button className="main-nav-link" style={{width: "25%", margin: "15px"}} onClick={() => {this.loadGameDrafts()}}>{this.state.pageCopy.loadGame}</button>
                <button className="main-nav-danger-link" style={{width: "25%", margin: "15px"}} onClick={() => {this.deleteGameDraft()}}>{this.state.pageCopy.deleteGame}</button>
                <button className="main-nav-link-yellow" style={{width: "25%", margin: "15px"}} onClick={() => {this.newGameDraft()}}>{this.state.pageCopy.newGame}</button>
              </div>
              {this.state.loadedGameDrafts && this.state.loadedGameDrafts.map((game, index) => {
                return (
                  <div style={{border: "2px dashed #0a3f93", width: "33%", padding: "5px"}} onClick={() => {
                    let load = confirm(this.state.pageCopy.loadConfirm);
                    if (load) {
                      this.setState({
                        backgroundDivColor: game.backgroundImageUrl,
                        gameDescriptionText: game.gameDescription,
                        backgroundDivColor: game.backgroundImageUrl,
                        gameName: game.gameName,
                        gamePlotText: game.plotText,
                        gameThumbnail: game.thumbnail,
                      })
                      game.tileSet && localStorage.setItem('tileset', JSON.stringify(game.tileSet))
                      game.backgroundImageUrl && localStorage.setItem('backgroundDivColor', game.backgroundImageUrl)
                      game.currentGameId && localStorage.setItem('currentGameId', game.currentGameId)
                      game.plotText && localStorage.setItem('gamePlotText', game.plotText)
                      game.gameDescription && localStorage.setItem('gameDescriptionText', game.gameDescription)
                      game.thumbnailUrl && localStorage.setItem('gameThumbnail', game.thumbnailUrl)
                      game.gameName && localStorage.setItem('gameName', game.gameName)
                      game.gameObjects && localStorage.setItem('gameObjects', JSON.stringify(game.gameObjects))
                      game.character && localStorage.setItem('character', JSON.stringify(game.character))
                      location.reload();
                    }
                  }}>
                    <img src={config.backend+ '/' + game.thumbnail} style={{maxWidth: "100%"}}/>
                    <h3 style={{textAlign: 'center'}}>{game.gameName}</h3>
                  </div>
                )
              })}
              <div className="tooltip" style={{margin: 'auto', width: '100%'}}><h2 style={{textAlign: 'center'}}>{this.state.pageCopy.gameName}</h2>
                <span className="tooltiptext">{this.state.pageCopy.gameNameTooltip}</span>
              </div><br/>
              <input className='send-url-input' placeholder={this.state.pageCopy.gameNamePlaceholder} value={this.state.gameName} onChange={(e) => {
                this.setState({
                  gameName: e.target.value
                })
                localStorage.setItem('gameName', e.target.value)
              }}/>
              <br/>
              <br/>
              <label htmlFor="colorPicker">{this.state.pageCopy.backgroundColor}</label><br/>
              <input name="colorPicker" type="color" value={this.state.backgroundDivColor} onChange={(e) => {
                localStorage.setItem('backgroundDivColor', e.target.value)
              }}/>
              <br/><br/>
              <h2 style={{ textAlign: 'center' }}>{this.state.pageCopy.gameThumbnail}</h2>
              <input className="main-nav-link" type="file" name="thumbnail" id="thumbnail-picker"/>
              <br/>
              <br/>
              <button className="main-nav-link" onClick={() => {
                  this.setState({
                    spriteType: '/thumbnails/',
                  }, () => {
                  this.handleImageSelect('thumbnail-picker')
                })
              }}>{this.state.pageCopy.createThumbnail}!</button><br/><br/>
              <div className="tooltip" style={{margin: 'auto', width: '100%'}}><h2 style={{textAlign: 'center'}}>{this.state.pageCopy.gamePlot}</h2>
                <span className="tooltiptext">{this.state.pageCopy.gamePlotTooltip}</span>
              </div><br/>
              <textarea className='text-area-large' placeholder={this.state.pageCopy.gamePlotPlaceholder} value={this.state.gamePlotText} onChange={(e) => {
                this.setState({
                  gamePlotText: e.target.value
                })
                localStorage.setItem('gamePlotText', e.target.value)
              }}/>
              <div className="tooltip" style={{margin: 'auto', width: '100%'}}><h2 style={{textAlign: 'center'}}>{this.state.pageCopy.gameDescription}</h2>
                <span className="tooltiptext">{this.state.pageCopy.gameDescriptionTooltip}</span>
              </div><br/>
              <textarea className='text-area-large' placeholder={this.state.pageCopy.gameDescriptionPlaceholder} value={this.state.gameDescriptionText} onChange={(e) => {
                this.setState({
                  gameDescriptionText: e.target.value
                })
                localStorage.setItem('gameDescriptionText', `${e.target.value}`)
                document.getElementById('test-html-output').innerHTML = e.target.value
              }}/>
              <h2>{this.state.pageCopy.previewHtml}</h2>
              <div id="test-html-output" style={{border: '2px dashed blue'}}></div>
          </div>
        </div>
        <h1 style={{ textAlign: 'center' }}>{this.state.pageCopy.previewGame}</h1>
        <audio ref={this.levelMusic} loop>
          <source src={Audio1} type="audio/mp3" />
        </audio>
        <audio ref={this.coinSound}>
          <source src={CoinSound} type="audio/wav" />
        </audio>
        <audio ref={this.jumpSound}>
          <source src={JumpSound} type="audio/wav" />
        </audio>
        <div style={{ textAlign: 'center', position: 'relative' }} id="canvas-container" tabIndex="-1">
          <div style={{
            position: 'absolute', left: '60px', bottom: '30px', zIndex: '220', backgroundColor: '#ffffff', padding: '0 0.15rem 0.15rem 0.15rem', border: '1px solid #114596',
          }}
          >
            <span style={{ width: '1.2rem', display: 'inline-block', verticalAlign: 'middle' }}>
              <FontAwesomeIcon
                icon={this.state.fullScreen ? faCompress : faExpand}
                style={{ color: '#114596' }}
                onClick={() => {
                  this.fullScreenGame();
                }}
              />
            </span>
          </div>
          <div style={{
            position: 'absolute', left: '110px', bottom: '30px', zIndex: '220', backgroundColor: '#ffffff', padding: '0 0.15rem 0.15rem 0.15rem', border: '1px solid #114596',
          }}
          >
            <span style={{ width: '1.2rem', display: 'inline-block', verticalAlign: 'middle' }}>
              <FontAwesomeIcon
                icon={faHome}
                style={{ color: '#114596' }}
                onClick={() => {
                  this.setState({
                    paused: false,
                    menuOpen: true,
                    mainMenuOpen: true,
                  });
                }}
              />
            </span>
          </div>
          <div
            title="press &quot;P&quot; to Pause"
            style={{
              position: 'absolute', left: '160px', bottom: '30px', zIndex: '220', backgroundColor: '#ffffff', padding: '0 0.15rem 0.15rem 0.15rem', border: '1px solid #114596',
            }}
          >
            <span style={{ width: '1.2rem', display: 'inline-block', verticalAlign: 'middle' }}>
              <FontAwesomeIcon
                icon={this.state.paused ? faPlay : faPause}
                style={{ color: '#114596', padding: '13% 10% 10% 10%' }}
                onClick={() => {
                  this.setState({
                    paused: !this.state.paused,
                  });
                }}
              />
            </span>
          </div>
          <div
            style={{
              position: 'absolute', left: '210px', bottom: '30px', zIndex: '220', backgroundColor: '#ffffff', padding: '0 0.15rem 0.15rem 0.15rem', border: '1px solid #114596',
            }}
            onMouseOver={() => { this.setState({ adjustingVolume: true }); }}
            onMouseOut={() => { this.setState({ adjustingVolume: false }); }}
          >
            <span style={{ width: '1.2rem', display: 'inline-block', verticalAlign: 'middle' }}>
              <FontAwesomeIcon
                icon={this.state.muted ? faVolumeMute : this.state.gameVolume > 0.5 ? faVolumeUp : faVolumeDown}
                style={{ color: '#114596' }}
                onClick={() => {
                  this.levelMusic.current.muted = !this.state.muted;
                  this.coinSound.current.muted = !this.state.muted;
                  this.jumpSound.current.muted = !this.state.muted;
                  this.setState({
                    muted: !this.state.muted,
                  });
                }}
              />
            </span>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={this.state.gameVolume}
              onChange={(e) => {
                this.levelMusic.current.volume = this.state.gameVolume;
                this.coinSound.current.volume = this.state.gameVolume;
                this.jumpSound.current.volume = this.state.gameVolume;
                this.setState({ gameVolume: e.target.value });
              }}
              className="volume-slider"
              style={{ verticalAlign: 'middle', marginLeft: '0.3rem', display: this.state.adjustingVolume ? 'inline-block' : 'none' }}
            />
          </div>
          {/* Pause Buttons */}
          <div style={{
            display: this.state.paused ? 'inline-block' : 'none', position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: '0', right: '0', top: '30vh', textAlign: 'center', zIndex: '300',
          }}
          >
            <div style={{ color: '#ffffff', fontSize: '2.8rem' }}>Game Paused</div>
            <button onClick={() => { this.setState({ paused: false }); }} className="game-button">Resume</button>
            <br />
            <button
              onClick={() => {
                this.setState({
                  paused: false,
                  menuOpen: true,
                  mainMenuOpen: true,
                });
              }}
              className="game-button"
            >
              Main Menu
            </button>
          </div>
          {/* Game Over Buttons */}
          <div style={{
            display: this.state.gameOver ? 'inline-block' : 'none', position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: '0', right: '0', top: '30vh', textAlign: 'center', zIndex: '300',
          }}
          >
            <div style={{ color: '#ffffff', fontSize: '2.8rem' }}>Game Over</div>
            <button
              onClick={() => {
                let myTileSet; let
                  myGameObjects;
                switch (this.state.currentLevel) {
                  case 1:
                    myTileSet = [...getTileSet("tileset")];
                    myGameObjects = [...getTileSet("gameObjects")];
                    break;
                  case 2:
                    myTileSet = [...tileset2];
                    myGameObjects = [...gameObjects2];
                    break;
                  case 3:
                    myTileSet = [...tileset3];
                    myGameObjects = [...gameObjects3];
                    break;
                  default:
                    myTileSet = [...getTileSet("tileset")];
                    myGameObjects = [...getTileSet("gameObjects")];
                }
                gameValues.tileSet = [...myTileSet];
                gameValues.gameObjects = [...myGameObjects];
                this.setState({
                  gameOver: false,
                  menuOpen: false,
                }, () => {
                  this.startGame();
                });
              }}
              className="game-button"
            >
              Retry
            </button>
            <br />
            <button
              onClick={() => {
                this.setState({
                  gameOver: false,
                  menuOpen: true,
                  mainMenuOpen: true,
                });
              }}
              className="game-button"
            >
              Main Menu
            </button>
          </div>
          {/* Main Menu Buttons */}
          { this.state.isloading
            ? (
              <div style={{
                position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: '0', right: '0', top: '15vh', textAlign: 'center', zIndex: '300',
              }}
              >
                <Loader type="Puff" />
                <p>Loading...</p>
              </div>
            )
            : (
              <>
                <div style={{
                  display: this.state.mainMenuOpen ? 'inline-block' : 'none', position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: '0', right: '0', top: '15vh', textAlign: 'center', zIndex: '300',
                }}
                >
                  <div style={{ color: '#ffffff', fontSize: '2.8rem' }}>
                    {this.state.gamePlotText}
                    {/* get hero name from server and put here */}
                  </div>
                  <button
                    disabled={!localStorage.getItem('character')}
                    onClick={() => {
                      this.setState({
                        // tileSet: [...tileset1],
                        // gameObjects: [...gameObjects1],
                        mainMenuOpen: false,
                        levelTextOpen: true,
                        currentLevel: 1,
                      });
                      gameValues.tileSet = [...getTileSet("tileset")];
                      gameValues.gameObjects = [...getTileSet("gameObjects")];
                    }}
                    className={!localStorage.getItem('character') ? 'game-button-disabled' : 'game-button'}
                  >
                    {localStorage.getItem('character') ? 'Start' : 'Please Create a Character'}
                  </button>
                  <br />
                  <button
                    onClick={() => {
                      this.setState({
                        // tileSet: [...tileset2],
                        // gameObjects: [...gameObjects2],
                        mainMenuOpen: false,
                        levelTextOpen: true,
                        currentLevel: 2,
                      });
                      gameValues.tileSet = [...tileset2];
                      gameValues.gameObjects = [...gameObjects2];
                    }}
                    className={this.state.level2Locked ? 'game-button-disabled' : 'game-button'}
                    disabled={this.state.level2Locked}
                  >
                    <FontAwesomeIcon icon={faLock} style={{ display: this.state.level2Locked ? 'inline-block' : 'none' }} />
                    {' '}
                    Level 2
                  </button>
                  <br />
                  <button
                    onClick={() => {
                      this.setState({
                        // tileSet: [...tileset3],
                        // gameObjects: [...gameObjects3],
                        mainMenuOpen: false,
                        levelTextOpen: true,
                        currentLevel: 3,
                      });
                      gameValues.tileSet = [...tileset3];
                      gameValues.gameObjects = [...gameObjects3];
                    }}
                    className={this.state.level3Locked ? 'game-button-disabled' : 'game-button'}
                    disabled={this.state.level3Locked}
                  >
                    <FontAwesomeIcon icon={faLock} style={{ display: this.state.level3Locked ? 'inline-block' : 'none' }} />
                    {' '}
                    Level 3
                  </button>
                </div>
              </>
            )}
          {/* Level Text */}
          <div style={{
            display: this.state.levelTextOpen ? 'inline-block' : 'none', position: 'absolute', marginLeft: 'auto', marginRight: 'auto', left: '0', right: '0', top: '15vh', textAlign: 'center', zIndex: '300',
          }}
          >
            <div style={{
              color: '#ffffff', fontSize: '1.3rem', width: '70%', margin: 'auto',
            }}
            >
              {decodeURI(this.state.level1Text)}
            </div>
            <br />
            <button
              onClick={() => {
                this.fullScreenGame();
                this.setState({
                  levelTextOpen: false,
                  menuOpen: false,
                }, () => {
                  this.startGame();
                });
              }}
              className="game-button"
            >
              Continue...
            </button>
          </div>

          <canvas className={this.state.fullScreen ? 'full-screen pixopixa-custom-background' : 'pixopixa-custom-background'} id="pixopixa-custom-background" style={{backgroundColor: `${this.state.backgroundDivColor}`}}/>
          <canvas id="pixopixa-custom-tileset" style={{ display: 'none' }} />
          <canvas id="pixopixa-custom-player" className={`pixopixa-custom-player ${this.state.fullScreen ? 'full-screen' : ''}`} />
          <canvas id="pixopixa-custom-ui" className={`pixopixa-custom-ui ${this.state.fullScreen ? 'full-screen' : ''}`} />
          <canvas id="pixopixa-custom-pause-screen" className={`pixopixa-custom-pause-screen ${this.state.fullScreen ? 'full-screen' : ''}`} style={{ display: (this.state.paused) ? 'inline-block' : 'none' }} />
          <canvas id="pixopixa-custom-menu-screen" className={`pixopixa-custom-menu-screen ${this.state.fullScreen ? 'full-screen' : ''}`} style={{ display: this.state.menuOpen ? 'inline-block' : 'none' }} />
        </div>
        <canvas id="hidden-canvas" width="1" height="1" style={{ display: 'none' }} />
        <br/>
        <br/>
        <hr/>
        <br/>
        <br/>
        <div style={{ textAlign: 'center', position: 'relative' }} id="canvas-container-tileset">
          <canvas id="pixopixa-custom-tileset-editor" />
        </div>
        <nav className="context-menu" id="context-menu" style={{width: "60vw"}}>
          <CreatePlaformEditor changeHandler={this.setGameValues} getState={this.getGameValues} createGameObject={this.createGameObject} handleMouseDownEvent={this.handleMouseDownEvent} canvasDiv={this.state.canvasDiv} handleDragStart={this.handleDragStart} toggleMenuOn={this.toggleMenuOn} getPosition={this.getPosition}/>
        </nav>
        <nav className="context-menu" id="context-menu-game-object" style={{width: "60vw"}}>
          <CreateMovingGameObjectEditor changeHandler={this.setGameValues} getState={this.getGameValues} createGameObject={this.createGameObject} handleDragEnd={this.handleDragEnd} canvasDiv={this.state.canvasDiv} handleMouseDownEvent={this.handleMouseDownEvent} toggleMenuOn={this.toggleMenuOn} getPosition={this.getPosition}/>
        </nav>
      </Layout>
    );
  }
}

export default Game;
