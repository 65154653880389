export const turkish = {
  /// Sprite Section
  addSprite: "Sprite Ekle",
  optionPlayer: "Oyuncu",
  optionEnemy: "Düşman",
  optionHazard: "Tehlikeli Obje [oyuncunun değdiğinde zarar gördüğü sabit parçalar]",
  optionGameObject: "Oyun Nesnesi", //OZGE: You'll see "Game Object" a fair bit essentially it will mean anything other than player and tiles, though it may or may not include enemies and hazards
  optionTiles: "Döşeme",
  optionMovingPlatform: "Hareketli Platform",
  spriteFailMessage: "Bir şeyler yanlış gitti, bu sprite kaydedilemedi",
  spriteSuccessMessage: "Sprite başarıyla kaydedildi!",

  /// Game Level Setting Section
  gamelevelSettings: "Oyun Seviye Ayarları",
  saveGame: "Oyunu Kaydet",
  loadGame: "Oyun Yükle",
  deleteGame: "Oyunu Sil",
  newGame: "Yeni Oyun",
  loadConfirm: "Yeni oyun mu yüklüyorsunuz? Bu tarayıcınızdaki tüm çalışmayı yenisiyle değiştirecek",
  deleteConfirm: "Oyunu silmek mi istiyorsunuz? Bu eylem geri döndürülemez",
  deleteCharacterConfirm: "Karakteri silmek mi istiyorsunuz? Bu eylem geri döndürülemez",
  deleteGameObjectConfirm: "Oyun Nesnelerini silmek mi istiyorsunuz? Bu eylem geri döndürülemez",
  gameName: "Oyun Adı", //OZGE: The name people will see if they choose this game
  gameNameTooltip: "Müşterilerin bu oyun için göreceği isim bu olacak",
  gameNamePlaceholder: "Lütfen Oyun Adını Girin",
  backgroundColor: "Arkaplan Rengi",
  gameThumbnail: "Oyun Küçük Resmi",
  createThumbnail: "Küçük Resim Oluştur",
  gamePlot: "Oyun Hikayesi", //OZGE: The customizable story people will see twhen they start the game "level Text" basocally
  // OZGE: NOTE FOR BELOW, DO NOT REPLACE TEXT BETWEEN '%' SYMBOLS, Ex: %name% should stay in English
  gamePlotTooltip: "Bu, müşterilerin üzerinde değişiklik yapabileceği hikaye kalıbıdır. Özel isteğe göre uyarlanmış isim, şehir, ödül değerleri için sırasıyla %name%, %city%, %enemy%, ve %reward% kullanın",
  gamePlotPlaceholder: "Oyun hikayesi metnini buraya girin",
  gameDescription: "Oyun Açıklaması",
  gameDescriptionTooltip: "Oyun önizleme sayfasında görünecek açıklamadır. Gerekli HTML etiketlerini kullanarak buraya yazınızı.",
  gameDescriptionPlaceholder: "HTML kullanarak oyun açıklaması metnini buraya doldurun",
  previewHtml: "HTML'in aşağıdaki önizlemesi",

  //Game Preview
  previewGame: "Oyunun Önizlemesini Göster",

  // Drag and Drop Section
  copy: "kopyala",
  delete: "sil",
  createMovingGameObject: "Hareketli Oyun Nesnesi Oluştur",
  typeOfMovingGameObject: "Hareketli Oyun Nesnesi Türü",
  none: "Hiçbiri",
  chargeLeft: "Sola Hücum Et",
  chargeRight: "Sağa Hücum Et",
  patrol: "Devriye Gez",
  objectSpeed: "Nesne Hızı",
  objectSpeedTooltip: "Oyun nesnesi hücum/devriye sırasında ne hızda hareket ediyor?",
  createMovingGameObject: "Hareketli Oyun Nesnesi Oluştur",
    // Platform
  platform: "Platform",
  createPlatform: "Platform Oluştur",
  platformLength: "Platform Uzunluğu",
  platformHeight: "Platform Yüksekliği",
  createPlatform: "Platform Oluştur",
  deletePlatform: "Platformu Sil",
  editPlatform: "Platformu Düzenle",
  clear: "Temizle",
    // Moving Platforms
  movingPlatforms: "Hareketli Platformlar",
  createMovingPlatformGroup: "Hareketli Platform Grubu Oluştur",
  numberOfPlatformsInGroup: "Gruptaki Platform Sayısı",
  movingPlatformLength: "Hareketli Platform Uzunluğu",
  platformHeight: "Platform Yüksekliği",
  platformStartingRight: "Bu nesne hareketine sağa yönelerek mi başlıyor?",
  platformStartingRightTooltip: "Nesne sağa doğru hareket etmeye mi başlayacak? (oyuncudan uzak tarafa doğru)",
  createMovingPlatformGroup: "Hareketli Platform Grubu Oluştur",

  // Left Sidebar
    // Character
  character: "Karakterler",
  create: "Oluştur",
  load: "Yükle",
  use: "Kullan",
  edit: "Düzenle",
  add: "Ekle",
  Animation: "Animasyon",
  walkLeft: "Sola Yürü",
  walkRight: "Sağa Yürü",
  idleLeft: "Sola Doğru Beklemede",
  idleRight: "Sağa Doğru Beklemede",
  jumpLeft: "Sola Atla",
  jumpRight: "Sağa Atla",
  hurtLeft: "Sol Yönde Yaralanma",
  hurtRight: "Sağ Yönde Yaralanma",
  deathLeft: "Sol Yönde Ölüm",
  deathRight: "Sağ Yönde Ölüm",
  characterName: "Karakter Adı",
  framesInAnimation: "Animasyon Kareleri",
  playerRunningSpeed: "Oyuncu Koşma Hızı",
  playerJumpSpeed: "Oyuncu Atlama Hızı",
  createCharacter: "Karakter Oluştur",
  editCharacter: "Karakteri Düzenle",
  createAnimation: "Animasyon Oluştur",
    // Game Object
  gameObjects: "Oyun Nesneleri",
  animateLeft: "Animasyonu Sola Doğru Oynat",
  animateRight: "Animasyonu Sağa Doğru Oynat",
  objectName: "Nesne Adı",
  objectType: "Nesne Türü",
  framesInAnimationTooltip: "Animasyonda kaç resim kullanılacak?",
  leftRightAnimation: "Animasyon çift taraflı olarak kullanılabilir mi?",
  leftRightAnimationTooltip: "Oyun nesnesi farklı yönlerde (sağ, sol) aynı şekilde mi hareket ediyor?",
  left: "Sol",
  right: "Sağ",
  no: "Hayır",
  yes: "Evet",
  width: "Genişlik",
  widthTooltip: "Piksel olarak eni, boyu ise eninden hesaplanacak",
  reward: "Ödül",
  /// New Section
  rewardNone: "Yok",
  rewardGround: "Yer",
  rewardCoin: "Madeni Para",
  rewardHealth: "Sağlık",
  rewardLife: "Can",
  rewardInvincibility: "Yenilmezlik",
  rewardTriggerChargeLeft: "Sola Hücum Tetikle",
  rewardTriggerChargeRight: "Sağa Hücum Tetikle",
  rewardCheckPoint: "Check Point",
  rewardEndPoint: "Bitiş Noktası (Çok Yakında)",
  rewardTriggerAnimation: "Animasyon Tetikle (Çok Yakında)",
////
  rewardTooltip: "Oyuncu nesneyle etkileşime geçtiğinde ne olacak?",
  rewardValue: "Ödül Değeri",
  rewardValueTooltip: "Oyuncunun ne kadar ödül kazanacağını gösterir. Eğer oyuncu bir sağlık puanı kaybedecekse -1 kullanın [NOT: Eğer etkileşim pozitif ya da negaatif olabiliyorsa, negatif sonuç için Ödül 2'yi kullanın. Eğer oyuncu nesneyi yok ettiğinde ödül almıyor ve yok etmeyi başaramadığında zarar görüyorsa Ödül 1'i 0'a getirip Ödül 2'yi yaralanma için kullanın]",
  destroySelfOnReward: "Ödül ulaşıldığında kendini yok ediyor mu",
  destroySelfOnRewardTooltip: "Oyuncu Ödül 1 kutusuna girdiğinde, oyun nesnesi oyundan kaldırılacak mı(bu durum Ödül 2 için geçerli değildir)?",
  heroBounceOnReward: "Oyuncu ödül üzerinde zıplayacak mı?",
  heroBounceOnRewardTooltip: "Oyuncu Ödül 1 kutusuna girdiğinde zıplayacak mı (bu durum ödül 2 için kullanılabilir değil)? Oyuncunun bir düşmanı üstüne zıplayarak yok ettiği durumlarda olağandır",
  true: "Doğru",
  false: "Yanlış",
  rewardRegionPaddingLeft: "Ödül Bölgesi: Sağa Kaydırılmış",
  rewardRegionPaddingTop: "Ödül Bölgesi: Alta kaydırılmış",
  rewardRegionLeft: "Ödül Bölgesi: Sol",
  rewardRegionTop: "Ödül Bölgesi: Üst",
  animationSpeed: "Animasyon Hızı",
  createGameObject: "Oyun Nesnesi Oluştur",
  reward2: "Ödül 2",
  reward2Value: "Ödül 2 Değeri",
  reward2RegionPaddingLeft: "Ödül 2 Bölgesi: Sağa Kaydırılmış",
  reward2RegionPaddingTop: "Reward 2 Bölgesi: Alta Kaydırılmış",
  reward2RegionLeft: "Reward 2 Bölgesi: Sol",
  reward2RegionTop: "Reward 2 Bölgesi: Üst",
}

export const english = {
  /// Sprite Section
  addSprite: "Add a Sprite",
  optionPlayer: "Player",
  optionEnemy: "Enemy",
  optionHazard: "Hazard",
  optionGameObject: "Game Object", //OZGE: You'll see "Game Object" a fair bit essentially it will mean anything other than player and tiles, though it may or may not include enemies and hazards
  optionTiles: "Tiles",
  optionMovingPlatform: "Moving Platform",
  spriteFailMessage: "Something went wrong, this sprite could not be saved",
  spriteSuccessMessage: "Sprite Saved Successfully!",

  /// Game Level Setting Section
  gamelevelSettings: "Game Level Settings",
  saveGame: "Save Game",
  loadGame: "Load Game",
  deleteGame: "Delete Game",
  newGame: "New Game",
  loadConfirm: "Load new game? This will replace any work in your browser",
  deleteConfirm: "Delete game? This action is not reversable",
  deleteCharacterConfirm: "Delete Character? This action is not reversable",
  deleteGameObjectConfirm: "Delete Game Object? This action is not reversable",
  gameName: "Game Name", //OZGE: The name people will see if they choose this game
  gameNameTooltip: "This is the name that will be shown to customers for this game",
  gameNamePlaceholder: "Please Enter Game Name",
  backgroundColor: "Background Color",
  gameThumbnail: "Game Thumbnail",
  createThumbnail: "Create Thumbnail",
  gamePlot: "Game Plot", //OZGE: The customizable story people will see twhen they start the game "level Text" basocally
  // OZGE: NOTE FOR BELOW, DO NOT REPLACE TEXT BETWEEN '%' SYMBOLS, Ex: %name% should stay in English
  gamePlotTooltip: "This is the story template the customer can edit. Use %name%, %city%, %enemy%, and %reward% for the custom name, city, enemy and reward values respectively",
  gamePlotPlaceholder: "Fill Game Plot Text Here",
  gameDescription: "Game Description",
  gameDescriptionTooltip: "The description that will appear on the game preview page. Write copy using appropriate HTML tags",
  gameDescriptionPlaceholder: "Fill Game Description Text Here Using HTML Markdown Language",
  previewHtml: "Preview HTML Below",

  //Game Preview
  previewGame: "Preview Game",

  // Drag and Drop Section
  copy: "copy",
  delete: "delete",
  createMovingGameObject: "Create Moving Game Object",
  typeOfMovingGameObject: "Type of Moving Game Object",
  none: "None",
  chargeLeft: "Charge Left",
  chargeRight: "Charge Right",
  patrol: "Patrol",
  objectSpeed: "Object Speed",
  objectSpeedTooltip: "How fast is the game object moving in its partol/charge?",
  createMovingGameObject: "Create Moving Game Object",
    // Platform
  platform: "Platform",
  createPlatform: "Create Platform",
  platformLength: "Platform Length",
  platformHeight: "Platform Height",
  createPlatform: "Create Platform",
  deletePlatform: "Delete Platform",
  editPlatform: "Edit Platform",
  clear: "Clear",
    // Moving Platforms
  movingPlatforms: "Moving Platforms",
  createMovingPlatformGroup: "Create Moving Platform Group",
  numberOfPlatformsInGroup: "Number of Platforms in Group",
  movingPlatformLength: "Moving Platform Length",
  platformHeight: "Platform Height",
  platformStartingRight: "Is This Item Starting To The Right",
  platformStartingRightTooltip: "Will the item begin moving to the right? (away from the player)",
  createMovingPlatformGroup: "Create Moving Platform Group",

  // Left Sidebar
    // Character
  character: "Characters",
  create: "Create",
  load: "Load",
  use: "Use",
  edit: "Edit",
  add: "Add",
  Animation: "Animation",
  walkLeft: "Walk Left",
  walkRight: "Walk Right",
  idleLeft: "Idle Left",
  idleRight: "Idle Right",
  jumpLeft: "Jump Left",
  jumpRight: "Jump Right",
  hurtLeft: "Hurt Left",
  hurtRight: "Hurt Right",
  deathLeft: "Death Left",
  deathRight: "Death Right",
  characterName: "Character Name",
  framesInAnimation: "Frames in Animation",
  playerRunningSpeed: "Player Running Speed",
  playerJumpSpeed: "Player Jump Speed",
  createCharacter: "Create Character",
  editCharacter: "Edit Character",
  createAnimation: "Create Animation",
    // Game Object
  gameObjects: "Game Objects",
  animateLeft: "Animate Left",
  animateRight: "Animate Right",
  objectName: "Object Name",
  objectType: "Object Type",
  framesInAnimationTooltip: "How Many Images Will Be Used in the Animation?",
  leftRightAnimation: "Left / Right Animation?",
  leftRightAnimationTooltip: "Will the game object animate differently when moving in different directions?",
  left: "Left",
  right: "Right",
  no: "No",
  yes: "Yes",
  width: "Width",
  widthTooltip: "Width in pixels, Height Will be calculated from width",
  reward: "Reward",
  /// New Section
  rewardNone: "None",
  rewardGround: "Ground",
  rewardCoin: "Coin",
  rewardHealth: "Health",
  rewardLife: "Life",
  rewardInvincibility: "Invincibility",
  rewardTriggerChargeLeft: "Trigger Charge Left",
  rewardTriggerChargeRight: "Trigger Charge Right",
  rewardCheckPoint: "Check Point",
  rewardEndPoint: "End Point (Coming Soon)",
  rewardTriggerAnimation: "Trigger Animation (Coming Soon)",
  ////
  rewardTooltip: "What will happen to the player when he interacts with the object?",
  rewardValue: "Reward Value",
  rewardValueTooltip: "How 'much' reward will the player receive, if the player loses a health, use -1 [NOTE: if the interaction can be positive or negative, use reward 2 for the negative outcome. If the player can destroy the item with no reward, or be hurt, set reward 1 to 0 and use reward 2 for injury]",
  destroySelfOnReward: "Destroy Self On Reward",
  destroySelfOnRewardTooltip: "Will the game object be removed from the game when player enters reward 1 box (this is not available for reward 2)?",
  heroBounceOnReward: "Hero Bounce on Reward?",
  heroBounceOnRewardTooltip: "Will the character bounce when they enter reward 1 box (this is not available for reward 2)? This is usual for a player jumping on and destroying an enemy",
  true: "True",
  false: "False",
  rewardRegionPaddingLeft: "Reward Region: Padding Left",
  rewardRegionPaddingTop: "Reward Region: Padding Top",
  rewardRegionLeft: "Reward Region: Left",
  rewardRegionTop: "Reward Region: Top",
  animationSpeed: "Animation Speed",
  createGameObject: "Create Game Object",
  reward2: "Reward 2",
  reward2Value: "Reward 2 Value",
  reward2RegionPaddingLeft: "Reward 2 Region: Padding Left",
  reward2RegionPaddingTop: "Reward 2 Region: Padding Top",
  reward2RegionLeft: "Reward 2 Region: Left",
  reward2RegionTop: "Reward 2 Region: Top",
}


export const musanese = {
  /// Sprite Section
  addSprite: "Mus Add a Sprite",
  optionPlayer: "Mus Player",
  optionEnemy: "Mus Enemy",
  optionHazard: "Mus Hazard",
  optionGameObject: "Mus Game Object", //OZGE: You'll see "Game Object" a fair bit essentially it will mean anything other than player and tiles, though it may or may not include enemies and hazards
  optionTiles: "Mus Tiles",
  optionMovingPlatform: "Mus Moving Platform",
  spriteFailMessage: "Mus Something went wrong, this sprite could not be saved",
  spriteSuccessMessage: "Mus Sprite Saved Successfully!",

  /// Game Level Setting Section
  gamelevelSettings: "Mus Game Level Settings",
  saveGame: "Mus Save Game",
  loadGame: "Mus Load Game",
  deleteGame: "Mus Delete Game",
  newGame: "Mus New Game",
  loadConfirm: "Mus Load new game? This will replace any work in your browser",
  deleteConfirm: "Mus Delete game? This action is not reversable",
  gameName: "Mus Game Name", //OZGE: The name people will see if they choose this game
  gameNameTooltip: "Mus This is the name that will be shown to customers for this game",
  gameNamePlaceholder: "Mus Please Enter Game Name",
  backgroundColor: "Mus Background Color",
  gameThumbnail: "Mus Game Thumbnail",
  createThumbnail: "Mus Create Thumbnail",
  gamePlot: "Mus Game Plot", //OZGE: The customizable story people will see twhen they start the game "level Text" basocally
  // OZGE: NOTE FOR BELOW, DO NOT REPLACE TEXT BETWEEN '%' SYMBOLS, Ex: %name% should stay in English
  gamePlotTooltip: "Mus This is the story template the customer can edit. Use %name%, %city%, %enemy%, and %reward% for the custom name, city, enemy and reward values respectively",
  gamePlotPlaceholder: "Mus Fill Game Plot Text Here",
  gameDescription: "Mus Game Description",
  gameDescriptionTooltip: "Mus The description that will appear on the game preview page. Write copy using appropriate HTML tags",
  gameDescriptionPlaceholder: "Mus Fill Game Description Text Here Using HTML Markdown Language",
  previewHtml: "Mus Preview HTML Below",

  //Game Preview
  previewGame: "Mus Preview Game",

  // Drag and Drop Section
  copy: "Mus copy",
  delete: "Mus delete",
  createMovingGameObject: "Mus Create Moving Game Object",
  typeOfMovingGameObject: "Mus Type of Moving Game Object",
  none: "None",
  chargeLeft: "Mus Charge Left",
  chargeRight: "Mus Charge Right",
  patrol: "Mus Patrol",
  objectSpeed: "Mus Object Speed",
  objectSpeedTooltip: "Mus How fast is the game object moving in its partol/charge?",
  createMovingGameObject: "Mus Create Moving Game Object",
    // Platform
  platform: "Mus Platform",
  createPlatform: "Mus Create Platform",
  platformLength: "Mus Platform Length",
  platformHeight: "Mus Platform Height",
  createPlatform: "Mus Create Platform",
  deletePlatform: "Mus Delete Platform",
  editPlatform: "Mus Edit Platform",
  clear: "Mus Clear",
    // Moving Platforms
  movingPlatforms: "Mus Moving Platforms",
  createMovingPlatformGroup: "Mus CreateMovingPlatformGroup",
  numberOfPlatformsInGroup: "Mus Number of Platforms in Group",
  movingPlatformLength: "Mus Moving Platform Length",
  platformHeight: "Mus Platform Height",
  platformStartingRight: "Mus Is This Platform Starting To The Right",
  platformStartingRightTooltip: "Mus Will the platform begin moving to the right? (away from the player)",
  createMovingPlatformGroup: "Mus Create Moving Platform Group",

  // Left Sidebar
    // Character
  character: "Mus Characters",
  create: "Mus Create",
  load: "Mus Load",
  use: "Mus Use",
  edit: "Mus Edit",
  add: "Mus Add",
  Animation: "Mus Animation",
  walkLeft: "Mus Walk Left",
  walkRight: "Mus Walk Right",
  idleLeft: "Mus Idle Left",
  idleRight: "Mus Idle Right",
  jumpLeft: "Mus Jump Left",
  jumpRight: "Mus Jump Right",
  hurtLeft: "Mus Hurt Left",
  hurtRight: "Mus Hurt Right",
  deathLeft: "Mus Death Left",
  deathRight: "Mus Death Right",
  characterName: "Mus Character Name",
  framesInAnimation: "Mus Frames in Animation",
  playerRunningSpeed: "Mus Player Running Speed",
  playerJumpSpeed: "Mus Player Jump Speed",
  createCharacter: "Mus Create Character",
  editCharacter: "Mus Edit Character",
  createAnimation: "Mus Create Animation",
    // Game Object
  gameObjects: "Game Objects",
  objectName: "Mus Object Name",
  objectType: "Mus Object Type",
  framesInAnimationTooltip: "Mus How Many Images Will Be Used in the Animation?",
  leftRightAnimation: "Mus Left / Right Animation?",
  leftRightAnimationTooltip: "Mus Will the game object animate differently when moving in different directions?",
  left: "Mus Left",
  right: "Mus Right",
  no: "Mus No",
  yes: "Mus Yes",
  width: "Mus Width",
  widthTooltip: "Mus Width in pixels, Height Will be calculated from width",
  reward: "Mus Reward",
  /// New Section
  rewardNone: "Mus None",
  rewardGround: "Mus Ground",
  rewardCoin: "Mus Coin",
  rewardHealth: "Mus Health",
  rewardLife: "Mus Life",
  rewardInvincibility: "Mus Invincibility",
  rewardTriggerChargeLeft: "Mus Trigger Charge Left",
  rewardTriggerChargeRight: "Mus Trigger Charge Right",
  rewardCheckPoint: "Mus Check Point",
  rewardEndPoint: "Mus End Point",
  rewardTriggerAnimation: "Mus Trigger Animation (Coming Soon)",
  ////
  rewardTooltip: "Mus What will happen to the player when he interacts with the object?",
  rewardValue: "Mus Reward Value",
  rewardValueTooltip: "Mus How 'much' reward will the player receive, if the player loses a health, use -1 [NOTE: if the interaction can be positive or negative, use reward 2 for the negative outcome. If the player can destroy the item with no reward, or be hurt, set reward 1 to 0 and use reward 2 for injury]",
  destroySelfOnReward: "Mus Destroy Self On Reward",
  destroySelfOnRewardTooltip: "Mus Will the game object be removed from the game when player enters reward 1 box (this is not available for reward 2)?",
  heroBounceOnReward: "Mus Hero Bounce on Reward?",
  heroBounceOnRewardTooltip: "Mus Will the character bounce when they enter reward 1 box (this is not available for reward 2)? This is usual for a player jumping on and destroying an enemy",
  true: "Mus True",
  false: "Mus False",
  rewardRegionPaddingLeft: "Mus Reward Region: Padding Left",
  rewardRegionPaddingTop: "Mus Reward Region: Padding Top",
  rewardRegionLeft: "Mus Reward Region: Left",
  rewardRegionTop: "Mus Reward Region: Top",
  reward2: "Mus Reward 2?",
  animationSpeed: "Mus Animation Speed",
  createGameObject: "Mus Create Game Object",
  reward2: "Mus Reward 2",
  reward2Value: "Mus Reward 2 Value",
  reward2RegionPaddingLeft: "Mus Reward 2 Region: Padding Left",
  reward2RegionPaddingTop: "Mus Reward 2 Region: Padding Top",
  reward2RegionLeft: "Mus Reward 2 Region: Left",
  reward2RegionTop: "Mus Reward 2 Region: Top",
}
