import config from '../config';
import { gameValues } from './render-game'
// SET UP ERROR HANDLING SO THE GAME WILL BREAK GRACEFULLY IF THERE IS A FAULTY IMAGE

export function loadSprites(imageContainer) {
  if (typeof window === 'undefined') return
  // an array of image URLs
  // let imageContainer = getSpriteArray(gameId)
  // an array of images
  const images = {};
  // for each image name create the image and put it into the images array
  for (const [key, value] of Object.entries(imageContainer)) {
    const image = new Image();
    image.draggable = true;
    image.src = value;
    image.crossOrigin = 'anonymous';
    images[key] = image;
  }
  return images;
}

export function generateCustomImagesWithVillian(
  hiddenCanvas,
  faceOnlyHero,
  faceOnlyVillian,
  // gameSprites,
) {
  // This is how a genious plays the game => gameSprites['hero-idle-left-1'] = "Mus Is Number One To Have Some Fun!"
  hiddenCanvas.width = 578;
  hiddenCanvas.height = 660;
  const hiddenContext = hiddenCanvas.getContext('2d');
  hiddenContext.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
  // var destX = 0;
  // var destY = 0;
  const destWidth = faceOnlyHero.width;
  const destHeight = faceOnlyHero.height;
  // gameSprites = gameSprites;
  let genSingleSprite = (name, offSetX, offSetY) => {
    hiddenContext.drawImage(faceOnlyHero, offSetX, offSetY, destWidth, destHeight);
    hiddenContext.beginPath();
    hiddenContext.rect(0, 0, faceOnlyHero.width, faceOnlyHero.height);
    hiddenContext.beginPath();
    hiddenContext.moveTo(200 + offSetX, 80 + offSetY);
    hiddenContext.quadraticCurveTo(280 + offSetX, 80 + offSetY, 280 + offSetX, 160 + offSetY);
    hiddenContext.quadraticCurveTo(280 + offSetX, 320 + offSetY, 200 + offSetX, 320 + offSetY);
    hiddenContext.quadraticCurveTo(120 + offSetX, 320 + offSetY, 120 + offSetX, 160 + offSetY);
    hiddenContext.quadraticCurveTo(120 + offSetX, 80 + offSetY, 200 + offSetX, 80 + offSetY);
    hiddenContext.closePath();
    hiddenContext.globalCompositeOperation = 'destination-in';
    hiddenContext.fillStyle = 'rgba(255, 255, 255, 1)';
    hiddenContext.fill();
    hiddenContext.globalCompositeOperation = 'source-over';
    hiddenContext.drawImage(gameValues.gameSprites[name], 25, 290, 350, 360);
    // const heroWalk1Sprite = hiddenCanvas.toDataURL();
    gameValues.gameSprites[name] = loadSprites({0: hiddenCanvas.toDataURL()})[0];
    hiddenContext.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
  }
  return
  ////// NEED TO FIX EVERYTHING BELOW HERE ////////
  genSingleSprite('hero-idle-left-1', 40, 0);
  genSingleSprite('hero-idle-left-2', 40, 0);
  genSingleSprite('hero-idle-left-3', 40, 0);
  genSingleSprite('hero-idle-left-4', 40, 10);
  genSingleSprite('hero-idle-left-5', 40, 0);
  genSingleSprite('hero-idle-right-1', -45, 0);
  genSingleSprite('hero-idle-right-2', -45, 0);
  genSingleSprite('hero-idle-right-3', -45, 0);
  genSingleSprite('hero-idle-right-4', -45, 10);
  genSingleSprite('hero-idle-right-5', -45, 0);
  genSingleSprite('hero-walk-left-1', 40, 0);
  genSingleSprite('hero-walk-left-2', 40, 0);
  genSingleSprite('hero-walk-left-3', 40, 0);
  genSingleSprite('hero-walk-left-4', 40, 0);
  genSingleSprite('hero-walk-left-5', 40, 0);
  genSingleSprite('hero-walk-left-6', 40, 0);
  genSingleSprite('hero-walk-left-7', 40, 0);
  genSingleSprite('hero-walk-right-1', -45, 0);
  genSingleSprite('hero-walk-right-2', -45, 0);
  genSingleSprite('hero-walk-right-3', -45, 0);
  genSingleSprite('hero-walk-right-4', -45, 0);
  genSingleSprite('hero-walk-right-5', -45, 0);
  genSingleSprite('hero-walk-right-6', -45, 0);
  genSingleSprite('hero-walk-right-7', -45, 0);
  genSingleSprite('hero-jump-left-1', 40, 0);
  genSingleSprite('hero-jump-left-2', 40, 0);
  genSingleSprite('hero-jump-left-3', 40, 0);
  genSingleSprite('hero-jump-left-4', 40, 0);
  genSingleSprite('hero-jump-left-5', 40, 0);
  genSingleSprite('hero-jump-left-6', 40, 0);
  genSingleSprite('hero-jump-left-7', 40, 0);
  genSingleSprite('hero-jump-right-1', -45, 0);
  genSingleSprite('hero-jump-right-2', -45, 0);
  genSingleSprite('hero-jump-right-3', -45, 0);
  genSingleSprite('hero-jump-right-4', -45, 0);
  genSingleSprite('hero-jump-right-5', -45, 0);
  genSingleSprite('hero-jump-right-6', -45, 0);
  genSingleSprite('hero-jump-right-7', -45, 0);
  genSingleSprite('hero-hurt-left-1', 40, 0);
  genSingleSprite('hero-hurt-left-2', 40, 0);
  genSingleSprite('hero-hurt-left-3', 40, 0);
  genSingleSprite('hero-hurt-left-4', 40, 0);
  genSingleSprite('hero-hurt-left-5', 40, 0);
  genSingleSprite('hero-hurt-left-6', 40, 0);
  genSingleSprite('hero-hurt-right-1', -45, 0);
  genSingleSprite('hero-hurt-right-2', -45, 0);
  genSingleSprite('hero-hurt-right-3', -45, 0);
  genSingleSprite('hero-hurt-right-4', -45, 0);
  genSingleSprite('hero-hurt-right-5', -45, 0);
  genSingleSprite('hero-hurt-right-6', -45, 0);
  genSingleSprite('hero-death-left-1', 40, 0);
  genSingleSprite('hero-death-left-2', 40, 0);
  genSingleSprite('hero-death-left-3', 40, 0);
  genSingleSprite('hero-death-left-4', 40, 0);
  genSingleSprite('hero-death-right-1', -45, 0);
  genSingleSprite('hero-death-right-2', -45, 0);
  genSingleSprite('hero-death-right-3', -45, 0);
  genSingleSprite('hero-death-right-4', -45, 0);





  // hiddenCanvas.width = 578;
  // hiddenCanvas.height = 660;
  // const hiddenContext = hiddenCanvas.getContext('2d');
  // hiddenContext.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
  // // var destX = 0;
  // // var destY = 0;
  // const destWidth = faceOnlyHero.width;
  // const destHeight = faceOnlyHero.height;
  //
  // /// Player Stand
  // hiddenContext.drawImage(faceOnlyHero, 0, 0, destWidth, destHeight);
  // hiddenContext.beginPath();
  // hiddenContext.rect(0, 0, faceOnlyHero.width, faceOnlyHero.height);
  // hiddenContext.beginPath();
  // hiddenContext.moveTo(200, 80);
  // hiddenContext.quadraticCurveTo(280, 80, 280, 160);
  // hiddenContext.quadraticCurveTo(280, 320, 200, 320);
  // hiddenContext.quadraticCurveTo(120, 320, 120, 160);
  // hiddenContext.quadraticCurveTo(120, 80, 200, 80);
  // hiddenContext.closePath();
  // hiddenContext.globalCompositeOperation = 'destination-in';
  // hiddenContext.fillStyle = 'rgba(255, 255, 255, 1)';
  // hiddenContext.fill();
  // hiddenContext.globalCompositeOperation = 'source-over';
  // hiddenContext.drawImage(gameSprites['hero-stand'], 25, 290, 350, 360);
  // const heroStandSprite = hiddenCanvas.toDataURL();
  // hiddenContext.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
  // /// ///////////////////////////////////////////////////////////
  // // Player Walk 1
  // hiddenContext.drawImage(faceOnlyHero, 0, 0, destWidth, destHeight);
  // hiddenContext.beginPath();
  // hiddenContext.rect(0, 0, faceOnlyHero.width, faceOnlyHero.height);
  // hiddenContext.beginPath();
  // hiddenContext.moveTo(200, 80);
  // hiddenContext.quadraticCurveTo(280, 80, 280, 160);
  // hiddenContext.quadraticCurveTo(280, 320, 200, 320);
  // hiddenContext.quadraticCurveTo(120, 320, 120, 160);
  // hiddenContext.quadraticCurveTo(120, 80, 200, 80);
  // hiddenContext.closePath();
  // hiddenContext.globalCompositeOperation = 'destination-in';
  // hiddenContext.fillStyle = 'rgba(255, 255, 255, 1)';
  // hiddenContext.fill();
  // hiddenContext.globalCompositeOperation = 'source-over';
  // hiddenContext.drawImage(gameSprites['hero-walk-1'], 25, 290, 350, 360);
  // const heroWalk1Sprite = hiddenCanvas.toDataURL();
  // hiddenContext.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
  // /// /////////////////////////////////////////////////////////////
  // /// Player Walk 2
  // hiddenContext.drawImage(faceOnlyHero, 0, 0, destWidth, destHeight);
  // hiddenContext.beginPath();
  // hiddenContext.rect(0, 0, faceOnlyHero.width, faceOnlyHero.height);
  // hiddenContext.beginPath();
  // hiddenContext.moveTo(200, 80);
  // hiddenContext.quadraticCurveTo(280, 80, 280, 160);
  // hiddenContext.quadraticCurveTo(280, 320, 200, 320);
  // hiddenContext.quadraticCurveTo(120, 320, 120, 160);
  // hiddenContext.quadraticCurveTo(120, 80, 200, 80);
  // hiddenContext.closePath();
  // hiddenContext.globalCompositeOperation = 'destination-in';
  // hiddenContext.fillStyle = 'rgba(255, 255, 255, 1)';
  // hiddenContext.fill();
  // hiddenContext.globalCompositeOperation = 'source-over';
  // hiddenContext.drawImage(gameSprites['hero-walk-2'], 25, 290, 350, 360);
  // const heroWalk2Sprite = hiddenCanvas.toDataURL();
  // hiddenContext.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
  // /// /////////////////////////////////////////////////////////////
  // /// Player Jump
  // hiddenContext.drawImage(faceOnlyHero, 0, 0, destWidth, destHeight);
  // hiddenContext.beginPath();
  // hiddenContext.rect(0, 0, faceOnlyHero.width, faceOnlyHero.height);
  // hiddenContext.beginPath();
  // hiddenContext.moveTo(200, 80);
  // hiddenContext.quadraticCurveTo(280, 80, 280, 160);
  // hiddenContext.quadraticCurveTo(280, 320, 200, 320);
  // hiddenContext.quadraticCurveTo(120, 320, 120, 160);
  // hiddenContext.quadraticCurveTo(120, 80, 200, 80);
  // hiddenContext.closePath();
  // hiddenContext.globalCompositeOperation = 'destination-in';
  // hiddenContext.fillStyle = 'rgba(255, 255, 255, 1)';
  // hiddenContext.fill();
  // hiddenContext.globalCompositeOperation = 'source-over';
  // hiddenContext.drawImage(gameSprites['hero-jump'], 25, 290, 350, 360);
  // const heroJumpSprite = hiddenCanvas.toDataURL();
  // hiddenContext.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
  // /// /////////////////////////////////////////////////////////////
  // /// Villian Stand
  // hiddenContext.drawImage(faceOnlyVillian, 0, 0, faceOnlyVillian.width, faceOnlyVillian.height);
  // hiddenContext.beginPath();
  // hiddenContext.rect(0, 0, faceOnlyVillian.width, faceOnlyVillian.height);
  // hiddenContext.beginPath();
  // hiddenContext.moveTo(200, 80);
  // hiddenContext.quadraticCurveTo(280, 80, 280, 160);
  // hiddenContext.quadraticCurveTo(280, 320, 200, 320);
  // hiddenContext.quadraticCurveTo(120, 320, 120, 160);
  // hiddenContext.quadraticCurveTo(120, 80, 200, 80);
  // hiddenContext.closePath();
  // hiddenContext.globalCompositeOperation = 'destination-in';
  // hiddenContext.fillStyle = 'rgba(255, 255, 255, 1)';
  // hiddenContext.fill();
  // hiddenContext.globalCompositeOperation = 'source-over';
  // hiddenContext.drawImage(gameSprites['villian-stand'], 25, 290, 350, 360);
  // const villianStandSprite = hiddenCanvas.toDataURL();
  // hiddenContext.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
  // /// /////////////////////////////////////////////////////////////
  // /// Villian Walk 1
  // hiddenContext.drawImage(faceOnlyVillian, 0, 0, faceOnlyVillian.width, faceOnlyVillian.height);
  // hiddenContext.beginPath();
  // hiddenContext.rect(0, 0, faceOnlyVillian.width, faceOnlyVillian.height);
  // hiddenContext.beginPath();
  // hiddenContext.moveTo(200, 80);
  // hiddenContext.quadraticCurveTo(280, 80, 280, 160);
  // hiddenContext.quadraticCurveTo(280, 320, 200, 320);
  // hiddenContext.quadraticCurveTo(120, 320, 120, 160);
  // hiddenContext.quadraticCurveTo(120, 80, 200, 80);
  // hiddenContext.closePath();
  // hiddenContext.globalCompositeOperation = 'destination-in';
  // hiddenContext.fillStyle = 'rgba(255, 255, 255, 1)';
  // hiddenContext.fill();
  // hiddenContext.globalCompositeOperation = 'source-over';
  // hiddenContext.drawImage(gameSprites['villian-walk-1'], 25, 290, 350, 360);
  // const villianWalk1Sprite = hiddenCanvas.toDataURL();
  // hiddenContext.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
  // /// /////////////////////////////////////////////////////////////
  // /// Villian Walk 2
  // hiddenContext.drawImage(faceOnlyVillian, 0, 0, faceOnlyVillian.width, faceOnlyVillian.height);
  // hiddenContext.beginPath();
  // hiddenContext.rect(0, 0, faceOnlyVillian.width, faceOnlyVillian.height);
  // hiddenContext.beginPath();
  // hiddenContext.moveTo(200, 80);
  // hiddenContext.quadraticCurveTo(280, 80, 280, 160);
  // hiddenContext.quadraticCurveTo(280, 320, 200, 320);
  // hiddenContext.quadraticCurveTo(120, 320, 120, 160);
  // hiddenContext.quadraticCurveTo(120, 80, 200, 80);
  // hiddenContext.closePath();
  // hiddenContext.globalCompositeOperation = 'destination-in';
  // hiddenContext.fillStyle = 'rgba(255, 255, 255, 1)';
  // hiddenContext.fill();
  // hiddenContext.globalCompositeOperation = 'source-over';
  // hiddenContext.drawImage(gameSprites['villian-walk-2'], 25, 290, 350, 360);
  // const villianWalk2Sprite = hiddenCanvas.toDataURL();
  // hiddenContext.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
  // /// /////////////////////////////////////////////////////////////
  // /// Villian Jump
  // hiddenContext.drawImage(faceOnlyVillian, 0, 0, faceOnlyVillian.width, faceOnlyVillian.height);
  // hiddenContext.beginPath();
  // hiddenContext.rect(0, 0, faceOnlyVillian.width, faceOnlyVillian.height);
  // hiddenContext.beginPath();
  // hiddenContext.moveTo(200, 80);
  // hiddenContext.quadraticCurveTo(280, 80, 280, 160);
  // hiddenContext.quadraticCurveTo(280, 320, 200, 320);
  // hiddenContext.quadraticCurveTo(120, 320, 120, 160);
  // hiddenContext.quadraticCurveTo(120, 80, 200, 80);
  // hiddenContext.closePath();
  // hiddenContext.globalCompositeOperation = 'destination-in';
  // hiddenContext.fillStyle = 'rgba(255, 255, 255, 1)';
  // hiddenContext.fill();
  // hiddenContext.globalCompositeOperation = 'source-over';
  // hiddenContext.drawImage(gameSprites['villian-jump'], 25, 290, 350, 360);
  // const villianJumpSprite = hiddenCanvas.toDataURL();
  // hiddenContext.clearRect(0, 0, hiddenCanvas.width, hiddenCanvas.height);
  // /// /////////////////////////////////////////////////////////////
  // return loadSprites({
  //   heroStandSprite,
  //   heroWalk1Sprite,
  //   heroWalk2Sprite,
  //   heroJumpSprite,
  //   villianStandSprite,
  //   villianWalk1Sprite,
  //   villianWalk2Sprite,
  //   villianJumpSprite,
  // });
}

export async function getSpriteArray(setState, getState, cb) {
  let sprites = {}
  let tiles = await JSON.parse(localStorage.getItem('tileset'))
  let gameObjects = await JSON.parse(localStorage.getItem('gameObjects'))
  let character = await JSON.parse(localStorage.getItem('character'))
  tiles && tiles.forEach(tile => {
    tile.imageArray.forEach(image => {
      let imageName = image.split("/tileSets/")[1]
      if (!sprites.imageName) {
        sprites[imageName] = image;
      }
    })
  })
  gameObjects && gameObjects.forEach(gameObject => {
    if (gameObject.spriteArrayLeft) {
      gameObject.spriteArrayLeft.forEach(image => {
        let imageName = image.split("/")[5]
        if (!sprites.imageName) {
          sprites[imageName] = image;
        }
      })
    }
    if (gameObject.spriteArrayRight) {
      gameObject.spriteArrayRight.forEach(image => {
        let imageName = image.split("/")[5] ? image.split("/")[5] : image.split("/")[4];
        if (!sprites.imageName) {
          sprites[imageName] = image;
        }
      })
    }
    if (gameObject.category === "moving-platforms") {
      gameObject.platforms && gameObject.platforms.forEach((platform, index) => {
        platform.imageArray && platform.imageArray.forEach((image, subIndex) => {
          let imageName = image.split("/")[5] ? image.split("/")[5] : image.split("/")[4];
          if (!sprites.imageName) {
            sprites[imageName] = image;
          }
        })
      })
    }
  })
  ////////////
  if (character && character.spriteArrayDeathLeft) {
    gameValues.heroStartX = character.x
    gameValues.heroStartY = character.y
    gameValues.cameraStartX = character.x - 500
    gameValues.cameraStartY = character.y - 300
    let characterArray = []
    character.spriteArrayDeathLeft.forEach(image => {
      let imageName = image.split("/")[5]
      characterArray.push(imageName)
      if (!sprites.imageName) {
        sprites[imageName] = image;
      }
    })
    gameValues.heroDeathLeftValues = characterArray
  }
  if (character && character.spriteArrayDeathRight) {
    let characterArray = []
    character.spriteArrayDeathRight.forEach(image => {
      let imageName = image.split("/")[5]
      characterArray.push(imageName)
      if (!sprites.imageName) {
        sprites[imageName] = image;
      }
    })
    gameValues.heroDeathRightValues = characterArray
  }
  if (character && character.spriteArrayHurtLeft) {
    let characterArray = []
    character.spriteArrayHurtLeft.forEach(image => {
      let imageName = image.split("/")[5]
      characterArray.push(imageName)
      if (!sprites.imageName) {
        sprites[imageName] = image;
      }
    })
    gameValues.heroHurtLeftValues = characterArray
  }
  if (character && character.spriteArrayHurtRight) {
    let characterArray = []
    character.spriteArrayHurtRight.forEach(image => {
      let imageName = image.split("/")[5]
      characterArray.push(imageName)
      if (!sprites.imageName) {
        sprites[imageName] = image;
      }
    })
    gameValues.heroHurtRightValues = characterArray
  }
  if (character && character.spriteArrayIdleLeft) {
    let characterArray = []
    character.spriteArrayIdleLeft.forEach(image => {
      let imageName = image.split("/")[5]
      characterArray.push(imageName)
      if (!sprites.imageName) {
        sprites[imageName] = image;
      }
    })
    gameValues.heroIdleLeftValues = characterArray
  }
  if (character && character.spriteArrayIdleRight) {
    let characterArray = []
    character.spriteArrayIdleRight.forEach(image => {
      let imageName = image.split("/")[5]
      characterArray.push(imageName)
      if (!sprites.imageName) {
        sprites[imageName] = image;
      }
    })
    gameValues.heroIdleRightValues = characterArray
  }
  if (character && character.spriteArrayJumpLeft) {
    let characterArray = []
    character.spriteArrayJumpLeft.forEach(image => {
      let imageName = image.split("/")[5]
      characterArray.push(imageName)
      if (!sprites.imageName) {
        sprites[imageName] = image;
      }
    })
    gameValues.heroJumpLeftValues = characterArray
  }
  if (character && character.spriteArrayJumpRight) {
    let characterArray = []
    character.spriteArrayJumpRight.forEach(image => {
      let imageName = image.split("/")[5]
      characterArray.push(imageName)
      if (!sprites.imageName) {
        sprites[imageName] = image;
      }
    })
    gameValues.heroJumpRightValues = characterArray
  }
  if (character && character.spriteArrayWalkLeft) {
    let characterArray = []
    character.spriteArrayWalkLeft.forEach(image => {
      let imageName = image.split("/")[5]
      characterArray.push(imageName)
      if (!sprites.imageName) {
        sprites[imageName] = image;
      }
    })
    gameValues.heroWalkLeftValues = characterArray
  }
  if (character && character.spriteArrayWalkRight) {
    let characterArray = []
    character.spriteArrayWalkRight.forEach(image => {
      let imageName = image.split("/")[5]
      characterArray.push(imageName)
      if (!sprites.imageName) {
        sprites[imageName] = image;
      }
    })
    gameValues.heroWalkRightValues = characterArray
  }
  if (character && character.speed) {
    gameValues.playerRunningSpeed = character.speed
  }
  if (character && character.jumpSpeed) {
    gameValues.playerJumpSpeed = character.jumpSpeed
  }
  ////////////
  console.log(sprites, "This is what we're after")
  let finishedsprites = await loadSprites(sprites)
  cb(finishedsprites)
  return
  switch (gameId) {
    case '1':
      return loadSprites({
        'hero-stand': `${config.img}standingMan.png`,
        'hero-walk-1': `${config.img}walkingMan1.png`,
        'hero-walk-2': `${config.img}walkingMan2.png`,
        'hero-jump': `${config.img}jumpingMan.png`,
        'villian-stand': `${config.img}villianStanding.png`,
        'villian-walk-1': `${config.img}villianStanding.png`,
        'villian-walk-2': `${config.img}villianStanding.png`,
        'villian-jump': `${config.img}villianStanding.png`,
        'pixopixa-logo-new': `${config.img}pixopixa-logo-new.png`,
        'pixopixa-logo-1': `${config.img}pixopixa-logo-1.png`,
        tile: `${config.img}tile.png`,
        'heart-full': `${config.img}heart-full.png`,
        'heart-half': `${config.img}heart-half.png`,
        'heart-empty': `${config.img}heart-empty.png`,
        'coin-full': `${config.img}coin-full.png`,
        'coin-2': `${config.img}coin-half.png`,
        'coin-3': `${config.img}coin-empty.png`,
        'checkpoint-0': `${config.img}checkpoint-0.png`,
        'checkpoint-1': `${config.img}checkpoint-1.png`,
        'checkpoint-2': `${config.img}checkpoint-2.png`,
        'checkpoint-3': `${config.img}checkpoint-3.png`,
        'checkpoint-4': `${config.img}checkpoint-4.png`,
        'checkpoint-5': `${config.img}checkpoint-5.png`,
        'checkpoint-6': `${config.img}checkpoint-6.png`,
        'checkpoint-7': `${config.img}checkpoint-7.png`,
        'checkpoint-8': `${config.img}checkpoint-8.png`,
        'checkpoint-9': `${config.img}checkpoint-9.png`,
        'level-exit': `${config.img}level-exit.png`,
        'level-exit-2': `${config.img}level-exit-2.png`,
        'enemy1-1': `${config.img}ghost1.png`,
        'enemy1-2': `${config.img}ghost2.png`,
        heart2: `${config.img}heart2.png`,
        heart3: `${config.img}heart3.png`,
        fire1: `${config.img}fire1.png`,
        fire2: `${config.img}fire2.png`,
        fire3: `${config.img}fire3.png`,
        life1: `${config.img}life1.png`,
        life2: `${config.img}life2.png`,
        life3: `${config.img}life3.png`,
        'bonus-box': `${config.img}bonus-box.png`,
        pineapple: `${config.img}pineapple.png`,
        'moving-platform-left-1': `${config.img}moving-platform-left-1.png`,
        'moving-platform-left-2': `${config.img}moving-platform-left-2.png`,
        'moving-platform-left-3': `${config.img}moving-platform-left-3.png`,
        'moving-platform-right-1': `${config.img}moving-platform-right-1.png`,
        'moving-platform-right-2': `${config.img}moving-platform-right-2.png`,
        'moving-platform-right-3': `${config.img}moving-platform-right-3.png`,
        'mossy-moving-platform-left-1': `${config.img}mossy-moving-platform-left-1.png`,
        'mossy-moving-platform-left-2': `${config.img}mossy-moving-platform-left-2.png`,
        'mossy-moving-platform-left-3': `${config.img}mossy-moving-platform-left-3.png`,
        'mossy-moving-platform-right-1': `${config.img}mossy-moving-platform-right-1.png`,
        'mossy-moving-platform-right-2': `${config.img}mossy-moving-platform-right-2.png`,
        'mossy-moving-platform-right-3': `${config.img}mossy-moving-platform-right-3.png`,
        cannon: `${config.img}cannon.png`,
        cannon2: `${config.img}cannon2.png`,
        cannon3: `${config.img}cannon3.png`,
        cannon4: `${config.img}cannon4.png`,
        thumbnail: `${config.img}to-birthday-and-beyond.png`,
      });
    case '2':
      return loadSprites({
        'hero-stand': `${config.img}standingMan.png`,
        'hero-walk-1': `${config.img}walkingMan1.png`,
        'hero-walk-2': `${config.img}walkingMan2.png`,
        'hero-jump': `${config.img}jumpingMan.png`,
        'villian-stand': `${config.img}villianStanding.png`,
        'villian-walk-1': `${config.img}villianStanding.png`,
        'villian-walk-2': `${config.img}villianStanding.png`,
        'villian-jump': `${config.img}villianStanding.png`,
        'pixopixa-logo-new': `${config.img}pixopixa-logo-new.png`,
        'pixopixa-logo-1': `${config.img}pixopixa-logo-1.png`,
        tile: `${config.img}tile.png`,
        'heart-full': `${config.img}heart-full.png`,
        'heart-half': `${config.img}heart-half.png`,
        'heart-empty': `${config.img}heart-empty.png`,
        'coin-full': `${config.img}coin-full.png`,
        'coin-2': `${config.img}coin-half.png`,
        'coin-3': `${config.img}coin-empty.png`,
        'checkpoint-0': `${config.img}checkpoint-0.png`,
        'checkpoint-1': `${config.img}checkpoint-1.png`,
        'checkpoint-2': `${config.img}checkpoint-2.png`,
        'checkpoint-3': `${config.img}checkpoint-3.png`,
        'checkpoint-4': `${config.img}checkpoint-4.png`,
        'checkpoint-5': `${config.img}checkpoint-5.png`,
        'checkpoint-6': `${config.img}checkpoint-6.png`,
        'checkpoint-7': `${config.img}checkpoint-7.png`,
        'checkpoint-8': `${config.img}checkpoint-8.png`,
        'checkpoint-9': `${config.img}checkpoint-9.png`,
        'level-exit': `${config.img}level-exit.png`,
        'level-exit-2': `${config.img}level-exit-2.png`,
        'enemy1-1': `${config.img}ghost1.png`,
        'enemy1-2': `${config.img}ghost2.png`,
        heart2: `${config.img}heart2.png`,
        heart3: `${config.img}heart3.png`,
        fire1: `${config.img}fire1.png`,
        fire2: `${config.img}fire2.png`,
        fire3: `${config.img}fire3.png`,
        life1: `${config.img}life1.png`,
        life2: `${config.img}life2.png`,
        life3: `${config.img}life3.png`,
        'bonus-box': `${config.img}bonus-box.png`,
        pineapple: `${config.img}pineapple.png`,
        'moving-platform-left-1': `${config.img}moving-platform-left-1.png`,
        'moving-platform-left-2': `${config.img}moving-platform-left-2.png`,
        'moving-platform-left-3': `${config.img}moving-platform-left-3.png`,
        'moving-platform-right-1': `${config.img}moving-platform-right-1.png`,
        'moving-platform-right-2': `${config.img}moving-platform-right-2.png`,
        'moving-platform-right-3': `${config.img}moving-platform-right-3.png`,
        'mossy-moving-platform-left-1': `${config.img}mossy-moving-platform-left-1.png`,
        'mossy-moving-platform-left-2': `${config.img}mossy-moving-platform-left-2.png`,
        'mossy-moving-platform-left-3': `${config.img}mossy-moving-platform-left-3.png`,
        'mossy-moving-platform-right-1': `${config.img}mossy-moving-platform-right-1.png`,
        'mossy-moving-platform-right-2': `${config.img}mossy-moving-platform-right-2.png`,
        'mossy-moving-platform-right-3': `${config.img}mossy-moving-platform-right-3.png`,
        cannon: `${config.img}cannon.png`,
        cannon2: `${config.img}cannon2.png`,
        cannon3: `${config.img}cannon3.png`,
        cannon4: `${config.img}cannon4.png`,
        thumbnail: `${config.img}birthday-bonanza.png`,
      });
    case '3':
      return loadSprites({
        'face-only': `${config.img}acbdc5f0-03ee-11eb-b3ef-6f58b9601130-faceOnly.png`,
        'face-only-villian': `${config.img}4d7b1bc0-5343-11eb-a44a-d1c46e1d9ce4-heroImage.png`,
        'hero-stand': `${config.img}standingMan.png`,
        'hero-walk-1': `${config.img}walkingMan1.png`,
        'hero-walk-2': `${config.img}walkingMan2.png`,
        'hero-jump': `${config.img}jumpingMan.png`,
        'villian-stand': `${config.img}villianStanding.png`,
        'villian-walk-1': `${config.img}villianStanding.png`,
        'villian-walk-2': `${config.img}villianStanding.png`,
        'villian-jump': `${config.img}villianStanding.png`,
        'pixopixa-logo-new': `${config.img}pixopixa-logo-new.png`,
        'pixopixa-logo-1': `${config.img}pixopixa-logo-1.png`,
        'tile': `${config.img}tile-dark.png`,
        // Should we make darker grey tiles for this game?
        'heart-full': `${config.img}heart-full.png`,
        'heart-half': `${config.img}heart-half.png`,
        'heart-empty': `${config.img}heart-empty.png`,
        // Coins are vials
        'coin-full': `${config.img}space-vial.png`,
        'coin-2': `${config.img}space-vial-2.png`,
        'coin-3': `${config.img}space-vial-3.png`,
        'checkpoint-0': `${config.img}checkpoint-0.png`,
        'checkpoint-1': `${config.img}checkpoint-1.png`,
        'checkpoint-2': `${config.img}checkpoint-2.png`,
        'checkpoint-3': `${config.img}checkpoint-3.png`,
        'checkpoint-4': `${config.img}checkpoint-4.png`,
        'checkpoint-5': `${config.img}checkpoint-5.png`,
        'checkpoint-6': `${config.img}checkpoint-6.png`,
        'checkpoint-7': `${config.img}checkpoint-7.png`,
        'checkpoint-8': `${config.img}checkpoint-8.png`,
        'checkpoint-9': `${config.img}checkpoint-9.png`,
        'level-exit': `${config.img}level-exit.png`,
        'level-exit-2': `${config.img}level-exit-2.png`,
        // Should use names like 'enemy 1 and enemy 2 or something, so it will work will all games'
        'enemy1-1': `${config.img}space-villian-1.png`,
        'enemy1-2': `${config.img}space-villian-2.png`,
        'heart2': `${config.img}heart2.png`,
        'heart3': `${config.img}heart3.png`,
        'fire1': `${config.img}fire1.png`,
        'fire2': `${config.img}fire2.png`,
        'fire3': `${config.img}fire3.png`,
        'life1': `${config.img}life1.png`,
        'life2': `${config.img}life2.png`,
        'life3': `${config.img}life3.png`,
        'bonus-box': `${config.img}bonus-box.png`,
        'pineapple': `${config.img}pineapple.png`,
        'moving-platform-left-1': `${config.img}moving-platform-left-1.png`,
        'moving-platform-left-2': `${config.img}moving-platform-left-2.png`,
        'moving-platform-left-3': `${config.img}moving-platform-left-3.png`,
        'moving-platform-right-1': `${config.img}moving-platform-right-1.png`,
        'moving-platform-right-2': `${config.img}moving-platform-right-2.png`,
        'moving-platform-right-3': `${config.img}moving-platform-right-3.png`,
        'mossy-moving-platform-left-1': `${config.img}mossy-moving-platform-left-1.png`,
        'mossy-moving-platform-left-2': `${config.img}mossy-moving-platform-left-2.png`,
        'mossy-moving-platform-left-3': `${config.img}mossy-moving-platform-left-3.png`,
        'mossy-moving-platform-right-1': `${config.img}mossy-moving-platform-right-1.png`,
        'mossy-moving-platform-right-2': `${config.img}mossy-moving-platform-right-2.png`,
        'mossy-moving-platform-right-3': `${config.img}mossy-moving-platform-right-3.png`,
        'cannon': `${config.img}cannon.png`,
        'cannon2': `${config.img}cannon2.png`,
        'cannon3': `${config.img}cannon3.png`,
        'cannon4': `${config.img}cannon4.png`,
        'thumbnail': `${config.img}to-birthday-and-beyond.png`,
      });
    case '4':
      return loadSprites({
        'hero-stand': `${config.img}standingMan.png`,
        'hero-walk-1': `${config.img}walkingMan1.png`,
        'hero-walk-2': `${config.img}walkingMan2.png`,
        'hero-jump': `${config.img}jumpingMan.png`,
        'villian-stand': `${config.img}villianStanding.png`,
        'villian-walk-1': `${config.img}villianStanding.png`,
        'villian-walk-2': `${config.img}villianStanding.png`,
        'villian-jump': `${config.img}villianStanding.png`,
        'pixopixa-logo-new': `${config.img}pixopixa-logo-new.png`,
        'pixopixa-logo-1': `${config.img}pixopixa-logo-1.png`,
        tile: `${config.img}tile.png`,
        'heart-full': `${config.img}heart-full.png`,
        'heart-half': `${config.img}heart-half.png`,
        'heart-empty': `${config.img}heart-empty.png`,
        'coin-full': `${config.img}coin-full.png`,
        'coin-2': `${config.img}coin-half.png`,
        'coin-3': `${config.img}coin-empty.png`,
        'checkpoint-0': `${config.img}checkpoint-0.png`,
        'checkpoint-1': `${config.img}checkpoint-1.png`,
        'checkpoint-2': `${config.img}checkpoint-2.png`,
        'checkpoint-3': `${config.img}checkpoint-3.png`,
        'checkpoint-4': `${config.img}checkpoint-4.png`,
        'checkpoint-5': `${config.img}checkpoint-5.png`,
        'checkpoint-6': `${config.img}checkpoint-6.png`,
        'checkpoint-7': `${config.img}checkpoint-7.png`,
        'checkpoint-8': `${config.img}checkpoint-8.png`,
        'checkpoint-9': `${config.img}checkpoint-9.png`,
        'level-exit': `${config.img}level-exit.png`,
        'level-exit-2': `${config.img}level-exit-2.png`,
        'enemy1-1': `${config.img}ghost1.png`,
        'enemy1-2': `${config.img}ghost2.png`,
        heart2: `${config.img}heart2.png`,
        heart3: `${config.img}heart3.png`,
        fire1: `${config.img}fire1.png`,
        fire2: `${config.img}fire2.png`,
        fire3: `${config.img}fire3.png`,
        life1: `${config.img}life1.png`,
        life2: `${config.img}life2.png`,
        life3: `${config.img}life3.png`,
        'bonus-box': `${config.img}bonus-box.png`,
        pineapple: `${config.img}pineapple.png`,
        'moving-platform-left-1': `${config.img}moving-platform-left-1.png`,
        'moving-platform-left-2': `${config.img}moving-platform-left-2.png`,
        'moving-platform-left-3': `${config.img}moving-platform-left-3.png`,
        'moving-platform-right-1': `${config.img}moving-platform-right-1.png`,
        'moving-platform-right-2': `${config.img}moving-platform-right-2.png`,
        'moving-platform-right-3': `${config.img}moving-platform-right-3.png`,
        'mossy-moving-platform-left-1': `${config.img}mossy-moving-platform-left-1.png`,
        'mossy-moving-platform-left-2': `${config.img}mossy-moving-platform-left-2.png`,
        'mossy-moving-platform-left-3': `${config.img}mossy-moving-platform-left-3.png`,
        'mossy-moving-platform-right-1': `${config.img}mossy-moving-platform-right-1.png`,
        'mossy-moving-platform-right-2': `${config.img}mossy-moving-platform-right-2.png`,
        'mossy-moving-platform-right-3': `${config.img}mossy-moving-platform-right-3.png`,
        cannon: `${config.img}cannon.png`,
        cannon2: `${config.img}cannon2.png`,
        cannon3: `${config.img}cannon3.png`,
        cannon4: `${config.img}cannon4.png`,
        thumbnail: `${config.img}workshop-dash.png`,
      });
    case '5':
      return loadSprites({
        'hero-stand': `${config.img}standingMan.png`,
        'hero-walk-1': `${config.img}walkingMan1.png`,
        'hero-walk-2': `${config.img}walkingMan2.png`,
        'hero-jump': `${config.img}jumpingMan.png`,
        'villian-stand': `${config.img}villianStanding.png`,
        'villian-walk-1': `${config.img}villianStanding.png`,
        'villian-walk-2': `${config.img}villianStanding.png`,
        'villian-jump': `${config.img}villianStanding.png`,
        'pixopixa-logo-new': `${config.img}pixopixa-logo-new.png`,
        'pixopixa-logo-1': `${config.img}pixopixa-logo-1.png`,
        tile: `${config.img}tile.png`,
        'heart-full': `${config.img}heart-full.png`,
        'heart-half': `${config.img}heart-half.png`,
        'heart-empty': `${config.img}heart-empty.png`,
        'coin-full': `${config.img}coin-full.png`,
        'coin-2': `${config.img}coin-half.png`,
        'coin-3': `${config.img}coin-empty.png`,
        'checkpoint-0': `${config.img}checkpoint-0.png`,
        'checkpoint-1': `${config.img}checkpoint-1.png`,
        'checkpoint-2': `${config.img}checkpoint-2.png`,
        'checkpoint-3': `${config.img}checkpoint-3.png`,
        'checkpoint-4': `${config.img}checkpoint-4.png`,
        'checkpoint-5': `${config.img}checkpoint-5.png`,
        'checkpoint-6': `${config.img}checkpoint-6.png`,
        'checkpoint-7': `${config.img}checkpoint-7.png`,
        'checkpoint-8': `${config.img}checkpoint-8.png`,
        'checkpoint-9': `${config.img}checkpoint-9.png`,
        'level-exit': `${config.img}level-exit.png`,
        'level-exit-2': `${config.img}level-exit-2.png`,
        'enemy1-1': `${config.img}ghost1.png`,
        'enemy1-2': `${config.img}ghost2.png`,
        heart2: `${config.img}heart2.png`,
        heart3: `${config.img}heart3.png`,
        fire1: `${config.img}fire1.png`,
        fire2: `${config.img}fire2.png`,
        fire3: `${config.img}fire3.png`,
        life1: `${config.img}life1.png`,
        life2: `${config.img}life2.png`,
        life3: `${config.img}life3.png`,
        'bonus-box': `${config.img}bonus-box.png`,
        pineapple: `${config.img}pineapple.png`,
        'moving-platform-left-1': `${config.img}moving-platform-left-1.png`,
        'moving-platform-left-2': `${config.img}moving-platform-left-2.png`,
        'moving-platform-left-3': `${config.img}moving-platform-left-3.png`,
        'moving-platform-right-1': `${config.img}moving-platform-right-1.png`,
        'moving-platform-right-2': `${config.img}moving-platform-right-2.png`,
        'moving-platform-right-3': `${config.img}moving-platform-right-3.png`,
        'mossy-moving-platform-left-1': `${config.img}mossy-moving-platform-left-1.png`,
        'mossy-moving-platform-left-2': `${config.img}mossy-moving-platform-left-2.png`,
        'mossy-moving-platform-left-3': `${config.img}mossy-moving-platform-left-3.png`,
        'mossy-moving-platform-right-1': `${config.img}mossy-moving-platform-right-1.png`,
        'mossy-moving-platform-right-2': `${config.img}mossy-moving-platform-right-2.png`,
        'mossy-moving-platform-right-3': `${config.img}mossy-moving-platform-right-3.png`,
        cannon: `${config.img}cannon.png`,
        cannon2: `${config.img}cannon2.png`,
        cannon3: `${config.img}cannon3.png`,
        cannon4: `${config.img}cannon4.png`,
        thumbnail: `${config.img}adventures-of-you.png`,
      });
    case '6':
      return loadSprites({
        'hero-stand': `${config.img}standingMan.png`,
        'hero-walk-1': `${config.img}walkingMan1.png`,
        'hero-walk-2': `${config.img}walkingMan2.png`,
        'hero-jump': `${config.img}jumpingMan.png`,
        'villian-stand': `${config.img}villianStanding.png`,
        'villian-walk-1': `${config.img}villianStanding.png`,
        'villian-walk-2': `${config.img}villianStanding.png`,
        'villian-jump': `${config.img}villianStanding.png`,
        'pixopixa-logo-new': `${config.img}pixopixa-logo-new.png`,
        'pixopixa-logo-1': `${config.img}pixopixa-logo-1.png`,
        tile: `${config.img}tile.png`,
        'heart-full': `${config.img}heart-full.png`,
        'heart-half': `${config.img}heart-half.png`,
        'heart-empty': `${config.img}heart-empty.png`,
        'coin-full': `${config.img}coin-full.png`,
        'coin-2': `${config.img}coin-half.png`,
        'coin-3': `${config.img}coin-empty.png`,
        'checkpoint-0': `${config.img}checkpoint-0.png`,
        'checkpoint-1': `${config.img}checkpoint-1.png`,
        'checkpoint-2': `${config.img}checkpoint-2.png`,
        'checkpoint-3': `${config.img}checkpoint-3.png`,
        'checkpoint-4': `${config.img}checkpoint-4.png`,
        'checkpoint-5': `${config.img}checkpoint-5.png`,
        'checkpoint-6': `${config.img}checkpoint-6.png`,
        'checkpoint-7': `${config.img}checkpoint-7.png`,
        'checkpoint-8': `${config.img}checkpoint-8.png`,
        'checkpoint-9': `${config.img}checkpoint-9.png`,
        'level-exit': `${config.img}level-exit.png`,
        'level-exit-2': `${config.img}level-exit-2.png`,
        'enemy1-1': `${config.img}ghost1.png`,
        'enemy1-2': `${config.img}ghost2.png`,
        heart2: `${config.img}heart2.png`,
        heart3: `${config.img}heart3.png`,
        fire1: `${config.img}fire1.png`,
        fire2: `${config.img}fire2.png`,
        fire3: `${config.img}fire3.png`,
        life1: `${config.img}life1.png`,
        life2: `${config.img}life2.png`,
        life3: `${config.img}life3.png`,
        'bonus-box': `${config.img}bonus-box.png`,
        pineapple: `${config.img}pineapple.png`,
        'moving-platform-left-1': `${config.img}moving-platform-left-1.png`,
        'moving-platform-left-2': `${config.img}moving-platform-left-2.png`,
        'moving-platform-left-3': `${config.img}moving-platform-left-3.png`,
        'moving-platform-right-1': `${config.img}moving-platform-right-1.png`,
        'moving-platform-right-2': `${config.img}moving-platform-right-2.png`,
        'moving-platform-right-3': `${config.img}moving-platform-right-3.png`,
        'mossy-moving-platform-left-1': `${config.img}mossy-moving-platform-left-1.png`,
        'mossy-moving-platform-left-2': `${config.img}mossy-moving-platform-left-2.png`,
        'mossy-moving-platform-left-3': `${config.img}mossy-moving-platform-left-3.png`,
        'mossy-moving-platform-right-1': `${config.img}mossy-moving-platform-right-1.png`,
        'mossy-moving-platform-right-2': `${config.img}mossy-moving-platform-right-2.png`,
        'mossy-moving-platform-right-3': `${config.img}mossy-moving-platform-right-3.png`,
        cannon: `${config.img}cannon.png`,
        cannon2: `${config.img}cannon2.png`,
        cannon3: `${config.img}cannon3.png`,
        cannon4: `${config.img}cannon4.png`,
        thumbnail: `${config.img}beat-the-ball-drop.png`,
      });
    case '7':
      return loadSprites({
        'tile': `${config.img}tile.png`,
        'hero-idle-left-1': `${config.img}players/${(heroVersion === 1) ? 'char1_idle_left_1.png' : 'char2_idle_left_1.png'}`,
        'hero-idle-left-2': `${config.img}players/${(heroVersion === 1) ? 'char1_idle_left_2.png' : 'char2_idle_left_2.png'}`,
        'hero-idle-left-3': `${config.img}players/${(heroVersion === 1) ? 'char1_idle_left_3.png' : 'char2_idle_left_3.png'}`,
        'hero-idle-left-4': `${config.img}players/${(heroVersion === 1) ? 'char1_idle_left_4.png' : 'char2_idle_left_4.png'}`,
        'hero-idle-left-5': `${config.img}players/${(heroVersion === 1) ? 'char1_idle_left_5.png' : 'char2_idle_left_5.png'}`,
        'hero-idle-right-1': `${config.img}players/${(heroVersion === 1) ? 'char1_idle_right_1.png' : 'char2_idle_right_1.png'}`,
        'hero-idle-right-2': `${config.img}players/${(heroVersion === 1) ? 'char1_idle_right_2.png' : 'char2_idle_right_2.png'}`,
        'hero-idle-right-3': `${config.img}players/${(heroVersion === 1) ? 'char1_idle_right_3.png' : 'char2_idle_right_3.png'}`,
        'hero-idle-right-4': `${config.img}players/${(heroVersion === 1) ? 'char1_idle_right_4.png' : 'char2_idle_right_4.png'}`,
        'hero-idle-right-5': `${config.img}players/${(heroVersion === 1) ? 'char1_idle_right_5.png' : 'char2_idle_right_5.png'}`,
        'hero-walk-left-1': `${config.img}players/${(heroVersion === 1) ? 'char1_walk_left_1.png' : 'char2_walk_left_1.png'}`,
        'hero-walk-left-2': `${config.img}players/${(heroVersion === 1) ? 'char1_walk_left_2.png' : 'char2_walk_left_2.png'}`,
        'hero-walk-left-3': `${config.img}players/${(heroVersion === 1) ? 'char1_walk_left_3.png' : 'char2_walk_left_3.png'}`,
        'hero-walk-left-4': `${config.img}players/${(heroVersion === 1) ? 'char1_walk_left_4.png' : 'char2_walk_left_4.png'}`,
        'hero-walk-left-5': `${config.img}players/${(heroVersion === 1) ? 'char1_walk_left_5.png' : 'char2_walk_left_5.png'}`,
        'hero-walk-left-6': `${config.img}players/${(heroVersion === 1) ? 'char1_walk_left_6.png' : 'char2_walk_left_6.png'}`,
        'hero-walk-left-7': `${config.img}players/${(heroVersion === 1) ? 'char1_walk_left_7.png' : 'char2_walk_left_7.png'}`,
        'hero-walk-right-1': `${config.img}players/${(heroVersion === 1) ? 'char1_walk_right_1.png' : 'char2_walk_right_1.png'}`,
        'hero-walk-right-2': `${config.img}players/${(heroVersion === 1) ? 'char1_walk_right_2.png' : 'char2_walk_right_2.png'}`,
        'hero-walk-right-3': `${config.img}players/${(heroVersion === 1) ? 'char1_walk_right_3.png' : 'char2_walk_right_3.png'}`,
        'hero-walk-right-4': `${config.img}players/${(heroVersion === 1) ? 'char1_walk_right_4.png' : 'char2_walk_right_4.png'}`,
        'hero-walk-right-5': `${config.img}players/${(heroVersion === 1) ? 'char1_walk_right_5.png' : 'char2_walk_right_5.png'}`,
        'hero-walk-right-6': `${config.img}players/${(heroVersion === 1) ? 'char1_walk_right_6.png' : 'char2_walk_right_6.png'}`,
        'hero-walk-right-7': `${config.img}players/${(heroVersion === 1) ? 'char1_walk_right_7.png' : 'char2_walk_right_7.png'}`,
        'hero-jump-left-1': `${config.img}players/${(heroVersion === 1) ? 'char1_jump_left_1.png' : 'char2_jump_left_1.png'}`,
        'hero-jump-left-2': `${config.img}players/${(heroVersion === 1) ? 'char1_jump_left_2.png' : 'char2_jump_left_2.png'}`,
        'hero-jump-left-3': `${config.img}players/${(heroVersion === 1) ? 'char1_jump_left_3.png' : 'char2_jump_left_3.png'}`,
        'hero-jump-left-4': `${config.img}players/${(heroVersion === 1) ? 'char1_jump_left_4.png' : 'char2_jump_left_4.png'}`,
        'hero-jump-left-5': `${config.img}players/${(heroVersion === 1) ? 'char1_jump_left_5.png' : 'char2_jump_left_5.png'}`,
        'hero-jump-left-6': `${config.img}players/${(heroVersion === 1) ? 'char1_jump_left_6.png' : 'char2_jump_left_6.png'}`,
        'hero-jump-left-7': `${config.img}players/${(heroVersion === 1) ? 'char1_jump_left_7.png' : 'char2_jump_left_7.png'}`,
        'hero-jump-right-1': `${config.img}players/${(heroVersion === 1) ? 'char1_jump_right_1.png' : 'char2_jump_right_1.png'}`,
        'hero-jump-right-2': `${config.img}players/${(heroVersion === 1) ? 'char1_jump_right_2.png' : 'char2_jump_right_2.png'}`,
        'hero-jump-right-3': `${config.img}players/${(heroVersion === 1) ? 'char1_jump_right_3.png' : 'char2_jump_right_3.png'}`,
        'hero-jump-right-4': `${config.img}players/${(heroVersion === 1) ? 'char1_jump_right_4.png' : 'char2_jump_right_4.png'}`,
        'hero-jump-right-5': `${config.img}players/${(heroVersion === 1) ? 'char1_jump_right_5.png' : 'char2_jump_right_5.png'}`,
        'hero-jump-right-6': `${config.img}players/${(heroVersion === 1) ? 'char1_jump_right_6.png' : 'char2_jump_right_6.png'}`,
        'hero-jump-right-7': `${config.img}players/${(heroVersion === 1) ? 'char1_jump_right_7.png' : 'char2_jump_right_7.png'}`,
        'hero-hurt-left-1': `${config.img}players/${(heroVersion === 1) ? 'char1_hurt_left_1.png' : 'char2_hurt_left_1.png'}`,
        'hero-hurt-left-2': `${config.img}players/${(heroVersion === 1) ? 'char1_hurt_left_2.png' : 'char2_hurt_left_2.png'}`,
        'hero-hurt-left-3': `${config.img}players/${(heroVersion === 1) ? 'char1_hurt_left_3.png' : 'char2_hurt_left_3.png'}`,
        'hero-hurt-left-4': `${config.img}players/${(heroVersion === 1) ? 'char1_hurt_left_4.png' : 'char2_hurt_left_4.png'}`,
        'hero-hurt-left-5': `${config.img}players/${(heroVersion === 1) ? 'char1_hurt_left_5.png' : 'char2_hurt_left_5.png'}`,
        'hero-hurt-left-6': `${config.img}players/${(heroVersion === 1) ? 'char1_hurt_left_6.png' : 'char2_hurt_left_6.png'}`,
        'hero-hurt-right-1': `${config.img}players/${(heroVersion === 1) ? 'char1_hurt_right_1.png' : 'char2_hurt_right_1.png'}`,
        'hero-hurt-right-2': `${config.img}players/${(heroVersion === 1) ? 'char1_hurt_right_2.png' : 'char2_hurt_right_2.png'}`,
        'hero-hurt-right-3': `${config.img}players/${(heroVersion === 1) ? 'char1_hurt_right_3.png' : 'char2_hurt_right_3.png'}`,
        'hero-hurt-right-4': `${config.img}players/${(heroVersion === 1) ? 'char1_hurt_right_4.png' : 'char2_hurt_right_4.png'}`,
        'hero-hurt-right-5': `${config.img}players/${(heroVersion === 1) ? 'char1_hurt_right_5.png' : 'char2_hurt_right_5.png'}`,
        'hero-hurt-right-6': `${config.img}players/${(heroVersion === 1) ? 'char1_hurt_right_6.png' : 'char2_hurt_right_6.png'}`,
        'hero-death-left-1': `${config.img}players/${(heroVersion === 1) ? 'char1_death_left_1.png' : 'char2_death_left_1.png'}`,
        'hero-death-left-2': `${config.img}players/${(heroVersion === 1) ? 'char1_death_left_2.png' : 'char2_death_left_2.png'}`,
        'hero-death-left-3': `${config.img}players/${(heroVersion === 1) ? 'char1_death_left_3.png' : 'char2_death_left_3.png'}`,
        'hero-death-left-4': `${config.img}players/${(heroVersion === 1) ? 'char1_death_left_4.png' : 'char2_death_left_4.png'}`,
        'hero-death-right-1': `${config.img}players/${(heroVersion === 1) ? 'char1_death_right_1.png' : 'char2_death_right_1.png'}`,
        'hero-death-right-2': `${config.img}players/${(heroVersion === 1) ? 'char1_death_right_2.png' : 'char2_death_right_2.png'}`,
        'hero-death-right-3': `${config.img}players/${(heroVersion === 1) ? 'char1_death_right_3.png' : 'char2_death_right_3.png'}`,
        'hero-death-right-4': `${config.img}players/${(heroVersion === 1) ? 'char1_death_right_4.png' : 'char2_death_right_4.png'}`,
        // tiles
        'platform-center': `${config.img}tileSets/sprite_000.png`,
        'platform-right': `${config.img}tileSets/sprite_001.png`,
        'platform-left': `${config.img}tileSets/sprite_002.png`,
        'support-vertical': `${config.img}tileSets/sprite_003.png`,
        'support-arm-right': `${config.img}tileSets/sprite_004.png`,
        'support-arm-left': `${config.img}tileSets/sprite_005.png`,
        'support-horizantal': `${config.img}tileSets/sprite_006.png`,
        'support-diagonal-left': `${config.img}tileSets/sprite_008.png`,
        'support-diagonal-right': `${config.img}tileSets/sprite_009.png`,
        'support-fork': `${config.img}tileSets/sprite_010.png`,
        'support-vertical-stub': `${config.img}tileSets/sprite_011.png`,
        'support-cable-top': `${config.img}tileSets/sprite_012.png`,
        'support-cable-bottom': `${config.img}tileSets/sprite_013.png`,
        'support-cable-middle': `${config.img}tileSets/sprite_014.png`,
        'cloud-blue-top-1': `${config.img}tileSets/sprite_015.png`,
        'cloud-blue-top-2': `${config.img}tileSets/sprite_019.png`,
        'cloud-blue-left': `${config.img}tileSets/sprite_017.png`,
        'cloud-blue-right': `${config.img}tileSets/sprite_021.png`,
        'cloud-blue-middle-1': `${config.img}tileSets/sprite_023.png`,
        'cloud-blue-middle-2': `${config.img}tileSets/sprite_025.png`,
        'cloud-pink-top-1': `${config.img}tileSets/sprite_016.png`,
        'cloud-pink-top-2': `${config.img}tileSets/sprite_020.png`,
        'cloud-pink-left': `${config.img}tileSets/sprite_018.png`,
        'cloud-pink-right': `${config.img}tileSets/sprite_022.png`,
        'cloud-pink-middle-1': `${config.img}tileSets/sprite_024.png`,
        'cloud-pink-middle-2': `${config.img}tileSets/sprite_026.png`,
        // Enemies
        'bear-walk-left-1': `${config.img}enemies/sprite_032.png`,
        'bear-walk-left-2': `${config.img}enemies/sprite_033.png`,
        'bear-walk-left-3': `${config.img}enemies/sprite_034.png`,
        'bear-walk-left-4': `${config.img}enemies/sprite_035.png`,
        'bear-walk-left-5': `${config.img}enemies/sprite_036.png`,
        'bear-walk-right-1': `${config.img}enemies/sprite_027.png`,
        'bear-walk-right-2': `${config.img}enemies/sprite_028.png`,
        'bear-walk-right-3': `${config.img}enemies/sprite_029.png`,
        'bear-walk-right-4': `${config.img}enemies/sprite_030.png`,
        'bear-walk-right-5': `${config.img}enemies/sprite_031.png`,
        'flower-walk-left-1': `${config.img}enemies/sprite_042.png`,
        'flower-walk-left-2': `${config.img}enemies/sprite_043.png`,
        'flower-walk-left-3': `${config.img}enemies/sprite_044.png`,
        'flower-walk-left-4': `${config.img}enemies/sprite_045.png`,
        'flower-walk-left-5': `${config.img}enemies/sprite_046.png`,
        'flower-walk-right-1': `${config.img}enemies/sprite_037.png`,
        'flower-walk-right-2': `${config.img}enemies/sprite_038.png`,
        'flower-walk-right-3': `${config.img}enemies/sprite_039.png`,
        'flower-walk-right-4': `${config.img}enemies/sprite_040.png`,
        'flower-walk-right-5': `${config.img}enemies/sprite_041.png`,
        // Unique Power Ups
        'perfume': `${config.img}gameObjects/sprite_071.png`,
        /// NEW ABOVE
        'villian-stand': `${config.img}villianStanding.png`,
        'villian-walk-1': `${config.img}villianStanding.png`,
        'villian-walk-2': `${config.img}villianStanding.png`,
        'villian-jump': `${config.img}villianStanding.png`,
        'pixopixa-logo-new': `${config.img}pixopixa-logo-new.png`,
        'pixopixa-logo-1': `${config.img}pixopixa-logo-1.png`,
        'heart-full': `${config.img}heart-full.png`,
        'heart-half': `${config.img}heart-half.png`,
        'heart-empty': `${config.img}heart-empty.png`,
        'coin-full': `${config.img}coin-full.png`,
        'coin-2': `${config.img}coin-half.png`,
        'coin-3': `${config.img}coin-empty.png`,
        'checkpoint-0': `${config.img}checkpoint-0.png`,
        'checkpoint-1': `${config.img}checkpoint-1.png`,
        'checkpoint-2': `${config.img}checkpoint-2.png`,
        'checkpoint-3': `${config.img}checkpoint-3.png`,
        'checkpoint-4': `${config.img}checkpoint-4.png`,
        'checkpoint-5': `${config.img}checkpoint-5.png`,
        'checkpoint-6': `${config.img}checkpoint-6.png`,
        'checkpoint-7': `${config.img}checkpoint-7.png`,
        'checkpoint-8': `${config.img}checkpoint-8.png`,
        'checkpoint-9': `${config.img}checkpoint-9.png`,
        'level-exit': `${config.img}level-exit.png`,
        'level-exit-2': `${config.img}level-exit-2.png`,
        'enemy1-1': `${config.img}ghost1.png`,
        'enemy1-2': `${config.img}ghost2.png`,
        'heart-2': `${config.img}heart2.png`,
        'heart-3': `${config.img}heart3.png`,
        'fire-1': `${config.img}fire1.png`,
        'fire-2': `${config.img}fire2.png`,
        'fire-3': `${config.img}fire3.png`,
        'life-1': `${config.img}life1.png`,
        'life-2': `${config.img}life2.png`,
        'life-3': `${config.img}life3.png`,
        'bonus-box': `${config.img}bonus-box.png`,
        pineapple: `${config.img}pineapple.png`,
        'moving-platform-left-1': `${config.img}moving-platform-left-1.png`,
        'moving-platform-left-2': `${config.img}moving-platform-left-2.png`,
        'moving-platform-left-3': `${config.img}moving-platform-left-3.png`,
        'moving-platform-right-1': `${config.img}moving-platform-right-1.png`,
        'moving-platform-right-2': `${config.img}moving-platform-right-2.png`,
        'moving-platform-right-3': `${config.img}moving-platform-right-3.png`,
        'mossy-moving-platform-left-1': `${config.img}mossy-moving-platform-left-1.png`,
        'mossy-moving-platform-left-2': `${config.img}mossy-moving-platform-left-2.png`,
        'mossy-moving-platform-left-3': `${config.img}mossy-moving-platform-left-3.png`,
        'mossy-moving-platform-right-1': `${config.img}mossy-moving-platform-right-1.png`,
        'mossy-moving-platform-right-2': `${config.img}mossy-moving-platform-right-2.png`,
        'mossy-moving-platform-right-3': `${config.img}mossy-moving-platform-right-3.png`,
        'cannon': `${config.img}cannon.png`,
        'cannon-2': `${config.img}cannon2.png`,
        'cannon-3': `${config.img}cannon3.png`,
        'cannon-4': `${config.img}cannon4.png`,
        thumbnail: `${config.img}cupids-gauntlet.png`,
      });
    case '8':
      return loadSprites({
        'hero-stand': `${config.img}standingMan.png`,
        'hero-walk-1': `${config.img}walkingMan1.png`,
        'hero-walk-2': `${config.img}walkingMan2.png`,
        'hero-jump': `${config.img}jumpingMan.png`,
        'villian-stand': `${config.img}villianStanding.png`,
        'villian-walk-1': `${config.img}villianStanding.png`,
        'villian-walk-2': `${config.img}villianStanding.png`,
        'villian-jump': `${config.img}villianStanding.png`,
        'pixopixa-logo-new': `${config.img}pixopixa-logo-new.png`,
        'pixopixa-logo-1': `${config.img}pixopixa-logo-1.png`,
        tile: `${config.img}tile.png`,
        'heart-full': `${config.img}heart-full.png`,
        'heart-half': `${config.img}heart-half.png`,
        'heart-empty': `${config.img}heart-empty.png`,
        'coin-full': `${config.img}coin-full.png`,
        'coin-2': `${config.img}coin-half.png`,
        'coin-3': `${config.img}coin-empty.png`,
        'checkpoint-0': `${config.img}checkpoint-0.png`,
        'checkpoint-1': `${config.img}checkpoint-1.png`,
        'checkpoint-2': `${config.img}checkpoint-2.png`,
        'checkpoint-3': `${config.img}checkpoint-3.png`,
        'checkpoint-4': `${config.img}checkpoint-4.png`,
        'checkpoint-5': `${config.img}checkpoint-5.png`,
        'checkpoint-6': `${config.img}checkpoint-6.png`,
        'checkpoint-7': `${config.img}checkpoint-7.png`,
        'checkpoint-8': `${config.img}checkpoint-8.png`,
        'checkpoint-9': `${config.img}checkpoint-9.png`,
        'level-exit': `${config.img}level-exit.png`,
        'level-exit-2': `${config.img}level-exit-2.png`,
        'enemy1-1': `${config.img}ghost1.png`,
        'enemy1-2': `${config.img}ghost2.png`,
        heart2: `${config.img}heart2.png`,
        heart3: `${config.img}heart3.png`,
        fire1: `${config.img}fire1.png`,
        fire2: `${config.img}fire2.png`,
        fire3: `${config.img}fire3.png`,
        life1: `${config.img}life1.png`,
        life2: `${config.img}life2.png`,
        life3: `${config.img}life3.png`,
        'bonus-box': `${config.img}bonus-box.png`,
        pineapple: `${config.img}pineapple.png`,
        'moving-platform-left-1': `${config.img}moving-platform-left-1.png`,
        'moving-platform-left-2': `${config.img}moving-platform-left-2.png`,
        'moving-platform-left-3': `${config.img}moving-platform-left-3.png`,
        'moving-platform-right-1': `${config.img}moving-platform-right-1.png`,
        'moving-platform-right-2': `${config.img}moving-platform-right-2.png`,
        'moving-platform-right-3': `${config.img}moving-platform-right-3.png`,
        'mossy-moving-platform-left-1': `${config.img}mossy-moving-platform-left-1.png`,
        'mossy-moving-platform-left-2': `${config.img}mossy-moving-platform-left-2.png`,
        'mossy-moving-platform-left-3': `${config.img}mossy-moving-platform-left-3.png`,
        'mossy-moving-platform-right-1': `${config.img}mossy-moving-platform-right-1.png`,
        'mossy-moving-platform-right-2': `${config.img}mossy-moving-platform-right-2.png`,
        'mossy-moving-platform-right-3': `${config.img}mossy-moving-platform-right-3.png`,
        cannon: `${config.img}cannon.png`,
        cannon2: `${config.img}cannon2.png`,
        cannon3: `${config.img}cannon3.png`,
        cannon4: `${config.img}cannon4.png`,
        thumbnail: `${config.img}birthday-derby.png`,
      });
    case '9':
      return loadSprites({
        'hero-stand': `${config.img}standingMan.png`,
        'hero-walk-1': `${config.img}walkingMan1.png`,
        'hero-walk-2': `${config.img}walkingMan2.png`,
        'hero-jump': `${config.img}jumpingMan.png`,
        'villian-stand': `${config.img}villianStanding.png`,
        'villian-walk-1': `${config.img}villianStanding.png`,
        'villian-walk-2': `${config.img}villianStanding.png`,
        'villian-jump': `${config.img}villianStanding.png`,
        'pixopixa-logo-new': `${config.img}pixopixa-logo-new.png`,
        'pixopixa-logo-1': `${config.img}pixopixa-logo-1.png`,
        tile: `${config.img}tile.png`,
        'heart-full': `${config.img}heart-full.png`,
        'heart-half': `${config.img}heart-half.png`,
        'heart-empty': `${config.img}heart-empty.png`,
        'coin-full': `${config.img}coin-full.png`,
        'coin-2': `${config.img}coin-half.png`,
        'coin-3': `${config.img}coin-empty.png`,
        'checkpoint-0': `${config.img}checkpoint-0.png`,
        'checkpoint-1': `${config.img}checkpoint-1.png`,
        'checkpoint-2': `${config.img}checkpoint-2.png`,
        'checkpoint-3': `${config.img}checkpoint-3.png`,
        'checkpoint-4': `${config.img}checkpoint-4.png`,
        'checkpoint-5': `${config.img}checkpoint-5.png`,
        'checkpoint-6': `${config.img}checkpoint-6.png`,
        'checkpoint-7': `${config.img}checkpoint-7.png`,
        'checkpoint-8': `${config.img}checkpoint-8.png`,
        'checkpoint-9': `${config.img}checkpoint-9.png`,
        'level-exit': `${config.img}level-exit.png`,
        'level-exit-2': `${config.img}level-exit-2.png`,
        'enemy1-1': `${config.img}ghost1.png`,
        'enemy1-2': `${config.img}ghost2.png`,
        heart2: `${config.img}heart2.png`,
        heart3: `${config.img}heart3.png`,
        fire1: `${config.img}fire1.png`,
        fire2: `${config.img}fire2.png`,
        fire3: `${config.img}fire3.png`,
        life1: `${config.img}life1.png`,
        life2: `${config.img}life2.png`,
        life3: `${config.img}life3.png`,
        'bonus-box': `${config.img}bonus-box.png`,
        pineapple: `${config.img}pineapple.png`,
        'moving-platform-left-1': `${config.img}moving-platform-left-1.png`,
        'moving-platform-left-2': `${config.img}moving-platform-left-2.png`,
        'moving-platform-left-3': `${config.img}moving-platform-left-3.png`,
        'moving-platform-right-1': `${config.img}moving-platform-right-1.png`,
        'moving-platform-right-2': `${config.img}moving-platform-right-2.png`,
        'moving-platform-right-3': `${config.img}moving-platform-right-3.png`,
        'mossy-moving-platform-left-1': `${config.img}mossy-moving-platform-left-1.png`,
        'mossy-moving-platform-left-2': `${config.img}mossy-moving-platform-left-2.png`,
        'mossy-moving-platform-left-3': `${config.img}mossy-moving-platform-left-3.png`,
        'mossy-moving-platform-right-1': `${config.img}mossy-moving-platform-right-1.png`,
        'mossy-moving-platform-right-2': `${config.img}mossy-moving-platform-right-2.png`,
        'mossy-moving-platform-right-3': `${config.img}mossy-moving-platform-right-3.png`,
        cannon: `${config.img}cannon.png`,
        cannon2: `${config.img}cannon2.png`,
        cannon3: `${config.img}cannon3.png`,
        cannon4: `${config.img}cannon4.png`,
        thumbnail: `${config.img}christmas-slider.png`,
      });
    case '10':
      return loadSprites({
        'hero-stand': `${config.img}standingMan.png`,
        'hero-walk-1': `${config.img}walkingMan1.png`,
        'hero-walk-2': `${config.img}walkingMan2.png`,
        'hero-jump': `${config.img}jumpingMan.png`,
        'villian-stand': `${config.img}villianStanding.png`,
        'villian-walk-1': `${config.img}villianStanding.png`,
        'villian-walk-2': `${config.img}villianStanding.png`,
        'villian-jump': `${config.img}villianStanding.png`,
        'pixopixa-logo-new': `${config.img}pixopixa-logo-new.png`,
        'pixopixa-logo-1': `${config.img}pixopixa-logo-1.png`,
        tile: `${config.img}tile.png`,
        'heart-full': `${config.img}heart-full.png`,
        'heart-half': `${config.img}heart-half.png`,
        'heart-empty': `${config.img}heart-empty.png`,
        'coin-full': `${config.img}coin-full.png`,
        'coin-2': `${config.img}coin-half.png`,
        'coin-3': `${config.img}coin-empty.png`,
        'checkpoint-0': `${config.img}checkpoint-0.png`,
        'checkpoint-1': `${config.img}checkpoint-1.png`,
        'checkpoint-2': `${config.img}checkpoint-2.png`,
        'checkpoint-3': `${config.img}checkpoint-3.png`,
        'checkpoint-4': `${config.img}checkpoint-4.png`,
        'checkpoint-5': `${config.img}checkpoint-5.png`,
        'checkpoint-6': `${config.img}checkpoint-6.png`,
        'checkpoint-7': `${config.img}checkpoint-7.png`,
        'checkpoint-8': `${config.img}checkpoint-8.png`,
        'checkpoint-9': `${config.img}checkpoint-9.png`,
        'level-exit': `${config.img}level-exit.png`,
        'level-exit-2': `${config.img}level-exit-2.png`,
        'enemy1-1': `${config.img}ghost1.png`,
        'enemy1-2': `${config.img}ghost2.png`,
        heart2: `${config.img}heart2.png`,
        heart3: `${config.img}heart3.png`,
        fire1: `${config.img}fire1.png`,
        fire2: `${config.img}fire2.png`,
        fire3: `${config.img}fire3.png`,
        life1: `${config.img}life1.png`,
        life2: `${config.img}life2.png`,
        life3: `${config.img}life3.png`,
        'bonus-box': `${config.img}bonus-box.png`,
        pineapple: `${config.img}pineapple.png`,
        'moving-platform-left-1': `${config.img}moving-platform-left-1.png`,
        'moving-platform-left-2': `${config.img}moving-platform-left-2.png`,
        'moving-platform-left-3': `${config.img}moving-platform-left-3.png`,
        'moving-platform-right-1': `${config.img}moving-platform-right-1.png`,
        'moving-platform-right-2': `${config.img}moving-platform-right-2.png`,
        'moving-platform-right-3': `${config.img}moving-platform-right-3.png`,
        'mossy-moving-platform-left-1': `${config.img}mossy-moving-platform-left-1.png`,
        'mossy-moving-platform-left-2': `${config.img}mossy-moving-platform-left-2.png`,
        'mossy-moving-platform-left-3': `${config.img}mossy-moving-platform-left-3.png`,
        'mossy-moving-platform-right-1': `${config.img}mossy-moving-platform-right-1.png`,
        'mossy-moving-platform-right-2': `${config.img}mossy-moving-platform-right-2.png`,
        'mossy-moving-platform-right-3': `${config.img}mossy-moving-platform-right-3.png`,
        cannon: `${config.img}cannon.png`,
        cannon2: `${config.img}cannon2.png`,
        cannon3: `${config.img}cannon3.png`,
        cannon4: `${config.img}cannon4.png`,
        thumbnail: `${config.img}beach-birthday.png`,
      });
    case '11':
      return loadSprites({
        'hero-stand': `${config.img}standingMan.png`,
        'hero-walk-1': `${config.img}walkingMan1.png`,
        'hero-walk-2': `${config.img}walkingMan2.png`,
        'hero-jump': `${config.img}jumpingMan.png`,
        'villian-stand': `${config.img}villianStanding.png`,
        'villian-walk-1': `${config.img}villianStanding.png`,
        'villian-walk-2': `${config.img}villianStanding.png`,
        'villian-jump': `${config.img}villianStanding.png`,
        'pixopixa-logo-new': `${config.img}pixopixa-logo-new.png`,
        'pixopixa-logo-1': `${config.img}pixopixa-logo-1.png`,
        tile: `${config.img}tile.png`,
        'heart-full': `${config.img}heart-full.png`,
        'heart-half': `${config.img}heart-half.png`,
        'heart-empty': `${config.img}heart-empty.png`,
        'coin-full': `${config.img}coin-full.png`,
        'coin-2': `${config.img}coin-half.png`,
        'coin-3': `${config.img}coin-empty.png`,
        'checkpoint-0': `${config.img}checkpoint-0.png`,
        'checkpoint-1': `${config.img}checkpoint-1.png`,
        'checkpoint-2': `${config.img}checkpoint-2.png`,
        'checkpoint-3': `${config.img}checkpoint-3.png`,
        'checkpoint-4': `${config.img}checkpoint-4.png`,
        'checkpoint-5': `${config.img}checkpoint-5.png`,
        'checkpoint-6': `${config.img}checkpoint-6.png`,
        'checkpoint-7': `${config.img}checkpoint-7.png`,
        'checkpoint-8': `${config.img}checkpoint-8.png`,
        'checkpoint-9': `${config.img}checkpoint-9.png`,
        'level-exit': `${config.img}level-exit.png`,
        'level-exit-2': `${config.img}level-exit-2.png`,
        'enemy1-1': `${config.img}ghost1.png`,
        'enemy1-2': `${config.img}ghost2.png`,
        heart2: `${config.img}heart2.png`,
        heart3: `${config.img}heart3.png`,
        fire1: `${config.img}fire1.png`,
        fire2: `${config.img}fire2.png`,
        fire3: `${config.img}fire3.png`,
        life1: `${config.img}life1.png`,
        life2: `${config.img}life2.png`,
        life3: `${config.img}life3.png`,
        'bonus-box': `${config.img}bonus-box.png`,
        pineapple: `${config.img}pineapple.png`,
        'moving-platform-left-1': `${config.img}moving-platform-left-1.png`,
        'moving-platform-left-2': `${config.img}moving-platform-left-2.png`,
        'moving-platform-left-3': `${config.img}moving-platform-left-3.png`,
        'moving-platform-right-1': `${config.img}moving-platform-right-1.png`,
        'moving-platform-right-2': `${config.img}moving-platform-right-2.png`,
        'moving-platform-right-3': `${config.img}moving-platform-right-3.png`,
        'mossy-moving-platform-left-1': `${config.img}mossy-moving-platform-left-1.png`,
        'mossy-moving-platform-left-2': `${config.img}mossy-moving-platform-left-2.png`,
        'mossy-moving-platform-left-3': `${config.img}mossy-moving-platform-left-3.png`,
        'mossy-moving-platform-right-1': `${config.img}mossy-moving-platform-right-1.png`,
        'mossy-moving-platform-right-2': `${config.img}mossy-moving-platform-right-2.png`,
        'mossy-moving-platform-right-3': `${config.img}mossy-moving-platform-right-3.png`,
        cannon: `${config.img}cannon.png`,
        cannon2: `${config.img}cannon2.png`,
        cannon3: `${config.img}cannon3.png`,
        cannon4: `${config.img}cannon4.png`,
        thumbnail: `${config.img}valentines-date-dash.png`,
      });
    case '12':
      return loadSprites({
        'hero-stand': `${config.img}standingMan.png`,
        'hero-walk-1': `${config.img}walkingMan1.png`,
        'hero-walk-2': `${config.img}walkingMan2.png`,
        'hero-jump': `${config.img}jumpingMan.png`,
        'villian-stand': `${config.img}villianStanding.png`,
        'villian-walk-1': `${config.img}villianStanding.png`,
        'villian-walk-2': `${config.img}villianStanding.png`,
        'villian-jump': `${config.img}villianStanding.png`,
        'pixopixa-logo-new': `${config.img}pixopixa-logo-new.png`,
        'pixopixa-logo-1': `${config.img}pixopixa-logo-1.png`,
        tile: `${config.img}tile.png`,
        'heart-full': `${config.img}heart-full.png`,
        'heart-half': `${config.img}heart-half.png`,
        'heart-empty': `${config.img}heart-empty.png`,
        'coin-full': `${config.img}coin-full.png`,
        'coin-2': `${config.img}coin-half.png`,
        'coin-3': `${config.img}coin-empty.png`,
        'checkpoint-0': `${config.img}checkpoint-0.png`,
        'checkpoint-1': `${config.img}checkpoint-1.png`,
        'checkpoint-2': `${config.img}checkpoint-2.png`,
        'checkpoint-3': `${config.img}checkpoint-3.png`,
        'checkpoint-4': `${config.img}checkpoint-4.png`,
        'checkpoint-5': `${config.img}checkpoint-5.png`,
        'checkpoint-6': `${config.img}checkpoint-6.png`,
        'checkpoint-7': `${config.img}checkpoint-7.png`,
        'checkpoint-8': `${config.img}checkpoint-8.png`,
        'checkpoint-9': `${config.img}checkpoint-9.png`,
        'level-exit': `${config.img}level-exit.png`,
        'level-exit-2': `${config.img}level-exit-2.png`,
        'enemy1-1': `${config.img}ghost1.png`,
        'enemy1-2': `${config.img}ghost2.png`,
        heart2: `${config.img}heart2.png`,
        heart3: `${config.img}heart3.png`,
        fire1: `${config.img}fire1.png`,
        fire2: `${config.img}fire2.png`,
        fire3: `${config.img}fire3.png`,
        life1: `${config.img}life1.png`,
        life2: `${config.img}life2.png`,
        life3: `${config.img}life3.png`,
        'bonus-box': `${config.img}bonus-box.png`,
        pineapple: `${config.img}pineapple.png`,
        'moving-platform-left-1': `${config.img}moving-platform-left-1.png`,
        'moving-platform-left-2': `${config.img}moving-platform-left-2.png`,
        'moving-platform-left-3': `${config.img}moving-platform-left-3.png`,
        'moving-platform-right-1': `${config.img}moving-platform-right-1.png`,
        'moving-platform-right-2': `${config.img}moving-platform-right-2.png`,
        'moving-platform-right-3': `${config.img}moving-platform-right-3.png`,
        'mossy-moving-platform-left-1': `${config.img}mossy-moving-platform-left-1.png`,
        'mossy-moving-platform-left-2': `${config.img}mossy-moving-platform-left-2.png`,
        'mossy-moving-platform-left-3': `${config.img}mossy-moving-platform-left-3.png`,
        'mossy-moving-platform-right-1': `${config.img}mossy-moving-platform-right-1.png`,
        'mossy-moving-platform-right-2': `${config.img}mossy-moving-platform-right-2.png`,
        'mossy-moving-platform-right-3': `${config.img}mossy-moving-platform-right-3.png`,
        cannon: `${config.img}cannon.png`,
        cannon2: `${config.img}cannon2.png`,
        cannon3: `${config.img}cannon3.png`,
        cannon4: `${config.img}cannon4.png`,
        thumbnail: `${config.img}princess-perils.png`,
      });
    default:
      // Create some type of Error case here, maybe redirect to an error page?
      return loadSprites({
        'pixopixa-logo-new': `${config.img}pixopixa-logo-new.png`,
        'pixopixa-logo-1': `${config.img}pixopixa-logo-1.png`,
        'hero-stand': `${config.img}standingMan.png`,
        'hero-walk-1': `${config.img}walkingMan1.png`,
        'hero-walk-2': `${config.img}walkingMan2.png`,
        'hero-jump': `${config.img}jumpingMan.png`,
        'villian-stand': `${config.img}villianStanding.png`,
        'villian-walk-1': `${config.img}villianStanding.png`,
        'villian-walk-2': `${config.img}villianStanding.png`,
        'villian-jump': `${config.img}villianStanding.png`,
      });
  }
}
