import React from 'react';
import { navigate } from 'gatsby';
import ImgNextGen from './ImgNextGen';
import config from './config';
import { v1 as uuidv1 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDolly, faGopuram, faCheckCircle, faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import {
  gameValues
} from './game-engine-admin/render-game';

const LoadGameModal = ({
  changeHandler, getState, createGameObject, handleDragEnd, canvasDiv, handleMouseDownEvent, toggleMenuOn, getPosition
}) => {

  return (
    <div>
          <h3>{getState().pageCopy.createMovingGameObject}</h3>
          <label htmlFor="moving-game-object-type">{getState().pageCopy.typeOfMovingGameObject}</label><br/>
          <select name="moving-game-object-type" id="moving-game-object-type" onChange={(e) => {changeHandler({
              newMovingGameObjectType: e.target.value
            })
          }}>
            <option value="">{getState().pageCopy.none}</option>
            <option value="chargeLeft">{getState().pageCopy.chargeLeft}</option>
            <option value="chargeRight">{getState().pageCopy.chargeRight}</option>
            <option value="patrol">{getState().pageCopy.patrol}</option>
          </select><br/>
          {getState().newMovingGameObjectType === "patrol" ? <>
              <div className="tooltip">{getState().pageCopy.platformStartingRight}
                <span className="tooltiptext" style={{paddingLeft: "45px"}}>{getState().pageCopy.platformStartingRightTooltip}</span>
              </div>
              <form>
                <input type="radio" value="false" name="right-first" checked={getState().newMovingGameObjectIsMovingRight === false} onClick={(e) => {changeHandler({
                    newMovingGameObjectIsMovingRight: false
                  })
                }}/> {getState().pageCopy.no} <br/>
                <input type="radio" value="true" name="right-first" checked={getState().newMovingGameObjectIsMovingRight === true} onClick={(e) => {changeHandler({
                    newMovingGameObjectIsMovingRight: true
                  })
                }}/> {getState().pageCopy.yes} <br/>
              </form>
            </> : null }
          {getState().newMovingGameObjectType !== "" ?
            <>
              <label className="tooltip" htmlFor="gameobject-patrol-speed">{getState().pageCopy.objectSpeed}
              <span className="tooltiptext" style={{paddingLeft: "45px"}}>{getState().pageCopy.objectSpeedTooltip}</span>
              </label>
              <input name="gameobject-patrol-speed" type="number" min="1" max="100" value={getState().newMovingGameObjectSpeed} onChange={(e) => {changeHandler({
                  newMovingGameObjectSpeed: e.target.value
                })
              }}/><br/>
            </> : null }
            <br/>
            <br/>
            <button style={{marginRight: "20px"}} className="main-nav-link" onClick={() => {
              let gameObjectParentElement = document.getElementById(getState().editedMovingGameObjectId).parentElement
              let itemIndex
              let itemInState
              gameValues.gameObjects.forEach((item, index) => {
                if (item.uniqueID === getState().editedMovingGameObjectId) {
                  itemInState = item
                  itemIndex = index
                }
              })
              gameValues.gameObjects[itemIndex] = {
                ...itemInState,
                isMoving: getState().newMovingGameObjectType ? true : false,
                movementType: getState().newMovingGameObjectType,
                patrolLeft: getState().newMovingGameObjectType === 'patrol' ? itemInState.x - 200 : null,
                patrolRight: getState().newMovingGameObjectType === 'patrol' ? itemInState.x + 400 : null,
                isMovingRight: getState().newMovingGameObjectType === 'patrol' ? getState().newMovingGameObjectIsMovingRight : getState().newMovingGameObjectType === 'chargeRight' ? true : null,
                speed: getState().newMovingGameObjectType !== '' ? parseInt(getState().newMovingGameObjectSpeed) : null,
              }
              if (getState().newMovingGameObjectType === "patrol") {
                let divLeft = document.createElement('div');
                divLeft.setAttribute('draggable', false);
                divLeft.setAttribute('id', `${gameObjectParentElement.firstElementChild.id}.left`);
                divLeft.setAttribute('style', `position: absolute; left: -200px; top: ${parseInt(gameObjectParentElement.style.height.split('px')[0])/2}px; z-index: 10000; width: 20px; height: 20px; cursor: pointer; background-color: #a976fc; border: 2px solid grey; border-radius: 12px`)
                gameObjectParentElement.appendChild(divLeft)
                let divRight = document.createElement('div');
                divRight.setAttribute('draggable', false);
                divRight.setAttribute('id', `${gameObjectParentElement.firstElementChild.id}.right`);
                divRight.setAttribute('style', `position: absolute; left: 400px; top: ${parseInt(gameObjectParentElement.style.height.split('px')[0])/2}px; z-index: 10000; width: 20px; height: 20px; cursor: pointer; background-color: #7b19c1; border: 2px solid grey; border-radius: 12px`)
                gameObjectParentElement.appendChild(divRight)
                let setListeners = (endpoint, typeOfEndpoint) => {
                  endpoint.addEventListener('mousedown', (event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    let _typeOfEndpoint = typeOfEndpoint
                    handleMouseDownEvent(event, (event) => {
                      let itemIndex
                      let parentId = endpoint.parentElement.firstElementChild.id
                      gameValues.gameObjects.forEach((item, index) => {
                        if (item.uniqueID === parentId) {
                          itemIndex = index
                        }
                      })
                      if (_typeOfEndpoint === "left") {
                        gameValues.gameObjects[itemIndex].patrolLeft = gameValues.gameObjects[itemIndex].x + parseInt(endpoint.style.left.split('px')[0])
                      }
                      if (_typeOfEndpoint === "right") {
                        gameValues.gameObjects[itemIndex].patrolRight = gameValues.gameObjects[itemIndex].x + parseInt(endpoint.style.left.split('px')[0])
                      }
                      localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects));
                      gameValues.tileSet ? localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet)) : null;
                    }, _typeOfEndpoint)
                  })
                  endpoint.addEventListener('dragstart', (event) => {
                    event.preventDefault()
                    event.stopPropagation()
                    return false;
                  })
                  endpoint.addEventListener('drop', (e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    return false;
                  })
                }
                setListeners(divLeft, "left")
                setListeners(divRight, "right")
              }
              gameValues.gameObjects ? localStorage.setItem('gameObjects', JSON.stringify(gameValues.gameObjects)) : null
              gameValues.tileSet ? localStorage.setItem('tileset', JSON.stringify(gameValues.tileSet)) : null
            }}>{getState().pageCopy.createMovingGameObject}</button>
        <br/>
        <br/>
    </div>
  );
};

export default LoadGameModal;
