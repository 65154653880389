import React from 'react';
import { navigate } from 'gatsby';
import ImgNextGen from './ImgNextGen';
import config from './config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGhost, faCheckCircle, faTimesCircle
} from '@fortawesome/free-solid-svg-icons';

const LoadPlayerModal = ({
  handleClose, show, modalCloseHandler, changeHandler, getState, checkDisabled, createPlayer
}) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';
  async function getGameObjectSpriteArray(target) {
    console.log(target.checked)
    target.checked = true;
    let resData = await fetch(`${config.backend}/admin/get-sprite-list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
        gameObjectType: 'players',
      }),
    })
    let resDataConverted = await resData.json()
    console.log(resDataConverted)
    changeHandler({
      gameObjectSpriteArray: resDataConverted.message
    })
  }

  const PlaceholderImageArray = () => {
    let placeholderSpriteArray = []
    for (let i = 0; i < getState().playerFrameCount; i++) {
      if (getState().newPlayerAnimationArray[i]) {
        placeholderSpriteArray.push(<img src={`${getState().newPlayerAnimationArray[i]}`} width="80" height="80" style={{margin: "10px", border: 'dashed 5px #c1c8d4'}} onClick={() => {
            getState().newPlayerAnimationArray.splice(i, 1)
            changeHandler({
              playerFrameCount: getState().playerFrameCount
            })
        }}/>)
      } else {
        placeholderSpriteArray.push(<FontAwesomeIcon icon={faGhost} style={{ color: '#c1c8d4', width: '60px', height: '60px', border: 'dashed 5px #c1c8d4', margin: '10px', padding: '10px' }} />)
      }
    }
    return placeholderSpriteArray
  }

  return (
    <div className={showHideClassName} id="modal-container" onClick={(e) => { modalCloseHandler(e, document.getElementById('modal-container')); }}>
      <section className="modal-main" id="email-popup" style={{ border: '4px solid #0a3f93' }}>
        <div style={{ minHeight: '500px', marginBottom: '0', position: 'relative' }}>
          <button onClick={handleClose} className="btn-optin-close">x</button>
          <div style={{
            display: 'flex', justifyContent: 'center', backgroundColor: '#00BCD4', padding: '20px 0',
          }}
          >
            <ImgNextGen width="60px" height="60px" fallback={`${config.img}pixopixa-logo-new.png`} srcWebp={`${config.img}pixopixa-logo-new.webp`} />
            <div style={{ width: '30px' }} />
            <ImgNextGen width="80px" height="60px" fallback={`${config.img}pixopixa-logo-1.png`} srcWebp={`${config.img}pixopixa-logo-1.webp`} />
          </div>
            <div>
              <div style={{width: '10%', textAlign: "center", float: 'left'}}>
                <p>{getState().pageCopy.walkLeft}</p>
                <FontAwesomeIcon icon={getState().newPlayerAnimationArrayWalkLeft.length > 0 ? faCheckCircle : faTimesCircle} style={{ color: `${getState().newPlayerAnimationArrayWalkLeft.length > 0 ? '#376e37' : '#c1c8d4'}`, fontSize: '3rem'}} />
              </div>
              <div style={{width: '10%', textAlign: "center", float: 'left'}}>
                <p>{getState().pageCopy.walkRight}</p>
                <FontAwesomeIcon icon={getState().newPlayerAnimationArrayWalkRight.length > 0 ? faCheckCircle : faTimesCircle} style={{ color: `${getState().newPlayerAnimationArrayWalkRight.length > 0 ? '#376e37' : '#c1c8d4'}`, fontSize: '3rem'}} />
              </div>
              <div style={{width: '10%', textAlign: "center", float: 'left'}}>
                <p>{getState().pageCopy.idleLeft}</p>
                <FontAwesomeIcon icon={getState().newPlayerAnimationArrayIdleLeft.length > 0 ? faCheckCircle : faTimesCircle} style={{ color: `${getState().newPlayerAnimationArrayIdleLeft.length > 0 ? '#376e37' : '#c1c8d4'}`, fontSize: '3rem'}} />
              </div>
              <div style={{width: '10%', textAlign: "center", float: 'left'}}>
                <p>{getState().pageCopy.idleRight}</p>
                <FontAwesomeIcon icon={getState().newPlayerAnimationArrayIdleRight.length > 0 ? faCheckCircle : faTimesCircle} style={{ color: `${getState().newPlayerAnimationArrayIdleRight.length > 0 ? '#376e37' : '#c1c8d4'}`, fontSize: '3rem'}} />
              </div>
              <div style={{width: '10%', textAlign: "center", float: 'left'}}>
                <p>{getState().pageCopy.jumpLeft}</p>
                <FontAwesomeIcon icon={getState().newPlayerAnimationArrayJumpLeft.length > 0 ? faCheckCircle : faTimesCircle} style={{ color: `${getState().newPlayerAnimationArrayJumpLeft.length > 0 ? '#376e37' : '#c1c8d4'}`, fontSize: '3rem'}} />
              </div>
              <div style={{width: '10%', textAlign: "center", float: 'left'}}>
                <p>{getState().pageCopy.jumpRight}</p>
                <FontAwesomeIcon icon={getState().newPlayerAnimationArrayJumpRight.length > 0 ? faCheckCircle : faTimesCircle} style={{ color: `${getState().newPlayerAnimationArrayJumpRight.length > 0 ? '#376e37' : '#c1c8d4'}`, fontSize: '3rem'}} />
              </div>
              <div style={{width: '10%', textAlign: "center", float: 'left'}}>
                <p>{getState().pageCopy.hurtLeft}</p>
                <FontAwesomeIcon icon={getState().newPlayerAnimationArrayHurtLeft.length > 0 ? faCheckCircle : faTimesCircle} style={{ color: `${getState().newPlayerAnimationArrayHurtLeft.length > 0 ? '#376e37' : '#c1c8d4'}`, fontSize: '3rem'}} />
              </div>
              <div style={{width: '10%', textAlign: "center", float: 'left'}}>
                <p>{getState().pageCopy.hurtRight}</p>
                <FontAwesomeIcon icon={getState().newPlayerAnimationArrayHurtRight.length > 0 ? faCheckCircle : faTimesCircle} style={{ color: `${getState().newPlayerAnimationArrayHurtRight.length > 0 ? '#376e37' : '#c1c8d4'}`, fontSize: '3rem'}} />
              </div>
              <div style={{width: '10%', textAlign: "center", float: 'left'}}>
                <p>{getState().pageCopy.deathLeft}</p>
                <FontAwesomeIcon icon={getState().newPlayerAnimationArrayDeathLeft.length > 0 ? faCheckCircle : faTimesCircle} style={{ color: `${getState().newPlayerAnimationArrayDeathLeft.length > 0 ? '#376e37' : '#c1c8d4'}`, fontSize: '3rem'}} />
              </div>
              <div style={{width: '10%', textAlign: "center", float: 'left'}}>
                <p>{getState().pageCopy.deathRight}</p>
                <FontAwesomeIcon icon={getState().newPlayerAnimationArrayDeathRight.length > 0 ? faCheckCircle : faTimesCircle} style={{ color: `${getState().newPlayerAnimationArrayDeathRight.length > 0 ? '#376e37' : '#c1c8d4'}`, fontSize: '3rem'}} />
              </div>
            </div>
            <br/>
            <div style={{width: "50%", textAlign: "center", float: "left", paddingTop: "15px"}}>
              <div>{getState().pageCopy.Animation}</div>
              <form>
                  <input type="radio" value="walkLeft" name="objectType" checked={getState().newPlayerActiveAnimationArray === "walkLeft"} onClick={(e) => {changeHandler({
                      newPlayerActiveAnimationArray: e.target.value,
                      newPlayerAnimationArray: getState().newPlayerAnimationArrayWalkLeft,
                      playerFrameCount: getState().newPlayerAnimationArrayWalkLeft.length > 0 ? getState().newPlayerAnimationArrayWalkLeft.length : 1,
                    })
                    getGameObjectSpriteArray(e.target)
                  }}/> {getState().pageCopy.walkLeft} <br/>
                  <input type="radio" value="walkRight" name="objectType" checked={getState().newPlayerActiveAnimationArray === "walkRight"} onClick={(e) => {changeHandler({
                      newPlayerActiveAnimationArray: e.target.value,
                      newPlayerAnimationArray: getState().newPlayerAnimationArrayWalkRight,
                      playerFrameCount: getState().newPlayerAnimationArrayWalkRight.length > 0 ? getState().newPlayerAnimationArrayWalkRight.length : 1,

                    })
                    getGameObjectSpriteArray(e.target)
                  }}/> {getState().pageCopy.walkRight} <br/>
                  <input type="radio" value="idleLeft" name="objectType" checked={getState().newPlayerActiveAnimationArray === "idleLeft"} onClick={(e) => {changeHandler({
                      newPlayerActiveAnimationArray: e.target.value,
                      newPlayerAnimationArray: getState().newPlayerAnimationArrayIdleLeft,
                      playerFrameCount: getState().newPlayerAnimationArrayIdleLeft.length > 0 ? getState().newPlayerAnimationArrayIdleLeft.length : 1,

                    })
                    getGameObjectSpriteArray(e.target)
                  }}/> {getState().pageCopy.idleLeft} <br/>
                  <input type="radio" value="idleRight" name="objectType" checked={getState().newPlayerActiveAnimationArray === "idleRight"} onClick={(e) => {changeHandler({
                      newPlayerActiveAnimationArray: e.target.value,
                      newPlayerAnimationArray: getState().newPlayerAnimationArrayIdleRight,
                      playerFrameCount: getState().newPlayerAnimationArrayIdleRight.length > 0 ? getState().newPlayerAnimationArrayIdleRight.length : 1,

                    })
                    getGameObjectSpriteArray(e.target)
                  }}/> {getState().pageCopy.idleRight} <br/>
                  <input type="radio" value="jumpLeft" name="objectType" checked={getState().newPlayerActiveAnimationArray === "jumpLeft"} onClick={(e) => {changeHandler({
                      newPlayerActiveAnimationArray: e.target.value,
                      newPlayerAnimationArray: getState().newPlayerAnimationArrayJumpLeft,
                      playerFrameCount: getState().newPlayerAnimationArrayJumpLeft.length > 0 ? getState().newPlayerAnimationArrayJumpLeft.length : 1,

                    })
                    getGameObjectSpriteArray(e.target)
                  }}/> {getState().pageCopy.jumpLeft} <br/>
                  <input type="radio" value="jumpRight" name="objectType" checked={getState().newPlayerActiveAnimationArray === "jumpRight"} onClick={(e) => {changeHandler({
                      newPlayerActiveAnimationArray: e.target.value,
                      newPlayerAnimationArray: getState().newPlayerAnimationArrayJumpRight,
                      playerFrameCount: getState().newPlayerAnimationArrayJumpRight.length > 0 ? getState().newPlayerAnimationArrayJumpRight.length : 1,

                    })
                    getGameObjectSpriteArray(e.target)
                  }}/> {getState().pageCopy.jumpRight} <br/>
                  <input type="radio" value="hurtLeft" name="objectType" checked={getState().newPlayerActiveAnimationArray === "hurtLeft"} onClick={(e) => {changeHandler({
                      newPlayerActiveAnimationArray: e.target.value,
                      newPlayerAnimationArray: getState().newPlayerAnimationArrayHurtLeft,
                      playerFrameCount: getState().newPlayerAnimationArrayHurtLeft.length > 0 ? getState().newPlayerAnimationArrayHurtLeft.length : 1,

                    })
                    getGameObjectSpriteArray(e.target)
                  }}/> {getState().pageCopy.hurtLeft} <br/>
                  <input type="radio" value="hurtRight" name="objectType" checked={getState().newPlayerActiveAnimationArray === "hurtRight"} onClick={(e) => {changeHandler({
                      newPlayerActiveAnimationArray: e.target.value,
                      newPlayerAnimationArray: getState().newPlayerAnimationArrayHurtRight,
                      playerFrameCount: getState().newPlayerAnimationArrayHurtRight.length > 0 ? getState().newPlayerAnimationArrayHurtRight.length : 1,

                    })
                    getGameObjectSpriteArray(e.target)
                  }}/> {getState().pageCopy.hurtRight} <br/>
                  <input type="radio" value="deathLeft" name="objectType" checked={getState().newPlayerActiveAnimationArray === "deathLeft"} onClick={(e) => {changeHandler({
                      newPlayerActiveAnimationArray: e.target.value,
                      newPlayerAnimationArray: getState().newPlayerAnimationArrayDeathLeft,
                      playerFrameCount: getState().newPlayerAnimationArrayDeathLeft.length > 0 ? getState().newPlayerAnimationArrayDeathLeft.length : 1,

                    })
                    getGameObjectSpriteArray(e.target)
                  }}/> {getState().pageCopy.deathLeft} <br/>
                  <input type="radio" value="deathRight" name="objectType" checked={getState().newPlayerActiveAnimationArray === "deathRight"} onClick={(e) => {changeHandler({
                      newPlayerActiveAnimationArray: e.target.value,
                      newPlayerAnimationArray: getState().newPlayerAnimationArrayDeathRight,
                      playerFrameCount: getState().newPlayerAnimationArrayDeathRight.length > 0 ? getState().newPlayerAnimationArrayDeathRight.length : 1,

                    })
                    getGameObjectSpriteArray(e.target)
                  }}/> {getState().pageCopy.deathRight} <br/>
                </form>
              </div>
              <div style={{width: "50%", textAlign: "center", float: "left", paddingTop: "15px"}}>
              <label htmlFor="player-name">{getState().pageCopy.characterName}
              </label><br/>
              <input name="player-name" type="text" value={getState().playerObjectName} onChange={(e) => {changeHandler({
                  playerObjectName: e.target.value
                })
              }}/><br/>
              <label htmlFor="animation-frame-count">{getState().pageCopy.framesInAnimation}</label><br/>
              <input name="animation-frame-count" type="number" min="1" max="11" value={getState().playerFrameCount} onChange={(e) => {changeHandler({
                  playerFrameCount: e.target.value
                })
                if (getState().newPlayerAnimationArray.length > e.target.value) {
                  getState().newPlayerAnimationArray.splice(e.target.value, 1)
                }
              }}/>
              <br/>
              <br/>
              <label htmlFor="player-running-speed">{getState().pageCopy.playerRunningSpeed}</label><br/>
              <input name="player-running-speed" type="number" min="1" max="40" value={getState().playerRunningSpeed} onChange={(e) => {changeHandler({
                  playerRunningSpeed: e.target.value
                })
              }}/>
              <br/>
              <br/>
              <label htmlFor="animation-frame-count">{getState().pageCopy.playerJumpSpeed}</label><br/>
              <input name="animation-frame-count" type="number" min="1" max="60" value={getState().playerJumpSpeed} onChange={(e) => {changeHandler({
                  playerJumpSpeed: e.target.value
                })
              }}/>
              <br/>
              <br/>
              <button style={{marginRight: "20px"}} className="main-nav-link" disabled={checkDisabled("player")} onClick={() => {createPlayer()}}>{getState().pageCopy.createCharacter}</button><br/><br/>
              {getState().characterCreationFailed ? <div style={{backgroundColor: "#FF4200", color: "#ffffff", padding: "0.5rem", fontSize: "1rem", border: "1px solid red", width: "100%", fontWeight: "600", marginBottom: "1rem" }}>Something went wrong, this character could not be created/updated</div> : null}
              {getState().characterCreationSucceeded ? <div className="success-div" style={{ margin: '0 1rem' }}>Game Object Created Successfully!</div> : null}
              </div>
              <div style={{clear: "both"}}/>
              <br/>
              <PlaceholderImageArray/>
              <br/>
              <button style={{marginRight: "20px"}} className="main-nav-link" disabled={getState().newPlayerAnimationArray.length !== parseInt(getState().playerFrameCount)} onClick={() => {
                switch (getState().newPlayerActiveAnimationArray) {
                  case 'walkLeft':
                    changeHandler({
                      newPlayerAnimationArrayWalkLeft: getState().newPlayerAnimationArray,
                      newPlayerAnimationArray: []
                    })
                    break;
                  case 'walkRight':
                    changeHandler({
                      newPlayerAnimationArrayWalkRight: getState().newPlayerAnimationArray,
                      newPlayerAnimationArray: []
                    })
                    break;
                  case 'idleLeft':
                    changeHandler({
                      newPlayerAnimationArrayIdleLeft: getState().newPlayerAnimationArray,
                      newPlayerAnimationArray: []
                    })
                    break;
                  case 'idleRight':
                    changeHandler({
                      newPlayerAnimationArrayIdleRight: getState().newPlayerAnimationArray,
                      newPlayerAnimationArray: []
                    })
                    break;
                  case 'jumpLeft':
                    changeHandler({
                      newPlayerAnimationArrayJumpLeft: getState().newPlayerAnimationArray,
                      newPlayerAnimationArray: []
                    })
                    break;
                  case 'jumpRight':
                    changeHandler({
                      newPlayerAnimationArrayJumpRight: getState().newPlayerAnimationArray,
                      newPlayerAnimationArray: []
                    })
                    break;
                  case 'hurtLeft':
                    changeHandler({
                      newPlayerAnimationArrayHurtLeft: getState().newPlayerAnimationArray,
                      newPlayerAnimationArray: []
                    })
                    break;
                  case 'hurtRight':
                    changeHandler({
                      newPlayerAnimationArrayHurtRight: getState().newPlayerAnimationArray,
                      newPlayerAnimationArray: []
                    })
                    break;
                  case 'deathLeft':
                    changeHandler({
                      newPlayerAnimationArrayDeathLeft: getState().newPlayerAnimationArray,
                      newPlayerAnimationArray: []
                    })
                    break;
                  case 'deathRight':
                    changeHandler({
                      newPlayerAnimationArrayDeathRight: getState().newPlayerAnimationArray,
                      newPlayerAnimationArray: []
                    })
                    break;
                  default:
                    return
                }
                // changeHandler({
                //   playerEditorStep: 2
                // })
              }}>{getState().pageCopy.createAnimation}</button>
              <button className="main-nav-link" onClick={() => {
                changeHandler({
                  newPlayerAnimationArray: []
                })
              }}>{getState().pageCopy.clear}</button>
            <ul>
            {
              getState().gameObjectSpriteArray.map((object) => {
                return (
                  <div className="tooltip">
                    <img src={`${object}`} width="80" height="80" onClick={(e) => {
                      if (getState().newPlayerAnimationArray.length < getState().playerFrameCount) {
                        getState().newPlayerAnimationArray.push(object)
                        changeHandler({
                          playerFrameCount: getState().playerFrameCount
                        })
                      } else if (getState().newPlayerAnimationArray.length >= getState().playerFrameCount) {
                        changeHandler({
                          playerFrameCount: parseInt(getState().playerFrameCount) + 1
                        }, () => {
                          getState().newPlayerAnimationArray.push(object)
                        })
                      }
                    }}/>
                    <span className="tooltiptext" >Image Name: {object.split('/')[5]}</span>
                  </div>
                )
              })
            }
            </ul>
        </div>
      </section>
    </div>
  );
};

export default LoadPlayerModal;
