import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Link from 'gatsby-link';
import ImgNextGen from './ImgNextGen';
import config from './config';
import Header from './header';
import Menu from './menu';
// import "./layout.css"

const Layout = ({ children }) => (
  <>
    <header
      className="top-navigation-2"
    >
      <div
        style={{
          margin: '0 auto',
          maxWidth: 1060,
          padding: '1.45rem 1.0875rem',
        }}
      >
        <div className="top-nav-bar-container-2" style={{ paddingBottom: '0.7rem' }}>
          <Link
            to="/"
            style={{
              textDecoration: 'none',
            }}
          >
            <ImgNextGen fallback={`${config.img}logo-transparent.png`} srcWebp={`${config.img}logo-transparent.webp`} className="top-nav-logo" />
            <ImgNextGen fallback={`${config.img}pixopixa-logo-1.png`} srcWebp={`${config.img}pixopixa-logo-1.webp`} className="top-nav-logo2" />
          </Link>
        </div>
      </div>
    </header>
    <div
      style={{
        margin: '0 auto',
        // maxWidth: 960,
        // padding: `0px 1.0875rem 1.45rem`,
        paddingTop: 0,
        marginBottom: 0,
      }}
    >
      <main>{children}</main>
    </div>
    <footer style={{ position: 'relative', bottom: '0', width: '100%' }}>
      <ul className="footer-container">
        <li className="footer-link" style={{ textDecoration: 'none' }}>
          {' '}
          ©
          {new Date().getFullYear()}
          , PixoPixa Inc.
        </li>
        <li><Link to="/about" className="footer-link">About Us</Link></li>
        <li><Link to="/blog" className="footer-link">Blog</Link></li>
        <li><Link to="/contact-us" className="footer-link">Contact Us</Link></li>
        <li><Link to="/terms" className="footer-link">Terms and Conditions</Link></li>
        <li><Link to="/faq" className="footer-link">FAQ</Link></li>
      </ul>
    </footer>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
