import { loadGame } from '../actions/game-actions';
import config from '../config';
import loadControls from './game-controls';
import { respawn, updateUI, groundCheck } from './game-events';
import {
  animateLevelExit, animateCheckpoint, animateCannon, populateObjects,
} from './animate-game-objects';
import { loadSprites, generateCustomImagesWithVillian } from './load-images';
import { tileset1, gameObjects1 } from '../tilesets';

export function getTileSet(arrayName) {
  if (typeof window !== 'undefined' && localStorage.getItem(arrayName)) {
    console.log(arrayName)
    let myTileset = JSON.parse(localStorage.getItem(arrayName))
    console.log(myTileset, "My Tileset")
    return myTileset
  } else {
    return []
  }
  // let ozge2 = JSON.parse(ozge)
  // console.log('WRITE ALL LOGIC TO GET TILESET HERE, SUB THIS IN FOR THE IMPORTED VALUE IN THE OBJECT BELOW')
}

export let gameValues = {
  x: 0,
  y: 0,
  cameraX: 0,
  cameraY: 0,
  cameraSpeed: 5,
  characterWidth: 110,
  characterHeight: 110,
  currentFrame: null,
  pineapple: null,
  bonusBox: null,
  currentCoin: null,
  currentHeart: null,
  currentLife: null,
  currentEnemy: null,
  currentFire: null,
  currentCannon: null,
  currentCheckpoint1: null,
  currentCheckpoint2: null,
  currentLevelExit: null,
  currentMovingPlatformLeft: null,
  currentMovingPlatformRight: null,
  currentMossyMovingPlatformLeft: null,
  currentMossyMovingPlatformRight: null,
  checkpointAnimationCount: 0,
  levelExitAnimationCount: 0,
  cannonAnimationCount: 0,
  stepCount: 0,
  coinCount: 0,
  jumpSpeed: 10,
  boxWidth: 80,
  boxHeight: 80,
  boxBounce: 0,
  groundLikeObjectsInFrame: [],
  coinSound: null,
  jumpSound: null,
  levelMusic: null,
  canvas: null,
  ctx: null,
  uICanvas: null,
  uICtx: null,
  pauseCanvas: null,
  pauseCtx: null,
  menuCanvas: null,
  menuCtx: null,
  tilesetCanvas: null,
  tilesetCtx: null,
  // PREVIOUSLY IN STATE
  isWalking: false,
  walkingRight: true,
  isJumping: false,
  canJump: true,
  canWalk: true,
  lives: 5,
  coins: 0,
  health: 6,
  respawnX: 17650,
  respawnY: 0,
  invincibleOnKnockback: false,
  invincible: false,
  checkPointIsAnimating: null,
  levelExitIsAnimating: false,
  respawning: false,
  tileSet: [...getTileSet("tileset")],
  gameObjects: [...getTileSet("gameObjects")],
  gameSprites: {}, // {...(getSpriteArray(this.props.gameVersionId))},
  dying: false,
  stuckToPlatform: false,
  cannonFiring: false,
  cannonXVelocity: 0,
  cannonYVelocity: 0,
  //ALL VALUES BELOW HERE WILL BE GENERATED FROM THE DATABASE
  heroIdleLeftValues: [],//'hero-idle-left-1', 'hero-idle-left-2', 'hero-idle-left-3', 'hero-idle-left-4', 'hero-idle-left-5'],
  heroIdleRightValues: [],//'hero-idle-right-1', 'hero-idle-right-2', 'hero-idle-right-3', 'hero-idle-right-4', 'hero-idle-right-5'],
  heroWalkLeftValues: [],//'hero-walk-left-1', 'hero-walk-left-2', 'hero-walk-left-3', 'hero-walk-left-4', 'hero-walk-left-5', 'hero-walk-left-6', 'hero-walk-left-7'],
  heroWalkRightValues: [],//'hero-walk-right-1', 'hero-walk-right-2', 'hero-walk-right-3', 'hero-walk-right-4', 'hero-walk-right-5', 'hero-walk-right-6', 'hero-walk-right-7'],
  heroJumpLeftValues: [],//'hero-jump-left-1', 'hero-jump-left-2', 'hero-jump-left-3', 'hero-jump-left-4', 'hero-jump-left-5', 'hero-jump-left-6', 'hero-jump-left-7'],
  heroJumpRightValues: [],//'hero-jump-right-1', 'hero-jump-right-2', 'hero-jump-right-3', 'hero-jump-right-4', 'hero-jump-right-5', 'hero-jump-right-6', 'hero-jump-right-7'],
  heroHurtLeftValues: [],//'hero-hurt-left-1', 'hero-hurt-left-2', 'hero-hurt-left-3', 'hero-hurt-left-4', 'hero-hurt-left-5', 'hero-hurt-left-6'],
  heroHurtRightValues: [],//'hero-hurt-right-1', 'hero-hurt-right-2', 'hero-hurt-right-3', 'hero-hurt-right-4', 'hero-hurt-right-5', 'hero-hurt-right-6'],
  heroDeathLeftValues: [],//'hero-death-left-1', 'hero-death-left-2', 'hero-death-left-3', 'hero-death-left-4'],
  heroDeathRightValues: [],//'hero-death-right-1', 'hero-death-right-2', 'hero-death-right-3', 'hero-death-right-4'],
  currentCoinValues: ['coin-full', 'coin-2', 'coin-3'],
  currentEnemy1Vales: ['bear-walk-left-1', 'bear-walk-left-2', 'bear-walk-left-3', 'bear-walk-left-4', 'bear-walk-left-5', 'bear-walk-right-1', 'bear-walk-right-2', 'bear-walk-right-3', 'bear-walk-right-4', 'bear-walk-right-5'],
  currentHeartValues: ['heart-half', 'heart-2', 'heart-3'],
  currentFireValues: ['fire-1', 'fire-2', 'fire-3'],
  currentLifeValues: ['life-1', 'life-2', 'life-3'],
  currentMovingPlatformLeftValues: ['moving-platform-left-1', 'moving-platform-left-2', 'moving-platform-left-3'],
  currentMovingPlatformRightValues: ['moving-platform-right-1', 'moving-platform-right-2', 'moving-platform-right-3'],
  currentMossyMovingPlatformLeftValues: ['mossy-moving-platform-left-1', 'mossy-moving-platform-left-2', 'mossy-moving-platform-left-3'],
  currentMossyMovingPlatformRightValues: ['mossy-moving-platform-right-1', 'mossy-moving-platform-right-2', 'mossy-moving-platform-right-3'],
  currentCannonValues: ['cannon', 'cannon-2', 'cannon-3', 'cannon-4'],
  currentCheckPointValues: ['checkpoint-0', 'checkpoint-1', 'checkpoint-2', 'checkpoint-3', 'checkpoint-4', 'checkpoint-5', 'checkpoint-6', 'checkpoint-7', 'checkpoint-8', 'checkpoint-9'],
  frameCount: 0,
  heroStartX: 300,
  heroStartY: 300,
  cameraStartX: 0,
  cameraStartY: 0,
  previewGameObjects: [],
  playerRunningSpeed: 5,
  playerJumpSpeed: 20,
};

export async function initializeMenu(setGameValues, getGameValues, gameId, gameVersionId) {
  localStorage.removeItem('unfinishedGameId');
  gameValues.menuCanvas = document.getElementById('pixopixa-custom-menu-screen');
  gameValues.menuCtx = gameValues.menuCanvas.getContext('2d');
  gameValues.menuCanvas.width = (window.innerWidth * 0.95) - 29;
  gameValues.menuCanvas.height = (window.innerHeight * 0.95) - 30;
  gameValues.menuCtx.fillStyle = '#ffff7f';
  gameValues.menuCtx.fillRect(0, 0, gameValues.menuCanvas.width, gameValues.menuCanvas.height);
  gameValues.menuCtx.fillStyle = '#00BCD4';
  gameValues.menuCtx.fillRect(0, 0, gameValues.menuCanvas.width, 220);
  // const resData = await loadGame('db3f2010-5b3e-11ec-9e1c-f7d3a26cb877', '7');
  // console.log(resData, 'Res Data')
  setGameValues({
    isloading: false,
    level1Text: "TEST",//resData.game.level1text,
    level2Text: "TEST2",//resData.game.level2text,
    level3Text: "TEST3",//resData.game.finaltext,
    currentLevelText: "TEST4",//resData.game.levelonetext,
    enemy: "TEST5",//resData.game.enemy,
  });
  const heroSrc = `${config.backend}/images/acbdc5f0-03ee-11eb-b3ef-6f58b9601130-heroStandSprite.png`;//`${config.backend}/${resData.game.heroImage}`;
  const villianSrc = `${config.backend}/images/acbdc5f0-03ee-11eb-b3ef-6f58b9601130-heroStandSprite.png`;//`${config.backend}/${resData.game.villianImage}`;
  const faceImages = loadSprites({
    heroImage: heroSrc,
    villianImage: villianSrc,
  });
  const [heroImage, villianImage] = [faceImages.heroImage, faceImages.villianImage];
  gameValues.gameSprites = { ...gameValues.gameSprites, heroImage };
  let bothLoaded = false;
  console.log(gameValues.gameSprites, "Game Sprites")
  const loadPlayer = () => {
    if (bothLoaded) {
      gameValues.gameSprites = { ...gameValues.gameSprites, ...generateCustomImagesWithVillian(document.getElementById('hidden-canvas'), heroImage, villianImage, gameValues.gameSprites) };
      // gameValues.gameSprites['hero-idle-left-1'].onload = () => {
      //   gameValues.menuCtx.drawImage(
      //     gameValues.gameSprites['hero-idle-left-1'],
      //     20,
      //     gameValues.menuCanvas.height / 6,
      //     300,
      //     400,
      //   );
      //   setGameValues({
      //     isloading: false,
      //   });
      // };
      // gameValues.gameSprites['villian-stand'].onload = () => {
      //   gameValues.menuCtx.drawImage(
      //     gameValues.gameSprites.villianStandSprite,
      //     gameValues.menuCanvas.width - 220,
      //     gameValues.menuCanvas.height / 6,
      //     300,
      //     400,
      //   );
      // };
    } else {
      bothLoaded = true;
    }
  };
  heroImage.onload = loadPlayer;
  villianImage.onload = loadPlayer;
  /// CAN COME BACK TO THIS, NOT IMPORTANT FOR EDITOR THOUGH
  // gameValues.menuCtx.drawImage(
  //   gameValues.gameSprites.pineapple,
  //   gameValues.menuCanvas.width / 4,
  //   gameValues.menuCanvas.height - 200,
  //   180,
  //   200,
  // );
  // gameValues.menuCtx.drawImage(gameValues.gameSprites['level-exit'], 3.7 * (gameValues.menuCanvas.width / 5), 60, 160, 160);
  // gameValues.menuCtx.drawImage(gameValues.gameSprites['pixopixa-logo-new'], 30, 30, 100, 100);
  // gameValues.menuCtx.drawImage(gameValues.gameSprites['pixopixa-logo-1'], 150, 32, 120, 75);
  heroImage.src = heroSrc;
  villianImage.src = villianSrc;
  loadControls(setGameValues, getGameValues);
}

export function initializeGame(getGameValues, setGameValues) {
  console.log(gameValues)
  gameValues.canvas = document.getElementById('pixopixa-custom-player');
  gameValues.ctx = gameValues.canvas.getContext('2d');
  gameValues.uICanvas = document.getElementById('pixopixa-custom-ui');
  gameValues.uICtx = gameValues.uICanvas.getContext('2d');
  gameValues.pauseCanvas = document.getElementById('pixopixa-custom-pause-screen');
  gameValues.pauseCtx = gameValues.pauseCanvas.getContext('2d');
  // gameValues.menuCanvas = document.getElementById("pixopixa-custom-menu-screen")
  // gameValues.menuCtx = gameValues.menuCanvas.getContext("2d")
  gameValues.tilesetCanvas = document.getElementById('pixopixa-custom-tileset');
  gameValues.tilesetCtx = gameValues.tilesetCanvas.getContext('2d');
  gameValues.tilesetCanvas.width = 30000;
  gameValues.tilesetCanvas.height = 1500;
  gameValues.canvas.width = (window.innerWidth * 0.95) - 29;
  gameValues.canvas.height = (window.innerHeight * 0.95) - 30;
  gameValues.uICanvas.width = (window.innerWidth * 0.95) - 29;
  gameValues.uICanvas.height = (window.innerHeight * 0.95) - 30;
  gameValues.pauseCanvas.width = (window.innerWidth * 0.95) - 29;
  gameValues.pauseCanvas.height = (window.innerHeight * 0.95) - 30;
  window.addEventListener('resize', () => {
    gameValues.canvas.width = (window.innerWidth * 0.95) - 29;
    gameValues.canvas.height = (window.innerHeight * 0.95) - 30;
    gameValues.uICanvas.width = (window.innerWidth * 0.95) - 29;
    gameValues.uICanvas.height = (window.innerHeight * 0.95) - 30;
    gameValues.pauseCanvas.width = (window.innerWidth * 0.95) - 29;
    gameValues.pauseCanvas.height = (window.innerHeight * 0.95) - 30;
    gameValues.menuCanvas.width = (window.innerWidth * 0.95) - 29;
    gameValues.menuCanvas.height = (window.innerHeight * 0.95) - 30;
    setGameValues({
      canvasWidth: (window.innerWidth * 0.95) - 29,
      canvasHeight: (window.innerHeight * 0.95) - 30,
    }, () => {
      gameValues.uICtx.drawImage(
        gameValues.gameSprites.heroImage,
        gameValues.canvas.width - 180,
        7,
        100,
        100,
      );
      gameValues.uICtx.font = '30px Arial';
      gameValues.uICtx.fillText(`x ${gameValues.lives}`, gameValues.canvas.width - 115, 45);
      updateUI(setGameValues);
    });
  });
  gameValues.tileSet.forEach((platform, index) => {
    for (let i = 0; i < platform.count; i += 1) {
      gameValues.tilesetCtx.drawImage(
        gameValues.gameSprites[gameValues.tileSet[index].imageArray[i].split("/tileSets/")[1]],
        platform.left + (i * 80),
        platform.top,
        80,
        80,
      );
    }
  });
  // UI BELOW, CLEAN UP LATER
  // gameValues.uICtx.drawImage(gameValues.gameSprites['heart-full'], 20, 20, 35, 35);
  // gameValues.uICtx.drawImage(gameValues.gameSprites['heart-full'], 60, 20, 35, 35);
  // gameValues.uICtx.drawImage(gameValues.gameSprites['heart-full'], 100, 20, 35, 35);
  // gameValues.uICtx.drawImage(gameValues.gameSprites['coin-full'], 160, 17, 37, 37);
  // gameValues.uICtx.font = '30px Arial';
  // gameValues.uICtx.fillText(`x ${gameValues.coins}`, 204, 45);
  gameValues.cameraX = gameValues.cameraStartX,
  gameValues.cameraY = gameValues.cameraStartY,
  gameValues.x = gameValues.heroStartX//canvas.width / 2;
  gameValues.y = gameValues.heroStartY//canvas.height / 5;
  // gameValues.currentFrame = gameValues.gameSprites.heroStandSprite;
  // gameValues.pineapple = gameValues.gameSprites.pineapple;
  // gameValues.bonusBox = gameValues.gameSprites['bonus-box'];
  // gameValues.currentCoin = gameValues.gameSprites['coin-full'];
  // gameValues.currentHeart = gameValues.gameSprites['heart-full'];
  // gameValues.currentLife = gameValues.gameSprites['life-1'];
  // gameValues.currentEnemy = gameValues.gameSprites['enemy1-1'];
  // gameValues.currentFire = gameValues.gameSprites['fire-1'];
  // gameValues.currentCannon = gameValues.gameSprites['cannon'];
  // gameValues.currentCheckpoint1 = gameValues.gameSprites['checkpoint-0'];
  // gameValues.currentCheckpoint2 = gameValues.gameSprites['checkpoint-0'];
  // gameValues.currentLevelExit = gameValues.gameSprites['level-exit'];
  // gameValues.currentMovingPlatformLeft = gameValues.gameSprites['moving-platform-left-1'];
  // gameValues.currentMovingPlatformRight = gameValues.gameSprites['moving-platform-right-1'];
  // gameValues.currentMossyMovingPlatformLeft = gameValues.gameSprites['mossy-moving-platform-left-3'];
  // gameValues.currentMossyMovingPlatformRight = gameValues.gameSprites['mossy-moving-platform-right-3'];
  gameValues.levelMusic.current.volume = getGameValues().gameVolume;
  gameValues.coinSound.current.volume = getGameValues().gameVolume;
  gameValues.jumpSound.current.volume = getGameValues().gameVolume;
  gameValues.levelMusic.current.play();
  gameValues.respawnX = gameValues.heroStartX;
  gameValues.respawnY = gameValues.heroStartY;
  setGameValues({
    isloading: false,
    canvasWidth: (window.innerWidth * 0.95) - 29,
    canvasHeight: (window.innerHeight * 0.95) - 30,
  });
}

export function animateSprite(spriteCount, animationSpeed, spriteArray) {
  let currentSprite = (Math.floor(gameValues.frameCount/animationSpeed)) % spriteCount;
  return spriteArray[currentSprite];
}

export function animateGame(setGameValues, getGameValues) {
  gameValues.ctx.clearRect(0, 0, gameValues.canvas.width, gameValues.canvas.height);
  // Ground Check
  if (!groundCheck(gameValues.x, gameValues.y, gameValues.jumpSpeed, 110, (top) => {
    gameValues.y = (top - 110);
  })) {
    gameValues.y += gameValues.jumpSpeed;
    gameValues.jumpSpeed += 0.85;
    if (gameValues.y > 1300) {
      gameValues.dying = true;
      respawn(setGameValues);
    }
    if (gameValues.stuckToPlatform) {
      gameValues.cameraSpeed = 5;
      gameValues.stuckToPlatform = false;
    }
  } else {
    gameValues.canJump = true;
  }

  // Handle Walking
  if (gameValues.isWalking && !gameValues.knockback && !gameValues.dying && gameValues.canWalk) {
    if (gameValues.walkingRight) {
      gameValues.x += gameValues.playerRunningSpeed;
      // if (gameValues.stepCount === 10) {
        gameValues.currentFrame = gameValues.gameSprites[animateSprite(gameValues.heroWalkRightValues.length, 4, gameValues.heroWalkRightValues)]
        // gameValues.stepCount = 0;
      // }
    } else {
      gameValues.x -= gameValues.playerRunningSpeed;
      // if (gameValues.stepCount === 10) {
        gameValues.currentFrame = gameValues.gameSprites[animateSprite(gameValues.heroWalkLeftValues.length, 4, gameValues.heroWalkLeftValues)]
        // gameValues.stepCount = 0;
      // }
    }
    gameValues.stepCount += 1;
  }
  if (gameValues.knockback) {
    gameValues.x -= 4;
    gameValues.y -= 3;
  }

  // Handle Jumping
  if (gameValues.isJumping) {
    gameValues.jumpSpeed = -gameValues.playerJumpSpeed;
    gameValues.currentFrame = gameValues.walkingRight ? gameValues.gameSprites[animateSprite(gameValues.heroJumpRightValues.length, 8, gameValues.heroJumpRightValues)] : gameValues.gameSprites[animateSprite(gameValues.heroJumpLeftValues.length, 8, gameValues.heroJumpLeftValues)];
    // console.log(gameValues.jumpSound)
    gameValues.jumpSound.current ? gameValues.jumpSound.current.currentTime = 0 : null;
    gameValues.jumpSound.current ? gameValues.jumpSound.current.play(): null;
    gameValues.isJumping = false;
    gameValues.canJump = false;
  }
  // Handle Standing
  if (!gameValues.isWalking && !gameValues.isJumping) {
    gameValues.currentFrame = gameValues.walkingRight ? gameValues.gameSprites[animateSprite(gameValues.heroIdleRightValues.length, 16, gameValues.heroIdleRightValues)] : gameValues.gameSprites[animateSprite(gameValues.heroIdleLeftValues.length, 16, gameValues.heroIdleLeftValues)];
  }
  if (!gameValues.canJump) {
    gameValues.currentFrame = gameValues.walkingRight ? gameValues.gameSprites[animateSprite(gameValues.heroJumpRightValues.length, 8, gameValues.heroJumpRightValues)] : gameValues.gameSprites[animateSprite(gameValues.heroJumpLeftValues.length, 8, gameValues.heroJumpLeftValues)];
  }
  // Move Camera => This needs some thought.
  if ((gameValues.x - gameValues.cameraX) > 2 * (gameValues.canvas.width / 3)) {
    gameValues.cameraX += gameValues.cameraSpeed;
  }
  if ((gameValues.x - gameValues.cameraX) < gameValues.canvas.width / 3) {
    gameValues.cameraX -= gameValues.cameraSpeed;
  }
  // console.log(y-cameraY, 2*canvas.height/3)
  if ((gameValues.y - gameValues.cameraY) > 3 * (gameValues.canvas.height / 5)
  && gameValues.y < 550) {
    gameValues.cameraY += 12;
  }
  if ((gameValues.y - gameValues.cameraY) < gameValues.canvas.height / 8) {
    gameValues.cameraY -= 10;
  }

  // populateObjects(gameObjects)

  // handle checkpoint animation
  if (gameValues.checkPointIsAnimating) {
    animateCheckpoint();
  }

  if (gameValues.levelExitIsAnimating && gameValues.levelExitAnimationCount < 41) {
    animateLevelExit();
  }

  if (gameValues.cannonFiring === 1) {
    animateCannon();
  }

  if (gameValues.cannonFiring === 2) {
    if (gameValues.cannonAnimationCount > 0) {
      gameValues.cannonAnimationCount -= 1;
      gameValues.cameraSpeed = gameValues.cannonXVelocity;
      gameValues.x += gameValues.cannonXVelocity;
      gameValues.y -= gameValues.cannonYVelocity;
    } else {
      gameValues.canWalk = true;
      gameValues.canJump = true;
      gameValues.cannonFiring = false;
      gameValues.cannonXVelocity = 0;
      gameValues.cannonYVelocity = 0;
      gameValues.cameraSpeed = 5;
      gameValues.cannonAnimationCount = 0;
    }
  }

  // handle coin animation
  gameValues.coinCount += 1;
  gameValues.currentCoin = gameValues.gameSprites[animateSprite(gameValues.currentCoinValues.length, 10, gameValues.currentCoinValues)];
  gameValues.currentEnemy = gameValues.gameSprites[animateSprite(gameValues.currentEnemy1Vales.length, 10, gameValues.currentEnemy1Vales)];
  gameValues.currentHeart = gameValues.gameSprites[animateSprite(gameValues.currentHeartValues.length, 10, gameValues.currentHeartValues)];
  gameValues.currentFire = gameValues.gameSprites[animateSprite(gameValues.currentFireValues.length, 10, gameValues.currentFireValues)];
  gameValues.currentLife = gameValues.gameSprites[animateSprite(gameValues.currentLifeValues.length, 10, gameValues.currentLifeValues)];
  gameValues.currentMovingPlatformLeft = gameValues.gameSprites[animateSprite(gameValues.currentMovingPlatformLeftValues.length, 10, gameValues.currentMovingPlatformLeftValues)];
  gameValues.currentMovingPlatformRight = gameValues.gameSprites[animateSprite(gameValues.currentMovingPlatformRightValues.length, 10, gameValues.currentMovingPlatformRightValues)];
  gameValues.currentMossyMovingPlatformLeft = gameValues.gameSprites[animateSprite(gameValues.currentMossyMovingPlatformLeftValues.length, 10, gameValues.currentMossyMovingPlatformLeftValues)];
  gameValues.currentMossyMovingPlatformRight = gameValues.gameSprites[animateSprite(gameValues.currentMossyMovingPlatformRightValues.length, 10, gameValues.currentMossyMovingPlatformRightValues)];

  // if (gameValues.coinCount === 10) {
  //   gameValues.currentCoin = gameValues.gameSprites['coin-full'];
  //   gameValues.currentEnemy = gameValues.gameSprites['enemy1-1'];
  //   gameValues.currentHeart = gameValues.gameSprites['heart-full'];
  //   gameValues.currentFire = gameValues.gameSprites.fire1;
  //   gameValues.currentLife = gameValues.gameSprites.life1;
  //   gameValues.currentMovingPlatformLeft = gameValues.gameSprites['moving-platform-left-1'];
  //   gameValues.currentMovingPlatformRight = gameValues.gameSprites['moving-platform-right-1'];
  //   gameValues.currentMossyMovingPlatformLeft = gameValues.gameSprites['mossy-moving-platform-left-3'];
  //   gameValues.currentMossyMovingPlatformRight = gameValues.gameSprites['mossy-moving-platform-right-3'];
  if (gameValues.frameCount % 40 === 20) {
    // gameValues.currentCoin = gameValues.gameSprites['coin-2'];
    // gameValues.currentHeart = gameValues.gameSprites.heart2;
    // gameValues.currentFire = gameValues.gameSprites.fire2;
    // gameValues.currentLife = gameValues.gameSprites.life2;
    // gameValues.currentMovingPlatformLeft = gameValues.gameSprites['moving-platform-left-2'];
    // gameValues.currentMovingPlatformRight = gameValues.gameSprites['moving-platform-right-2'];
    // gameValues.currentMossyMovingPlatformLeft = gameValues.gameSprites['mossy-moving-platform-left-1'];
    // gameValues.currentMossyMovingPlatformRight = gameValues.gameSprites['mossy-moving-platform-right-1'];
    gameValues.boxWidth = 82;
    gameValues.boxHeight = 82;
  // } else if (gameValues.coinCount === 30) {
  //   gameValues.currentCoin = gameValues.gameSprites['coin-3'];
  //   gameValues.currentEnemy = gameValues.gameSprites['enemy1-2'];
  //   gameValues.currentHeart = gameValues.gameSprites.heart3;
  //   gameValues.currentFire = gameValues.gameSprites.fire3;
  //   gameValues.currentLife = gameValues.gameSprites.life3;
  //   gameValues.currentMovingPlatformLeft = gameValues.gameSprites['moving-platform-left-3'];
  //   gameValues.currentMovingPlatformRight = gameValues.gameSprites['moving-platform-right-3'];
  //   gameValues.currentMossyMovingPlatformLeft = gameValues.gameSprites['mossy-moving-platform-left-2'];
  //   gameValues.currentMossyMovingPlatformRight = gameValues.gameSprites['mossy-moving-platform-right-2'];
  } else if (gameValues.frameCount % 40 === 0) {
    gameValues.boxWidth = 80;
    gameValues.boxHeight = 80;
    // gameValues.currentCoin = gameValues.gameSprites['coin-2'];
    // gameValues.currentHeart = gameValues.gameSprites.heart2;
    // gameValues.currentFire = gameValues.gameSprites.fire2;
    // gameValues.currentLife = gameValues.gameSprites.life2;
    // gameValues.coinCount = 0;
  }
  gameValues.frameCount++;

  gameValues.ctx.drawImage(
    gameValues.tilesetCanvas,
    gameValues.cameraX,
    gameValues.cameraY,
    getGameValues().canvasWidth,
    getGameValues().canvasHeight,
    0,
    0,
    getGameValues().canvasWidth,
    getGameValues().canvasHeight,
  );
  // populateObjects(setGameValues);
  gameValues.ctx.drawImage(
    gameValues.currentFrame,
    (gameValues.x - gameValues.cameraX),
    (gameValues.y - gameValues.cameraY),
    gameValues.characterWidth,
    gameValues.characterHeight,
  );
  populateObjects(setGameValues);
}
