import React from 'react';
import { navigate } from 'gatsby';
import ImgNextGen from './ImgNextGen';
import config from './config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGhost, faCheckCircle, faTimesCircle
} from '@fortawesome/free-solid-svg-icons';

const LoadGameModal = ({
  handleClose, show, modalCloseHandler, changeHandler, getState, checkDisabled, createGameObject
}) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';
  async function getGameObjectSpriteArray(target) {
    target.checked = true;
    let resData = await fetch(`${config.backend}/admin/get-sprite-list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
        gameObjectType: `${target.value}`,
      }),
    })
    let resDataConverted = await resData.json()
    console.log(resDataConverted)
    changeHandler({
      gameObjectSpriteArray: resDataConverted.message
    })
  }

  const PlaceholderImageArray = () => {
    let placeholderSpriteArray = []
    for (let i = 0; i < getState().gameObjectFrameCount; i++) {
      if (getState().newgameObjectAnimationArray[i]) {
        placeholderSpriteArray.push(<img src={`${getState().newgameObjectAnimationArray[i]}`} width="80" height="80" style={{margin: "10px", border: 'dashed 5px #c1c8d4'}} onClick={() => {
            getState().newgameObjectAnimationArray.splice(i, 1)
            changeHandler({
              gameObjectFrameCount: getState().gameObjectFrameCount
            })
        }}/>)
      } else {
        placeholderSpriteArray.push(<FontAwesomeIcon icon={faGhost} style={{ color: '#c1c8d4', width: '60px', height: '60px', border: 'dashed 5px #c1c8d4', margin: '10px', padding: '10px' }} />)
      }
    }
    return placeholderSpriteArray
  }

  return (
    <div className={showHideClassName} id="modal-container" onClick={(e) => { modalCloseHandler(e, document.getElementById('modal-container')); }}>
      <section className="modal-main" id="email-popup" style={{ border: '4px solid #0a3f93' }}>
        <div style={{ minHeight: '500px', marginBottom: '0', position: 'relative' }}>
          <button onClick={handleClose} className="btn-optin-close">x</button>
          <div style={{
            display: 'flex', justifyContent: 'center', backgroundColor: '#00BCD4', padding: '20px 0',
          }}
          >
            <ImgNextGen width="60px" height="60px" fallback={`${config.img}pixopixa-logo-new.png`} srcWebp={`${config.img}pixopixa-logo-new.webp`} />
            <div style={{ width: '30px' }} />
            <ImgNextGen width="80px" height="60px" fallback={`${config.img}pixopixa-logo-1.png`} srcWebp={`${config.img}pixopixa-logo-1.webp`} />
          </div>
            {getState().gameObjectLeftRightAnimation ? (
              <>
                <div>
                  <div style={{width: '50%', textAlign: "center", float: "left"}}>
                    <p>{getState().pageCopy.animateLeft}</p>
                    <FontAwesomeIcon icon={getState().newgameObjectAnimationArrayLeft.length > 0 ? faCheckCircle : faTimesCircle} style={{ color: `${getState().newgameObjectAnimationArrayLeft.length > 0 ? '#376e37' : '#c1c8d4'}`, fontSize: '3rem'}} />
                  </div>
                  <div style={{width: '50%', textAlign: "center", float: "left"}}>
                    <p>{getState().pageCopy.animateRight}</p>
                    <FontAwesomeIcon icon={getState().newgameObjectAnimationArrayRight.length > 0 ? faCheckCircle : faTimesCircle} style={{ color: `${getState().newgameObjectAnimationArrayRight.length > 0 ? '#376e37' : '#c1c8d4'}`, fontSize: '3rem'}} />
                  </div>
                </div>
                <div style={{clear: "both"}}></div>
              </>
            ) : null}
            <div style={{width: "calc(50% - 35px)", float: "left", paddingTop: "15px", paddingLeft: "35px"}}>
              <label htmlFor="object-name">{getState().pageCopy.objectName}
              </label>
              <input name="object-name" type="text" value={getState().gameObjectObjectName} onChange={(e) => {changeHandler({
                  gameObjectObjectName: e.target.value
                })
              }}/><br/>
              <div>{getState().pageCopy.objectType}</div>
              <form onClick={(e) => {changeHandler({
                newgameObjectType: e.target.value
              })
              getGameObjectSpriteArray(e.target)
            }}>
                <input type="radio" value="enemies" name="objectType" checked={getState().newgameObjectType === "enemies"} onClick={(e) => {changeHandler({
                    newgameObjectType: e.target.value
                  })
                  getGameObjectSpriteArray(e.target)
                }}/> {getState().pageCopy.optionEnemy} <br/>
                <input type="radio" value="hazards" name="objectType" checked={getState().newgameObjectType === "hazards"} onClick={(e) => {changeHandler({
                    newgameObjectType: e.target.value
                  })
                  getGameObjectSpriteArray(e.target)
                }}/> {getState().pageCopy.optionHazard} <br/>
                <input type="radio" value="gameObjects" name="objectType" checked={getState().newgameObjectType === "gameObjects"} onClick={(e) => {changeHandler({
                    newgameObjectType: e.target.value
                  })
                  getGameObjectSpriteArray(e.target)
                }}/> {getState().pageCopy.optionGameObject} <br/>
                <input type="radio" value="tileSets" name="objectType" checked={getState().newgameObjectType === "tileSets"} onClick={(e) => {changeHandler({
                    newgameObjectType: e.target.value
                  })
                  getGameObjectSpriteArray(e.target)
                }}/> {getState().pageCopy.optionTiles} <br/>
                <input type="radio" value="movingPlatforms" name="objectType" checked={getState().newgameObjectType === "movingPlatforms"} onClick={(e) => {changeHandler({
                    newgameObjectType: e.target.value
                  })
                  getGameObjectSpriteArray(e.target)
                }}/> {getState().pageCopy.optionMovingPlatform} <br/>
              </form>
              <div>
              <label className="tooltip" htmlFor="animation-frame-count">{getState().pageCopy.framesInAnimation}
              <span className="tooltiptext" style={{paddingLeft: "45px"}}>{getState().pageCopy.framesInAnimationTooltip}</span>
              </label>
              <input name="animation-frame-count" type="number" min="1" max="11" value={getState().gameObjectFrameCount} onChange={(e) => {changeHandler({
                  gameObjectFrameCount: e.target.value
                })
                if (getState().newgameObjectAnimationArray.length > e.target.value) {
                  getState().newgameObjectAnimationArray.splice(e.target.value, 1)
                }
              }}/><br/>
              <div className="tooltip">{getState().pageCopy.leftRightAnimation}
                <span className="tooltiptext" style={{paddingLeft: "45px"}}>{getState().pageCopy.leftRightAnimationTooltip}</span>
              </div>
              <form>
                <input type="radio" value="false" name="left-right" checked={getState().gameObjectLeftRightAnimation === false} onClick={(e) => {changeHandler({
                    gameObjectLeftRightAnimation: false
                  })
                }}/> {getState().pageCopy.no} <br/>
                <input type="radio" value="true" name="left-right" checked={getState().gameObjectLeftRightAnimation === true} onClick={(e) => {changeHandler({
                    gameObjectLeftRightAnimation: true
                  })
                }}/> {getState().pageCopy.yes} <br/>
              </form>
              {getState().gameObjectLeftRightAnimation ?
                (<>
                  <div>{getState().pageCopy.Animation}</div>
                  <form>
                    <input type="radio" value="left" name="game-object-animation" checked={getState().newgameObjectActiveAnimationArray === "left"} onClick={(e) => {changeHandler({
                        newgameObjectActiveAnimationArray: "left",
                        newPlayerAnimationArray: getState().newgameObjectAnimationArrayLeft,
                        gameObjectFrameCount: getState().newgameObjectAnimationArrayLeft.length > 0 ? getState().newgameObjectAnimationArrayLeft.length : 1,
                      })
                      getGameObjectSpriteArray(e.target)
                    }}/> {getState().pageCopy.left} <br/>
                    <input type="radio" value="right" name="game-object-animation" checked={getState().newgameObjectActiveAnimationArray === "right"} onClick={(e) => {changeHandler({
                        newgameObjectActiveAnimationArray: "right",
                        newgameObjectAnimationArray: getState().newgameObjectAnimationArrayRight,
                        gameObjectFrameCount: getState().newgameObjectAnimationArrayRight.length > 0 ? getState().newgameObjectAnimationArrayRight.length : 1,
                      })
                      getGameObjectSpriteArray(e.target)
                    }}/> {getState().pageCopy.right} <br/>
                  </form>
                </>) : null
              }
                <br/>
                <PlaceholderImageArray/>
                <br/>
                <button style={{marginRight: "20px"}} className="main-nav-link" disabled={getState().newgameObjectAnimationArray.length !== parseInt(getState().gameObjectFrameCount)} onClick={() => {
                  switch (getState().newgameObjectActiveAnimationArray) {
                    case 'left':
                      changeHandler({
                        newgameObjectAnimationArrayLeft: getState().newgameObjectAnimationArray,
                        newgameObjectAnimationArray: []
                      })
                      break;
                    case 'right':
                      changeHandler({
                        newgameObjectAnimationArrayRight: getState().newgameObjectAnimationArray,
                        newgameObjectAnimationArray: []
                      })
                      break;
                    default:
                      return
                  }
                }}> {getState().pageCopy.createAnimation} </button>
                <button className="main-nav-link" onClick={() => {
                  changeHandler({
                    newgameObjectAnimationArray: []
                  })
                }}>{getState().pageCopy.clear}</button>
              <ul>
              {
                getState().gameObjectSpriteArray.map((object) => {
                  return (
                    <div className="tooltip">
                      <img src={`${object}`} width="80" height="80" onClick={(e) => {
                        if (getState().newgameObjectAnimationArray.length < getState().gameObjectFrameCount) {
                          getState().newgameObjectAnimationArray.push(object)
                          changeHandler({
                            gameObjectFrameCount: getState().gameObjectFrameCount
                          })
                          if (getState().newgameObjectAnimationArray.length === 1 && getState().newgameObjectActiveAnimationArray === 'left') {
                            changeHandler({
                              newGameObjectPreviewImage: object,
                            })
                          }
                        } else if (getState().newgameObjectAnimationArray.length >= getState().gameObjectFrameCount) {
                          changeHandler({
                            gameObjectFrameCount: parseInt(getState().gameObjectFrameCount) + 1
                          }, () => {
                            getState().newgameObjectAnimationArray.push(object)
                          })
                        }
                      }}/>
                      <span className="tooltiptext" >Image Name: {object.split('/')[5]}</span>
                    </div>
                  )
                })
              }
              </ul>
            </div>
          </div>
          <div style={{width: "25%", textAlign: "center", float: "left", paddingTop: "15px"}}>
            <label className="tooltip" htmlFor="game-object-width"> {getState().pageCopy.width}
              <span className="tooltiptext">{getState().pageCopy.widthTooltip}</span>
            </label><br/>
            <input name="game-object-width" id="game-object-width" type="number" min="1" max="900" value={getState().newgameObjectWidth} onChange={(e) => {changeHandler({
                newgameObjectWidth: e.target.value
              })
            }}/><br/>
            <label className="tooltip" htmlFor="game-object-reward"> {getState().pageCopy.reward}
              <span className="tooltiptext">{getState().pageCopy.rewardTooltip}</span>
            </label><br/>
            <select name="game-object-reward" id="game-object-reward" onChange={(e) => {changeHandler({
                newgameObjectReward: e.target.value
              })
            }}>
              <option value="none">{getState().pageCopy.rewardNone}</option>
              <option value="ground">{getState().pageCopy.rewardGround}</option>
              <option value="coin">{getState().pageCopy.rewardCoin}</option>
              <option value="health">{getState().pageCopy.rewardHealth}</option>
              <option value="life">{getState().pageCopy.rewardLife}</option>
              <option value="invincibility">{getState().pageCopy.rewardInvincibility}</option>
              <option value="triggerChargeLeft">{getState().pageCopy.rewardTriggerChargeLeft}</option>
              <option value="triggerChargeRight">{getState().pageCopy.rewardTriggerChargeRight}</option>
              <option value="checkPoint">{getState().pageCopy.rewardCheckPoint}</option>
              <option value="endPoint">{getState().pageCopy.rewardEndPoint}</option>
              <option value="triggerAnimation">{getState().pageCopy.rewardTriggerAnimation}</option>
            </select><br/>
            <label className="tooltip" htmlFor="game-object-reward-value"> {getState().pageCopy.rewardValue}
              <span className="tooltiptext">{getState().pageCopy.rewardValueTooltip}</span>
            </label><br/>
            <input name="game-object-reward-value" id="game-object-reward-value" type="number" min="-900" max="900" value={getState().newgameObjectRewardValue} onChange={(e) => {changeHandler({
                newgameObjectRewardValue: e.target.value
              })
            }}/><br/>
            <label className="tooltip" htmlFor="destroy-self-on-reward"> {getState().pageCopy.destroySelfOnReward}
              <span className="tooltiptext">{getState().pageCopy.destroySelfOnRewardTooltip}</span>
            </label><br/>
            <input type="radio" value="true" name="destroy-self-on-reward" checked={getState().newgameObjectDestroySelfOnReward === true} onClick={(e) => {changeHandler({
                newgameObjectDestroySelfOnReward: true
              })
            }}/> {getState().pageCopy.true} <br/>
            <input type="radio" value="false" name="destroy-self-on-reward" checked={getState().newgameObjectDestroySelfOnReward === false} onClick={(e) => {changeHandler({
                newgameObjectDestroySelfOnReward: false
              })
            }}/> {getState().pageCopy.false} <br/>
            <label className="tooltip" htmlFor="bounce-on-reward"> {getState().pageCopy.heroBounceOnReward}
              <span className="tooltiptext">{getState().pageCopy.heroBounceOnRewardTooltip}</span>
            </label><br/>
            <input type="radio" value="true" name="bounce-on-reward" checked={getState().newgameObjectHeroBounceOnReward === true} onClick={(e) => {changeHandler({
                newgameObjectHeroBounceOnReward: true
              })
            }}/> {getState().pageCopy.true} <br/>
            <input type="radio" value="false" name="bounce-on-reward" checked={getState().newgameObjectHeroBounceOnReward === false} onClick={(e) => {changeHandler({
                newgameObjectHeroBounceOnReward: false
              })
            }}/> {getState().pageCopy.false} <br/>
            <label htmlFor="reward-region-padding-left"> {getState().pageCopy.rewardRegionPaddingLeft} </label><br/>
            <input name="reward-region-padding-left" type="number" min="-900" max="900" value={getState().newgameObjectRewardRegionPaddingLeft} onChange={(e) => {changeHandler({
                newgameObjectRewardRegionPaddingLeft: e.target.value
              })
            }}/><br/>
            <label htmlFor="reward-region-padding-top"> {getState().pageCopy.rewardRegionPaddingTop} </label><br/>
            <input name="reward-region-padding-top" type="number" min="-900" max="900" value={getState().newgameObjectRewardRegionPaddingTop} onChange={(e) => {changeHandler({
                newgameObjectRewardRegionPaddingTop: e.target.value
              })
            }}/><br/>
            <label htmlFor="reward-region-left"> {getState().pageCopy.rewardRegionLeft} </label><br/>
            <input name="reward-region-left" type="number" min="-900" max="900" value={getState().newgameObjectRewardRegionLeft} onChange={(e) => {changeHandler({
                newgameObjectRewardRegionLeft: e.target.value
              })
            }}/><br/>
            <label htmlFor="reward-region-top"> {getState().pageCopy.rewardRegionTop} </label><br/>
            <input name="reward-region-top" type="number" min="-900" max="900" value={getState().newgameObjectRewardRegionTop} onChange={(e) => {changeHandler({
                newgameObjectRewardRegionTop: e.target.value
              })
            }}/><br/>
            <div style={{position: "relative", textAlign: "left", margin: "10px"}}>
              <img src={getState().newGameObjectPreviewImage} style={{position: "relative", left: "0", top: "0", width: `${getState().newgameObjectWidth}px`, border: 'dashed 5px #c1c8d4'}}/>
              <div style={{position: "absolute", top: "0", left: "0", border: "2px dashed green", width: `${getState().newgameObjectRewardRegionLeft ? getState().newgameObjectRewardRegionLeft + "px" : 0}`, height: `${getState().newgameObjectRewardRegionTop ? getState().newgameObjectRewardRegionTop + "px" : 0}`, marginTop: `${getState().newgameObjectRewardRegionPaddingTop ? (parseInt(getState().newgameObjectRewardRegionPaddingTop) + 5) + "px" : '5px'}`, marginLeft: `${getState().newgameObjectRewardRegionPaddingLeft ? (parseInt(getState().newgameObjectRewardRegionPaddingLeft) + 5) + "px" : '5px'}`}}></div>
              <div style={{position: "absolute", top: "0", left: "0", border: "2px dashed red", width: `${getState().newgameObjectReward2RegionLeft ? getState().newgameObjectReward2RegionLeft + "px" : 0}`, height: `${getState().newgameObjectReward2RegionTop ? getState().newgameObjectReward2RegionTop + "px" : 0}`, marginTop: `${getState().newgameObjectReward2RegionPaddingTop ? (parseInt(getState().newgameObjectReward2RegionPaddingTop) + 5) + "px" : '5px'}`, marginLeft: `${getState().newgameObjectReward2RegionPaddingLeft ? (parseInt(getState().newgameObjectReward2RegionPaddingLeft) + 5) + "px" : '5px'}`}}></div>
            </div>
          </div>
          <div style={{width: "25%", textAlign: "center", float: "left", paddingTop: "15px"}}>
            <label htmlFor="hasReward2"> {getState().pageCopy.reward2}? </label><br/>
            <input type="radio" value="true" name="hasReward2" checked={getState().newgameObjectHasReward2 === true} onClick={(e) => {changeHandler({
                newgameObjectHasReward2: true
              })
            }}/> {getState().pageCopy.yes} <br/>
            <input type="radio" value="false" name="hasReward2" checked={getState().newgameObjectHasReward2 === false} onClick={(e) => {changeHandler({
                newgameObjectHasReward2: false
              })
            }}/> {getState().pageCopy.no} <br/>
            {getState().newgameObjectHasReward2 ? (
              <>
                <label htmlFor="game-object-reward"> {getState().pageCopy.reward2} </label><br/>
                <select name="game-object-reward" id="game-object-reward" onChange={(e) => {changeHandler({
                    newgameObjectReward2: e.target.value
                  })
                }}>
                  <option value="coin">Coin</option>
                  <option value="ground">Ground</option>
                  <option value="health">Health</option>
                  <option value="life">Life</option>
                  <option value="invincibility">Invincibility</option>
                  <option value="triggerChargeLeft">{getState().pageCopy.rewardTriggerChargeLeft}</option>
                  <option value="triggerChargeRight">{getState().pageCopy.rewardTriggerChargeRight}</option>
                  <option value="checkPoint">{getState().pageCopy.rewardCheckPoint}</option>
                  <option value="endPoint">{getState().pageCopy.rewardEndPoint}</option>
                  <option value="triggerAnimation">{getState().pageCopy.rewardTriggerAnimation}</option>
                </select><br/>
                <label htmlFor="reward-2-value"> {getState().pageCopy.reward2Value} </label><br/>
                <input name="reward-2-value" type="number" min="-900" max="900" onChange={(e) => {changeHandler({
                    newgameObjectReward2Value: e.target.value
                  })
                }}/><br/>
                <label htmlFor="reward-2-region-padding-left"> {getState().pageCopy.reward2RegionPaddingLeft} </label><br/>
                <input name="reward-2-region-padding-left" type="number" min="-900" max="900" onChange={(e) => {changeHandler({
                    newgameObjectReward2RegionPaddingLeft: e.target.value
                  })
                }}/><br/>
                <label htmlFor="reward-2-region-padding-top"> {getState().pageCopy.reward2RegionPaddingTop} </label><br/>
                <input name="reward-2-region-padding-top" type="number" min="-900" max="900" onChange={(e) => {changeHandler({
                    newgameObjectReward2RegionPaddingTop: e.target.value
                  })
                }}/><br/>
                <label htmlFor="reward-2-region-left"> {getState().pageCopy.reward2RegionLeft} </label><br/>
                <input name="reward-2-region-left" type="number" min="-900" max="900" onChange={(e) => {changeHandler({
                    newgameObjectReward2RegionLeft: e.target.value
                  })
                }}/><br/>
                <label htmlFor="reward-2-region-top"> {getState().pageCopy.reward2RegionTop} </label><br/>
                <input name="reward-2-region-top" type="number" min="-900" max="900" onChange={(e) => {changeHandler({
                    newgameObjectReward2RegionTop: e.target.value
                  })
                }}/><br/>
              </>
            ) : null}
            <label htmlFor="game-object-speed"> {getState().pageCopy.animationSpeed} </label><br/>
            <input name="game-object-speed" type="number" min="-900" max="900" value={getState().newgameObjectSpeed} onChange={(e) => {changeHandler({
                newgameObjectSpeed: e.target.value
              })
            }}/><br/><br/>
            <button style={{marginRight: "20px"}} className="main-nav-link" disabled={checkDisabled("gameObject")} onClick={() => {createGameObject()}}>{getState().pageCopy.createGameObject}</button>
            <br/>
            <br/>
            {getState().gameObjectCreationFailed ? <div style={{backgroundColor: "#FF4200", color: "#ffffff", padding: "0.5rem", fontSize: "1rem", border: "1px solid red", width: "100%", fontWeight: "600", marginBottom: "1rem" }}>Something went wrong, this object could not be created/updated</div> : null}
            {getState().gameObjectCreationSucceeded ? <div className="success-div" style={{ margin: '0 1rem' }}>Game Object Created Successfully!</div> : null}
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoadGameModal;
